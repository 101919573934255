.dm-icons {
  display: inline-flex;
  flex-direction: row;
  gap: 0.3rem;
  align-items: center;
  .dm-icon {
    &:hover {
      cursor: pointer;
    }

    :global(svg) {
      &:hover {
        color: #01518a;
      }
      color: #003357;
    }
  }
}
