button {
  cursor: pointer !important;
}

.add-new-cert-timeline {
  padding-left: 3rem;
  padding-right: 3rem;
}

.add-new-cert-continue-btn {
  background-color: #0088cc !important;
  color: #fff !important;
}

.add-new-cert-save-btn {
  background-color: #01518a;
  color: #fff;
}

.add-new-cert-save-activate-btn {
  background-color: #0088cc;
  color: #fff;
}

.add-new-cert-dialog {
  max-height: unset;

  .add-new-cert-back-btn {
    background-color: #888888 !important;
    color: #fff !important;
  }

  .add-new-cert-btn-cancel {
    background-color: #ffffff !important;
    border-color: #999999 !important;
    color: #999999 !important;
  }

  .add-new-cert-btn-cancel:hover:not([disabled]) {
    background-color: #999999 !important;
    border-color: none !important;
    color: #ffffff !important;
  }

  .p-dialog-content {
    overflow: hidden !important;
    padding-bottom: 0px !important
  }
}