.button-column-container {
  width: 2em;
}

.button-wrapper {
  display: flex;
  justify-content: center;
}

.icon {
  font-size: 20px;
  border-radius: 0;
  font-weight: 900;
}

.button-container {
  padding: 0;
}

.button {
  padding: 2px 4px;
  border: none;
  background-color: rgb(0, 21, 36);
  color: #fff;
  border-radius: 5px;
  margin: 8px;
  &:hover {
    background-color: rgb(1, 21, 36) !important;
  }
}

.button--disabled {
  background-color: #999999;
}

.header-width {
  width: 100%;
}

.dropdown-icon--hasError {
  vertical-align: top;
}

.table--height {
  height: calc(90vh - 400px) !important;
}

.e2eDropdown-box__container {
  &:has(+ td) {
    background: red;
  }

  .e2eDropdown-box {
    width: 100%;

    &--error {
      border: 1px solid red;
      &:focus {
        border-color: #e24c4c !important;
        box-shadow: 0 0 0 0.1rem #e24c4c !important;
      }
    }

    :global(.p-dropdown-label),
    :global(.p-multiselect-label) {
      padding: 0.5rem 0.7rem;
    }
  }
}
