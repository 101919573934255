.table-container {
  width: 100%;

  .project-order-line-list-grid-container {
    padding: 0;

    :global(.p-datatable) {
      border: solid 1px #cbcfd1;
    }

    :global(.p-datatable-table) {
      min-width: 100%;
    }

    :global(.p-datatable-wrapper) {
      height: auto;
      overflow: hidden;
    }

    :global(.p-datatable-loading-overlay) {
      z-index: 4;
    }

    :global(.p-datatable-thead) {
      z-index: 3;
    }

    :global(.p-datatable-emptymessage) {
      font-style: italic;
      font-weight: normal;

      td {
        border: none;
        background-color: #ffffff !important;
        border: 0 !important;
        justify-content: center;
      }
    }

    &
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > .p-datatable-table
          > .p-datatable-thead
          > tr
      ) {
      & > th {
        background-color: #fcfcfc !important;
        color: #222222;
        border: 1px solid #cbcfd1;
        border-width: 0 0 1px 0;
      }
    }
    :global(th .p-column-header-content) {
      display: flex !important;
      border-right: 2px solid #ccc;
      padding-right: 0.5rem;
    }

    :global(th .p-column-header-content .p-column-title) {
      width: 100%;
    }

    :global(th.p-sortable-column .p-column-header-content .p-column-title) {
      width: calc(100% - 28px);
    }

    :global(.p-datatable .p-sortable-column .p-sortable-column-icon) {
      margin-left: 0;
    }

    :global(.p-datatable .p-datatable-tbody > tr > td .p-row-toggler) {
      height: 1rem;
      width: 1rem;
      color: #222222;
    }

    &
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > .p-datatable-table
          > .p-datatable-tbody
          > tr
      ) {
      & > td {
        background: #ffffff;
        color: #222222;
        font-weight: normal;
        border: 1px solid #cbcfd1;
        border-width: 0 0 1px 0;

        .orderline-cell-container {
          display: flex;
          width: 100%;
          align-items: center;

          .left {
            min-width: 90px !important;
            flex-grow: 1;
            display: flex;
            cursor: pointer;

            .task-checkbox {
              margin-right: 0.5rem;
              cursor: pointer;
            }

            .order-line-number-text {
              white-space: nowrap !important;
              overflow: hidden !important;
              text-overflow: ellipsis !important;
              flex-grow: 1;
            }

            .custom-row-expander {
              margin-right: 0.5rem;
              cursor: pointer;
            }
          }

          .right {
            text-align: right;
            padding-right: 1.2rem;
            display: flex;
            align-items: center;

            .action-icon {
              margin-left: 0.75rem;
              cursor: pointer;
            }

            .action-button {
              margin-left: 0.4rem;
            }

            button {
              padding: 0.1rem;
              width: 1.5rem;
            }

            span {
              color: #222222;
            }
          }
        }

        .order-line-overdue {
          color: #c02032 !important;
        }
        .project-line-is-completed {
          color: #3eb489 !important;
        }
        .check-icon {
          color: #146615 !important;
          margin-right: 0.2rem;
        }
      }
    }
  }
}

.is-timestamping {
  opacity: 0.5;
}

.disabled {
  opacity: 0.4;
}

.predecessor-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
