@import "material-icons/iconfont/material-icons.css";
@import "@underwriters-labs/design-system-scss";
@import "./DesignSystemOverrides.scss";
@import "../node_modules/@syncfusion/ej2-fluent-theme/styles/fluent.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/fluent.css"; 
@import '../node_modules/@syncfusion/ej2-buttons/styles/fluent.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/fluent.css';  
@import '../node_modules/@syncfusion/ej2-react-dropdowns/styles/fluent.css';  
@import '../node_modules/@syncfusion/ej2-react-inputs/styles/fluent.css';  
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-react-popups/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/fluent.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/fluent.css";
@import '../node_modules/@syncfusion/ej2-react-buttons/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-react-popups/styles/fluent.css';
@import "../node_modules/@syncfusion/ej2-base/styles/fluent.css";
@import "../node_modules/@syncfusion/ej2-react-buttons/styles/fluent.css";
@import "../node_modules/@syncfusion/ej2-react-gantt/styles/fluent.css";