.dialog {
    .dialog-header__title {
        border-bottom: 2px solid #c5c3c3;
        padding-bottom: 2rem;
        margin-top: .5rem;
        margin-bottom: 0px;
        font-size: 1.25rem;
    }

    :global(.p-dialog-header-icon) {
        position: absolute;
        top: 1.75rem;
        right: 1.5rem;
    }

    .verbiage {
        margin-bottom: 1rem;
        line-height: 1.5rem;
        font-weight:600;
    }

    .dialog-body {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .dialog-footer {
        display: flex;
        justify-content: center;
        gap: .35rem;
        padding-top: 1rem;
        margin-bottom: 0.5rem;
        flex-wrap: wrap;
        border-top: 2px solid #c5c3c3;

        button {
            height: 3.25rem;
            min-width: 9rem;
            font-weight: 600;
            display: flex;
            justify-content: center;
        }

        .btn-ok {
            background-color: #00518A;
            border: none;
        }
    }
}