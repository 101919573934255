.certificate-attachments__container {
  padding: 0.5rem;

  .container {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    display: flex;

    .document-type {
      cursor: pointer;
    }
  }

  .title__section {
    font-weight: 700;
    padding-bottom: 0.3rem;
  }
}

.files {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  margin: 0px !important;

  .icons {
    margin: 1px;
    font-size: 14px !important;
  }

  .files-column {
    display: flex;
    max-width: 45% !important;
    width: auto;
    justify-content: start;
    margin: 0px !important;
  }

  .actions-column {
    max-width: 50%;
    display: flex;
    justify-content: end;
    padding: 0px !important;
  }

  .delete-column {
    max-width: 5% !important;
    display: flex;
    padding: 0px !important;
    justify-content: end;
    margin: 0px;
    align-items: center;
    align-content: center;
  }

  &.file-status-4 {
    .files-column {
      max-width: 35% !important;
    }

    .actions-column {
      max-width: 60% !important;
    }
  }
}

.files:last-child {
  padding-bottom: 1rem;
}

.fileName {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
}

.text-btn {
  display: inline-block;
  overflow-x: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;

  &.downloadLink {
    color: #01518a;

    &:hover {
      color: #0a99ff;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &.unlinkEcm {
    color: #e12d33;
    font-style: italic;
    text-decoration: underline !important;
    padding-right: 2px;

    &:hover {
      color: #931519;
      cursor: pointer;
    }
  }

  &.linkToWbAndEcm {
    color: #0a99ff;
    font-style: italic;
    text-decoration: underline !important;
    padding-right: 2px;

    &:hover {
      color: #01518a;
      cursor: pointer;
    }
  }

  &.linkToWb {
    color: #ffa700;
    font-style: italic;
    text-decoration: underline !important;
    padding-right: 2px;

    &:hover {
      color: #d18820;
      cursor: pointer;
    }
  }
}

.icons {
  font-size: 16px;
  cursor: pointer;

  &.icons-ml {
    margin-left: 0.4rem;
  }
}

.legend-icon-1 {
  color: #146615;
}

.legend-icon-2 {
  color: #ffa700;
}

.legend-icon-3 {
  color: #0a99ff;
}
.legend-icon-4 {
  color: black;
}

.uploading-icon {
  color: #999999;
}

.fileName-status-0 {
  color: #0a99ff !important;
}

.status-indicator-container {
  display: inline-flex;
}
