.refiner-card {
    .card-header {
      cursor: pointer;
      background-color: white;
    }
  }
  
  .ul-card {
    padding: 0 !important;
  }
  
  .ul-card__item{
    padding:1rem !important;
  }
  