// th div.p-column-header-content > span {
// }

th {
  position: sticky !important;
  background-color: #ebebea !important;
}

.cert-list-grid-container {
  margin-top: 1rem;

  .p-datatable-wrapper {
    height: calc(100vh - 210px);
  }

  .text-overflow-ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-width: 90px !important;
  }
  .p-datatable-thead {
    z-index: 2;
  }

  thead > tr {
    background-color: #e6e6e6 !important;
  }

  .p-reorderable-column:not(.p-frozen-column) {
    z-index: 0 !important;
  }

  th:hover {
    background-color: #cccccc !important;
  }

  div > div > table > tbody > tr:nth-child(even) {
    background: #f5f5f5;
  }

  .td-active {
    color: #001524;
  }

  .td-expiring-soon {
    color: #ffa700;
  }

  .td-under-revision {
    color: #0a99ff;
  }

  .td-obsolete {
    color: #e12d33;
  }

  th.p-resizable-column .p-column-header-content {
    display: flex !important;

    .p-column-title {
      width: calc(100% - 28px);
    }
  }

  .td-withdrawn {
    color: #999999;
  }

  .td-action-item {
    cursor: pointer;
  }

  .p-reorderable-column:hover {
    cursor: move;
  }

  .p-sortable-column-icon:hover {
    cursor: pointer;
  }
}

.add-new-btn {
  background-color: #146615 !important;

  &:hover {
    background-color: #1eb61e !important;
  }
}

.justify-content-end {
  justify-content: flex-end !important;
}

.last-frozen {
  border-right-width: 2px !important;
}

.border-right-solid {
  // border-right: solid 1px blue;
}

.drag-handle:hover {
  // border-right: solid 1px blue;
}
