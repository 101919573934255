.project-collaborator-dropdown-panel {
    display:flex; 
    flex-direction: column;

    :first-child {
        order:2;
    }

    :nth-child(1) {
        order:1
    }
    :last-child {
        order:3;
    }

    .header {
        display: grid;
        grid-template-columns: 1fr 0.10fr;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left:0.75rem;
        padding-right:0.75rem;
        background:#f8f9fb;
        
        .close-button {
            padding-inline-start: 0px;
            padding-inline-end: 0px;
            text-align: end;
            justify-content: end;
            padding-right: 0.30rem;
            width: 30px;
            &:focus-visible {
                outline-color: transparent;
                outline-width: 0;
            }
            &:focus {
                box-shadow: none;
            }
            &:hover{
                background: transparent !important;
            }
            &:active{
                background: transparent !important;
            }
        }
    }
    .footer {
        padding: 0.5rem;
        display: flex;
        justify-content: center;

        .show-more-button {
            text-decoration: underline;
            color:#1283fb !important;
            box-shadow: none !important;
            :hover {
                color:#1176e2 !important;
            }
        }
    }
}

.project-collaborator-dropdown-panel-show-more-not-visible {
    display:flex; 
    flex-direction: column;

    :first-child {
        order:2;
    }

    :last-child {
        order:1;
    }
    
    .header {
        display: grid;
        grid-template-columns: 1fr 0.10fr;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left:0.75rem;
        padding-right:0.75rem;
        background:#f8f9fb;

        .show-more-not-visible {
            order: 1 !important;
        }
        
        .close-button {
            padding-inline-start: 0px;
            padding-inline-end: 0px;
            text-align: end;
            justify-content: end;
            padding-right: 0.30rem;
            width: 30px;
            &:focus-visible {
                outline-color: transparent;
                outline-width: 0;
            }
            &:focus {
                box-shadow: none;
            }
            &:hover{
                background: transparent !important;
            }
            &:active{
                background: transparent !important;
            }
        }
    }
    .footer {
        padding: 0.5rem;
        display: flex;
        justify-content: center;

        .show-more-button {
            text-decoration: underline;
            color:#1283fb !important;
            box-shadow: none !important;
            :hover {
                color:#1176e2 !important;
            }
        }
    }
}