.dialog-content{
    margin-top:10px;
    padding: 20px;

    .text-label {
        padding-bottom: 5px;
    }

    .text-box__container{
        padding-bottom:30px;
        .text-box {
            width:100%;
        }
    }
  
    .required-field {
        color:red;
    }

    .template-details__container{
        padding-bottom:10px;

        .template-details__text{
            padding-bottom:10px;
        }
    }

    .text-box--error{
        &:focus {
            border-color:#e24c4c !important;
            box-shadow:0 0 0 0.1rem #e24c4c !important;
        }
    }
}


.footer-button__container {
    gap: 10px;
    display: flex;
    justify-content: center;
    
    .apply-button {
       
        border: none !important;
        background:#3F47B5;
        &:disabled {
            background:#A0A4DB;
            &:hover{
                background:#A0A4DB;
            }
        }
        &:hover{
            background:#363da1;
        }
    }
    .cancel-button {
        margin-left:50px;
        background:#595957;
        border: none !important;
        &:hover{
            background:#4b4b49;
        }
    }
}

.dialog-close-button {
    :global(.p-dialog-header-icon.p-dialog-header-close.p-link) {
        &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0 !important;
        }
        &:hover{
            background: transparent;
        }
    }
}

