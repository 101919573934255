.custom-multiselect {
  width: 100%;
  :global .p-multiselect-label-container {
    width: auto !important;
  }
}

.color-red {
  color: #dc3545;
}

.color-blue {
  color: #007bff !important;
}

.multiselect-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  .multiselect-items {
    width: 100%;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .custom-filter {
      width: 100%;
      span {
        display: block;
      }
      input {
        width: 100%;
      }
    }
    button {
      text-align: center;
    }

    .filter-container {
      display: inline-block;
      width: 100%;
      input {
        display: inline;
        min-width: 250px;
      }
      .filter-hidden-label {
        height: 0;
        visibility: hidden;
        margin: 0 2rem;
      }
    }
  }
}

.custom-showmore {
  text-decoration: underline;
}

.custom-option {
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.refiner-dropdown-placeholder {
  &:after {
    content: "" !important;
  }

  &:not(:first-child) {
    display: none !important;
  }
}
