.project-full-view-container {
  padding: 1.25rem !important;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  h2 {
    margin: 1rem 0px;
    font-weight: 600;
  }
  .info-icon,
  .fa-circle-info {
    margin: 0px 1rem;
  }
  span {
    width: 7vw;
  }
}

.body {
  margin: 1rem 0px !important;
}

.table-div {
  margin: 0px !important;
  width: calc(100vw - 1rem);
  overflow: auto;
  height: calc(100vh - 11rem);

  :global(.p-datatable .p-datatable-tbody > tr.p-datatable-row-expansion > td) {
    background: #ffffff;
    width: 100%;
    padding: 0;
  }

  :global(.p-datatable .p-datatable-wrapper) {
    overflow: visible !important;
    width: 100%;
  }

  :global(.p-datatable-tbody)
    tr:has(:global(td .p-checkbox > div > input[checked])):not(
      :global(.p-datatable-row-expansion)
    ) {
    background: unset !important;

    td[role="cell"] {
      background: #d1eafd !important;
    }
  }
}

.error-div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 70vh;
  img {
    width: 27vw;
    display: block;
  }
  h1,
  h3 {
    font-weight: 400;
  }
}

.tooltip {
  :first-child {
    border-right-color: white !important;
  }

  :last-child {
    background: white;
    color: #222222;
  }

  .tooltip-title {
    font-weight: bolder;
    padding-bottom: 0.5rem;
  }

  .tooltip-label {
    font-style: italic;
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
}
