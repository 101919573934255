.container {
  display: flex;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;

  .left {
    width: 20%;
    min-width: 20%;
  }

  .right {
    padding: 1rem 1.75rem;
    flex-grow: 1;
    min-width: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .table-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .pwq-column { 
        flex-grow: 1 !important;
      }

      .table__header {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: flex;
        width: 100%;
        .header-button-container {
          text-align: right;
          width: 50%;
          .table__header__actions {
            display: inline-flex;

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              background: none;
              min-width: 44px;
              height: 35px;
              padding: 0 0.5rem;
              border-radius: 2px;

              &:hover {
                background: #e2e6ea;
                border-color: #dae0e5;
              }
            }
          }
        }
        .header-record__container {
          display: flex;
          align-items: end;
          width: 50%;
          .header-record-shown {
            font-weight: bold;
          }
        }
       
      }

      :global(.p-datatable-loading-overlay) {
        z-index: 3;
      }
      :global(.p-datatable-wrapper) {
        height: calc(100vh - 145px);
        overflow: scroll;
        padding-bottom: 45px;
      }

      :global(th.p-sortable-column .p-column-header-content) {
        display: flex !important;
      }
      :global(th.p-sortable-column .p-column-header-content .p-column-title) {
        width: calc(100% - 28px);
      }

      :global(.p-datatable-thead) {
        z-index: 2;
        background-color: transparent !important;

      }

      :global(.p-datatable-footer) {
        z-index: 1;
        position: absolute;
        bottom: 15px;
        width: 100%;
        background: white;
        border: 1px solid #dee2e6;
        text-align: center;
        padding: 0.5rem 1rem;
        min-height: 40px;
        .load-more {
          margin-right: 0.25rem;
          color: #007bff;
          cursor: pointer;
          text-decoration: underline;
          font-weight: bold;
          &.disabled {
            opacity: 0.5;
          }
        }
        .end-of-list {
          font-style: italic;
          font-weight: normal;
        }
      }

      :global(.p-datatable-emptymessage) {
        font-style: italic;
        font-weight: normal;
        position: absolute;
        top: 50%;
        left: 44%;

        td {
          border: none;
        }
      }

      .no-data {
        :global(.p-datatable-wrapper) {
          overflow-x: hidden;
        }
      }

      .table__flex-handler-cell {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .flex-handler-column__header {
        label {
          margin-right: 0.75rem;
        }

        svg {
          cursor: pointer;
        }
      }
    }

    .right__footer {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .btn-create-e2e {
        background-color: #01518a;
        font-weight: 600;

        &:disabled {
          opacity: 0.5;
        }
      }

      .selected-rows-info {
        background-color: #ffa800;
        font-size: 0.9rem;
        padding: 0.1rem 0.5rem;
        color: black;
        font-weight: 600;
      }

      .btn-add-order-line {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        background-color: #01518a;
        font-weight: bold;
        border: none;
        border-radius: 3px, 3px, 3px, 3px;

        &:hover {
          background-color: #003357;
        }

        &:disabled {
          background-color: rgba(1, 81, 138, 0.5);
        }

        &:active {
          background-color: #003357;
          border: 1px solid #0a99ff;
        }
      }

      .btn-cancel {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        background-color: #e6e6e6;
        font-weight: bold;
        border: none;
        border-radius: 3px, 3px, 3px, 3px;
        color: black;

        &:active {
          background-color: #cccccc;
          border: 1px solid #0a99ff;
        }

        &:hover {
          background-color: #cccccc;
        }

        &:disabled {
          background-color: rgba(230, 230, 230, 0.5);
        }
      }
    }
  }
}

.error {
  color: #e12d33;
  font-size: 14px;
  font-style: italic;
}

.last-frozen {
  border-right-width: 2px !important;
}
