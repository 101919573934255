.table-container {
  width: 100%;

  .project-task-list-grid-container {
    padding: 0;

    .data-row-bg {
      border-bottom: solid 1px #cbcfd1;
      background-color: #f5f5f5;
    }

    :global(.p-datatable) {
      border: solid 1px #cbcfd1;
    }

    :global(.p-datatable-table) {
      min-width: 100%;
    }

    :global(.p-datatable-wrapper) {
      height: auto;
      overflow: hidden;
    }

    :global(.p-datatable-loading-overlay) {
      z-index: 6;
    }

    :global(.p-datatable-thead) {
      z-index: 5;
      background-color: #e6e6e6 !important;
    }

    :global(.p-datatable-emptymessage) {
      font-style: italic;
      font-weight: normal;

      td {
        border: none;
        background-color: #ffffff !important;
        border: 0 !important;
        justify-content: center;
      }
    }

    &
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > .p-datatable-table
          > .p-datatable-thead
          > tr
      ) {
      & > th {
        background-color: #e6e6e6 !important;
        color: #222222;
        border: 1px solid #cbcfd1;
        border-width: 0 0 1px 0;
      }
    }

    :global(th .p-column-header-content) {
      display: flex !important;
      border-right: 2px solid #ccc;
      padding-right: 0.5rem;
      min-height: 22px;
    }

    :global(th .p-column-header-content .p-column-title) {
      width: 100%;
    }

    :global(th.p-sortable-column .p-column-header-content .p-column-title) {
      width: calc(100% - 28px);
    }

    :global(.p-datatable .p-sortable-column .p-sortable-column-icon) {
      margin-left: 0;
    }

    :global(.p-datatable .p-datatable-tbody > tr > td .p-row-toggler) {
      height: 1rem;
      width: 1rem;
      color: #222222;
    }

    :global(
        .p-datatable .p-datatable-tbody > tr.p-datatable-row-expansion > td
      ) {
      padding: 0;
    }

    &
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > .p-datatable-table
          > .p-datatable-tbody
          > tr
      ) {
      & > td {
        background: #f5f5f5;
        color: #222222;
        font-weight: normal;
        border: 1px solid #cbcfd1;
        border-width: 0 0 1px 0;

        .task-name-container {
          display: flex;
          width: 100%;

          .left {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            min-width: 90px !important;
            width: 100%;

            .task-checkbox {
              margin-right: 0.5rem;
              cursor: pointer;
            }

            .task-row-expander {
              margin-right: 0.5rem;
              cursor: pointer;
              vertical-align: middle;
              & > svg {
                vertical-align: middle;
              }
            }
          }

          .child-task {
            margin-left: 2.85rem;
            font-style: italic;
            .child-task-text {
              color: #eb9a25;
            }
          }

          .task-name-title {
            vertical-align: middle;
          }
        }
      }
    }

    &
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > .p-datatable-table
          > .p-datatable-tbody
          > tr.p-rowgroup-header
      ) {
      & > td {
        font-weight: bold !important;
        padding-left: 0.75rem;
        background-color: #e6e6e6 !important;
        width: 100%;
      }
    }
  }
}

.task-name-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  button {
    all: unset;
    font-size: 1.15rem;
  }
  .button-disabled {
    opacity: 0.5;
    cursor: default !important;
  }
  .adhoc-task-icon {
    color: #003357;
  }
}
.dropdownViewBy {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  :global(.p-multiselect-label-container) {
    width: 20rem;
  }
  :global(.p-multiselect-label) {
    height: auto !important;
    padding: 0.275rem 0.75rem;
  }
}
.subheader-container {
  width: 520px;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0px !important;
  }
  button {
    all: unset;
    font-size: 1.15rem;
  }
  .button-disabled {
    opacity: 0.5;
    cursor: default !important;
  }
  .adhoc-task-icon {
    color: #003357;
  }
}
