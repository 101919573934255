.container {
  display: flex;
  justify-content: space-between;
}

.action {
  display: flex;
  font-size: 16px;
  color: #000000;
}

.icon {
  font-size: 24px;
  color: #000000;
}

.line {
  border-bottom: none !important;
  border: 1px solid #d3d3d3;
  margin-left: -9px;
  margin-right: -9px;
}
