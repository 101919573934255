.favorites {
  align-items: center;
  display: flex;
  justify-content: center;
  background: none;
  padding: 0.5rem;
  width: 100%;
}

.favorites:hover {
  background: #e2e6ea;
  border-color: #dae0e5;
}

.sub-title {
  font-size: 16px;
  font-weight: bold;
}

.save-button {
  margin-left: 0.5rem;
  line-height: 0.75;
}

.save-button:disabled {
  pointer-events: none;
}

.def-and-clear {
  margin: 5px 0 0 0;
}

.set-as-default,
.clear-filters {
  display: inline-block;
  width: 50%;
}

.clear-filters {
  text-align: right;
  color: #36c4fb;
  cursor: pointer;
}

.clear-filters:hover {
  color: #00def5;
  cursor: pointer;
}

.fav-header {
  border-bottom: solid 0.5px #ebebeb;
  padding-bottom: 10px;

  .favorites-input-body {
    display: inline-flex;
    margin: 5px 0;
  }

  .favorites-input {
    height: 30px;
    width: 218px;
  }

  .favorites-input-invalid {
    border-color: #e24c4c;
  }

  .favorites-input-invalid:hover {
    border-color: #e24c4c;
  }

  .favorite-invalid-message {
    margin-top: 2px;
    color: #e24c4c;
    font-style: italic;
  }
}

.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  height: 20px;
}

.fav-body {
  padding-top: 10px;
  max-height: 165px;
  overflow-y: auto;
  scrollbar-width: none;

  .list {
    margin: 10px 0 0 0;

    .item:hover {
      background-color: #f2f2f2;
    }

    .item {
      display: flex;
      width: 100%;
      margin: 0 0 2px 0;
      padding: 2px;
      cursor: pointer;

      .fav,
      .fav-action {
        display: inline-block;

        .action {
          width: 40px;
          text-align: center;
        }

        .action:hover {
          background-color: #f2f2f2;
          border-radius: 5px;
        }
      }

      .fav-star {
        color: #146615;
      }

      .fav-action {
        width: 79px;
        text-align: right;
        color: #000000;
      }

      .fav {
        width: 200px;
        white-space: nowrap;
      }
    }
  }
}

.fav-display {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100px;
}

.dialog-favorite {
  :global(.p-dialog-header) {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #c5c3c3;
  }

  :global(.p-dialog-footer) {
    padding-top: 1.5rem;
    border-top: 1px solid #c5c3c3;

    display: flex;
    justify-content: center;
  }

  .edit-body {
    padding: 2rem;
    font-size: 18px !important;

    .edit-label {
      text-align: center;
    }

    .edit-input {
      margin: 10px 0;
      height: 100%;
      width: 100%;
    }
  }

  .edit-checkbox {
    margin-top: 10px;
  }

  .edit-button-save {
    background-color: #06548c !important;
    color: #fff !important;
    border: none;
  }

  .edit-button-save:hover {
    background-color: #023c65 !important;
    color: #fff !important;
    border: none;
  }

  .edit-button-cancel {
    color: #ffffff;
    background-color: #64748b;
    border: none;
  }

  .edit-button-cancel:hover {
    color: #ffffff;
    background-color: #4a5769;
    border: none;
  }

  .delete-body {
    padding: 2rem;
    font-size: 18px !important;
    text-align: center;
  }

  .invalid-message {
    color: #e24c4c;
    font-style: italic;
  }

  .favorites-input-invalid {
    border-color: #e24c4c;
  }

  .favorites-input-invalid:hover {
    border-color: #e24c4c;
  }
}
