.ul-toast-container {
    width: 432px;
    position: fixed;
    right: 12px;
    top: 12px;
    z-index: 3001;
    transition: transform 3s ease-in-out;
    animation: toast-in-right 3s;
    max-height: 100vh;
    overflow-y: auto;

    .ul-alert {
        margin-bottom: 10px;

        .ul-alert__close {
            height: 20px;
        }
    }
}

.alert-appear, .alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-appear-active, .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms ease-in, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
