.dialog {
  :global(.p-dialog-header-icon) {
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;
  }

  :global(.p-dialog-header) {
    padding-bottom: 0px;
  }

  :global(.p-dialog-footer) {
    padding-bottom: 1rem;
  }

  :global(.p-dialog-content) {
    padding-bottom: 0.8rem;
  }

  .dialog-header__title {
    font-size: 1.15rem;
    border-bottom: 3px solid #c5c3c3;
    margin-top: 0.25rem;
    padding-bottom: 1.5rem;
  }

  .dialog-header__info {
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: end;
    gap: 2rem;
    margin: 1.5rem 0.5rem 2rem;
    max-width: 100%;
  }

  .dialog-header__info__left {
    flex-grow: 1;
    align-items: end;
    display: flex;
    align-items: end;
    gap: 2rem;

    .dialog-header__info__left__field-info {
      display: flex;
      align-items: center;
      max-width: 100%;

      h5,
      svg {
        text-wrap: nowrap;
        font-size: 0.85rem;
      }

      h5 {
        margin: 0px;
        margin-right: 0.5rem;
        overflow-y: hidden;
        text-overflow: ellipsis;
      }

      .icon {
        cursor: pointer;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      width: 50%;
    }
  }

  .dialog-header__info__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    margin-right: 1rem;

    .btn-select-model {
      background-color: #006af5;
      font-weight: 500;
      font-size: 1rem;
      padding: 0.65rem 1rem;
    }

    .btn-favorites {
      all: unset;
    }
  }

  .dialog__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    .dialog__loader-div {
      width: 100%;
      height: 25%;
      text-align: center;
      span {
        font-size: 1.25rem;
      }
      .loader-container {
        margin: 5px auto;
        width: 100%;
        height: 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.5);
        z-index: 1;
      }
    }
  }

  .projects-table {
    &,
    :global(.p-datatable-wrapper) {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    :global(.p-checkbox-box) {
      height: 1.1rem;
      width: 1.1rem;
    }

    :global(.p-datatable-thead) {
      z-index: 12 !important;
    }

    :global(.p-datatable-scrollable .p-datatable-thead) {
      z-index: 2 !important;
    }

    :global(td.p-frozen-column) {
      z-index: 11;
    }

    :global(th.p-resizable-column.p-frozen-column.p-selection-column) {
      left: 0px !important;
    }

    th,
    td {
      padding: 0.1rem 0.2rem !important;
      font-size: 0.8rem;
      border: 1px solid #ccc;
    }

    td {
      line-height: 1rem;

      :global(.p-dropdown),
      :global(.p-multiselect) {
        max-width: 100%;
        width: 100%;
        font-size: 0.8rem !important;
        margin: 0.1rem 0rem;
      }

      :global(.p-dropdown),
      :global(.p-multiselect) {
        max-width: 100%;
        width: 100%;
        font-size: 0.8rem !important;
        margin: 0.1rem 0rem;
      }

      :global(.p-dropdown-label),
      :global(.p-multiselect-label) {
        font-size: 0.8rem;
      }
    }
  }

  .btn-save {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    min-width: 8rem;
    margin-right: 1.75rem !important;
    background-color: #4047b5;
  }

  .btn-compare-delivery-path {
    padding: 0.5em 0.5em;
    margin-left: 1em;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }

  .btn-compare-delivery-path:hover {
    background-color: #0f6572 !important;
    border-color: #0f6572 !important;
  }

  .btn-add-order-line {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: #fff;
    border-color: #01518a;
    color: #01518a;
    font-weight: bold;
    border-radius: 3px, 3px, 3px, 3px;
  }

  .btn-add-order-line:hover {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: #003357;
    font-weight: bold;
  }

  .btn-add-order-line:disabled {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: rgba(1, 81, 138, 0.5);
    font-weight: bold;
  }

  .btn-add-order-line:active {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: #003357;
    font-weight: bold;
    border: 1px solid #0a99ff;
  }

  .model-text {
    float: left;
  }

  .clear-all-model {
    all: unset;
    float: right;
    color: royalblue;
    text-decoration: underline;
    font-weight: normal;
  }

  .model-icon {
    font-size: large;
    cursor: pointer;
  }

  .dialog__body {
    min-height: 100%;
    max-height: 100%;
    position: relative;
  }

  .single-line__select-models {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;

    .models {
      width: calc(100% - 30px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;

      .model-names-container {
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #cbedff;
        border: 0.5px solid #73b6ff;
        margin-right: 5px;
      }

      .hidden-models-count {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 5px;
        height: 25px;
        width: 25px;
        background-color: #cbedff;
        border: 0.5px solid #73b6ff;
      }
    }

    button {
      all: unset;
    }
  }

  .checkbox-hide {
    :global(.p-checkbox-box.p-component) {
      visibility: hidden;
    }
  }

  .delete-icon-container {
    padding: 1px !important;

    .delete-icon {
      cursor: pointer;
      font-size: 1rem !important;
      color: #000000;
    }
  }

  .delete-icon-hide {
    visibility: hidden;
  }

  :global(.p-button.p-button-outlined) {
    color: #01518a;
  }

  // hack for delete header reordering itself when table is reordered
  .delete-header {
    left: 30px !important;
  }
}

.required-field {
  color: red;
}

.project-name__label {
  font-size: 0.85rem;
}

.project-name__input {
  width: 100%;
}

.project-name__error-message {
  font-size: 0.775rem;
  font-weight: normal;
  color: red;
  position: absolute;
  bottom: -30px;
  font-style: italic;
}

.project-name__input-container {
  position: relative;
}

.footer-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  gap: 2rem;
}

.confirmation-checkbox-container {
  text-align: left;
  display: flex;
  max-width: 45rem;
  gap: 0.5rem;
  font-size: 0.9rem;
  line-height: 1.25rem;
  color: #191919;
}

.footer__actions {
  min-width: fit-content;
  margin-right: 0;
  margin-left: auto;
}

.btn-save-and-create-new {
  color: #ffffff !important;
  background-color: #01518a !important;
  border-color: #01518a !important;
}

.btn-save-and-continue {
  color: #ffffff !important;
  background-color: #0088cc !important;
  border-color: #0088cc !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-cancel-edit {
  color: #808080 !important;
  background-color: #ffffff !important;
  border-color: #808080 !important;
}

.btn-save-edit-changes {
  color: #ffffff !important;
  background-color: #0088cc !important;
  border-color: #0088cc !important;
}
.filler {
  visibility: hidden;
}

.dd-fixed-width {
  :global(.p-dropdown-items-wrapper > .p-dropdown-items) {
    width: unset !important;
    & > :global(li) {
      width: unset !important;
    }
  }
}

.flex-project-name-link {
  text-decoration:underline;
  color: #5C8FB3;
  cursor: pointer;

  &-confirmed {
    color: blue;
  }
}

.flex-template-confirm-icon {
  color: green;
  margin-right: 0.3rem;
}

.flexProjectName-tooltip-content{
  line-height: 0.5rem;
}

.pwq-flexProjectName-tooltip-container {
  gap: 1rem;
  & > div:last-of-type {
    border: #343a40 1px solid;
    background: #ffffff;
    color:#343a40;

    box-shadow: 0px 3px 10px 0px rgba(52, 58, 64, 0.5);
    -webkit-box-shadow: 0px 3px 10px 0px rgba(52, 58, 64, 0.5);
    -moz-box-shadow: 0px 3px 10px 0px rgba(52, 58, 64, 0.5);
  }
}
