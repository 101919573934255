.project-header-actions-container {
  display: flex;
  align-items: center;
  & > * {
    margin-left: 0.4em;
  }
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .flexTaskToggle {
    width: 2.5rem;
    height: 1.25rem;
    margin-top: 0.25rem;
    :global(.p-inputswitch-slider) {
      border-radius: 20px;
      &:before {
        width: 1rem;
        height: 1rem;
        margin-top: -0.525rem;
      }
    }
  }
  .flexTaskToggle--checked {
    :global(.p-inputswitch-slider) {
      background: #0a99ff;
      &:before {
        transform: translateX(1rem);
      }
      &:hover {
        background: #0c8eeb !important;
      }
    }
  }
}

.btn-icon {
  background: none;
  min-width: 44px;
  height: 35px;
  padding: 0;
  border-radius: 2px;
  font-size: 1rem;
  &.generate-report,
  &.add-timestamp,
  &.bulk-assign,
  &.edit-timeentry {
    font-size: 1.25rem;
  }

  &.update-task-status {
    color: #d18820 !important;
    font-size: 1.25rem;
  }
}

.btn-icon:hover {
  background: #e2e6ea;
  border-color: #dae0e5;
}
.vl {
  background-color: #caced1;
  width: 1px;
  height: 1.5em;
  display: inline-block;
  margin: 0 0.9em !important;
}

.green {
  color: #146615;
}
.gold {
  color: #d18820;
}
.red {
  color: #e12d33;
}
.blue {
  color: #2786c9;
}
.dark-blue {
  color: #003357;
}

.bulk-assign-contributor-spinner {
  width: 1.25rem;
  height: 1.25rem;

  :global(.p-progress-spinner-circle) {
    stroke: #144c71 !important;
  }
}
