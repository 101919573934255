.custom-dialog {
  :global(.p-dialog-header) {
    color: #222222;
  }
  :global(.p-dialog-content) {
    color: #222222;
    padding-bottom: 0;
    overflow-y: auto !important;
  }
  :global(.p-dialog-footer) {
    color: #222222;
  }
  :global(.p-button) {
    padding: 0;
    color: #222222 !important;
  }
  .body-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .dialog-footer {
    display: flex;
    &.center-items {
      justify-content: center;
    }
    &.right-items {
      justify-content: end;
    }
    .dialog-cancel-btn {
      background-color: #ffffff;
      border-color: #999999;
      color: #999999;
      min-width: 5rem;
      border-radius: 5px;
    }
    .dialog-save-btn {
      background-color: #0088cc;
      color: #ffffff;
      min-width: 5rem;
      border-radius: 5px;
    }
  }
}
.loading-div {
  height: 200px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.hr {
  border: 1px solid #cecece;
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.error {
  color: #ff2037;
}
