.custom-badge {
  border-radius: 10px;
  padding: 0.05rem 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.3px;
  text-align: center;
  min-width: 140px;
  display: block;
  &.inprogress {
    background-color: #0a99ff;
    color: #ffffff;
  }
  &.notscheduled {
    background-color: #595958;
    color: #ffffff;
  }
  &.completed {
    background-color: #146615;
    color: #ffffff;
  }
  &.onhold {
    background-color: #ffa700;
    color: #8a5340;
  }
  &.cancelled {
    background-color: #cccccd;
    color: #444444;
  }
}
.custom-dropdown {
  min-width: 160px !important;
  max-width: 160px !important;

  :global(.p-dropdown-label) {
    padding-top: 3px;
    padding-bottom: 2px;
    margin: 0;
  }
}
