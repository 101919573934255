.doc-knowledge-full-div {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .tbl-div {
    width: calc(100vw - 10px) !important;
    height: calc(100vh - 280px) !important;
  }

  .document-knowledge-container {
    display: flex;
  }

  .description {
    margin: auto 0 auto 10px;
    font-style: italic;
    padding-top: 11px;
  }

  .actions-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
