.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #F8F8F8;

    &>div {
        width: 90%;
        max-width: 20rem;
        margin-top: -2rem;
        background-color: white;

        .header {
            background-color: #B10820;
            color: white;

            h4 {
                padding: .8rem;
                font-size: 1.3rem;
                font-weight: 500;
                text-align: center;
                margin: 0px;
            }
        }

        .content {
            margin: 1.3rem;

            .title {
                font-size: 1rem;
                margin-top: .8rem;
                margin-bottom: .8rem;
            }

            p {
                font-weight: 600;
                font-size: .7rem;
                margin-top: 0px;
            }

            form {
                margin-top: 1.5rem;


                &>* {
                    display: block;
                    width: 100%;
                    text-align: left;
                }

                .label-for-password {
                    font-weight: bold;
                    font-size: .7rem;
                    margin-bottom: 5px;
                }

                .error-message {
                    font-size: .7rem;
                    margin-left: 5px;
                    margin-top: 5px;
                    color: red;
                    display: flex;
                    align-items: center;

                    span {
                        font-weight: bold;
                        font-size: .8rem;
                    }
                }

                input {
                    height: 2.2rem;
                    font-size: 1.1rem;
                    outline: none;
                    border-width: 1px;
                    width: 100%;
                    max-width: 100%;
                    border-color: #6c6c6c;
                    border-radius: 3px;

                    &.error {
                        border-color: red;
                    }
                }

                .btn-continue {
                    margin-top: 1.8rem;
                    font-size: .9rem;
                    text-align: center;
                    background-color: #4D4D4D;
                    color: white;
                    border: none;
                    padding: .6rem;
                    outline: none;

                    &:disabled {
                        background-color: #E6E6E6;
                    }
                }
            }
        }
    }
}