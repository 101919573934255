.btnContainer {
  display: flex;
  justify-content: center;
}

.closeBtn {
  padding: 8px 30px;
  color: #ffffff;
  background-color: #595958;
  border: none;
  border-radius: 2px;
}

.view-history {
  :global(th.e-headercell.e-leftalign) {
    background-color: transparent !important;
  }

  :global(.e-dialog) {
    border: 0.3px solid #59595a;
  }
}

.change-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.associated-scheme {
  &>label {
    display: block;
    margin-bottom: .5rem;
  }

  &>div {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
}

.flex-tasks {
  .flex-task-name {
    font-weight: bold;
    display: block;
    margin-bottom: .25rem;
  }

  &>div {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
}

.dialog {
  .table-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;

    position: relative;
  }

  :global(.p-datatable) {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}