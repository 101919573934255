.container {
    .modal-header {
        margin-top: 5px;
        margin-left: 30px;
    }

    hr {
        border-color: #cfcfcf;
        border-bottom: 0px;
    }

    :global(.p-dialog-header) {
        padding: 20px 0px 0px 0px !important;
    }

    :global(.p-dialog-footer) {
        padding: 0px 0px 25px 0px !important;
    }

    :global(.p-dialog-header-icons) {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .footer-content {
        margin-top: 18px;
    }

    .body-content {
        line-height: 22px;
        padding-right: 25px;
        padding-left: 25px;
        margin-bottom: 0px;
    }

    .btn-yes {
        background-color: #0088CC !important;
    }
}