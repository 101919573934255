.btn-no {
  background-color: transparent !important;
  border: 1px solid gray;
  color: gray;
}

.btn-no:hover {
  background-color: gray !important;
  border: 1px solid gray !important;
  color: #fff;
}

.btn-yes {
  background-color: #0088cc !important;
}

.btn-cancel {
  background-color: white !important;
  border-color: #888888 !important;
  color: #888888 !important;
}

.milestone-table {
  margin: 1.5rem 0rem;
  max-height: 25rem;
  overflow-y: scroll;
  padding: 0.5rem 0rem;
}

.milestone-table::-webkit-scrollbar {
  display: none;
}

.action-note {
  color: #c02032;
  margin-left: 0.25rem;
  font-weight: bold;
}

.modal-body {
  margin: 0px !important;
}
