.container {
    .modal-header {
      margin-top: 5px;
      margin-left: 30px;
    }
  
    hr {
      border-color: #cfcfcf;
      border-bottom: 0px;
    }
  
    :global(.p-dialog-header) {
      padding: 20px 0px 0px 0px !important;
    }
  
    :global(.p-dialog-footer) {
      padding: 0px 0px 25px 0px !important;
    }
  
    .footer-content {
      margin-top: 18px;
    }
  
    .body-content {
      line-height: 22px;
      padding-right: 8px;
      padding-left: 8px;
      margin-bottom: 0px;
    }
  
    .btn-yes {
      background-color: #0088CC !important;
    }

    :global(.p-dialog-content) {
      padding-bottom: 1.5rem;
    }

    :global(.p-dialog-header-icons) {
      margin-right: 20px;
    }
  }
  