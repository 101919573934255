.onhold-reason-dialog {
  border-radius: 0px;

  :global(.p-dialog-footer) {
    border-top: 1px solid #dee2e6;
    padding: 0;
  }

  .footer {
    align-content: center;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    gap: 0.75rem;
    padding: 1rem;

    .button-cancel {
      height: 2.5rem;
      width: 7rem;
      background: transparent;
      border-color: #b8b8b8;
      outline-color: #b8b8b8;
      color: #b8b8b8;
      margin: 0;

      :hover {
        background: transparent;
        color: #9e9e9e;
        border-color: #9e9e9e;
        outline-color: #9e9e9e;
      }
    }

    .button-save {
      height: 2.5rem;
      margin: 0;
      background: #0088cc;
      border: 1px solid #a9dff7;
      :hover {
        background: #016a9e;
      }
    }
  }

  .content {
    padding: 1rem;

    .reason-dropdown {
      cursor: pointer;
      width: 100%;
    }
  }

  .spinner__container {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 2rem;
    padding-top: 5rem;
    text-align: center;

    .spinner {
      width: 5rem;
      height: 5rem;

      :global(.p-progress-spinner-circle) {
        stroke: #144c71 !important;
      }
    }
  }
}
