.daterange-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  :global .p-field-checkbox {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }

  :global .p-field {
    display: flex;
    flex-direction: column;
  }
}

.calendar-custom-panel {
  table {
    td {
      padding: 0 !important;
    }
  }
}
