.badge-container {
  position: absolute;
}
.badge {
  font-size: 1rem !important;
  min-width: 2rem !important;
  height: 2rem !important;
  line-height: 2rem !important;

  font-weight: 400 !important;
  border-radius: 20px !important;
  margin-right: -0.2rem;
}
.ordercreator {
  background-color: #122c49 !important;
  color: #fff !important;
}
.flexhandler {
  background-color: #577e9e !important;
  color: #fff !important;
}
.e2ehandler {
  background-color: #bce4f7 !important;
  color: #000000 !important;
}
.milestonehandler {
  background-color: #e5ddca !important;
  color: #000000 !important;
}
