.projectDetailsLoader__container{
    padding: 1.5rem;
    display:grid;
    align-items: center;
    justify-content: center;
    height:30rem;
    max-height: 30rem;
    grid-template-columns: 0.75fr 2fr;
    justify-content: center;
    align-content: center;
    
    .spinner {
        width:1.1rem;
        height: 1.1rem;
        :global(.p-progress-spinner-circle) {
            stroke: #144c71 !important;
        }
    }
}