.section-title{
  margin-left: -5%;
}

.help-details {
  margin-top: 30px;

  & > div {
    margin-bottom: 20px;
    line-height: 20px;
  }

  .title {
    margin-bottom: 15px;
  }
}

.existing-cert-error {
  color: #dc143c !important
}