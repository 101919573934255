.form-control {
  width: 100%;
}

:global(.focusable):focus {
  :global(.p-inputtext) {
    border-color: #0a99ff !important;
    box-shadow: 0 0 0 0.1rem #0a99ff !important;
  }
}

:global(.p-multiselect) {
  border: 0.0625rem solid #595958;
}

.form-label {
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 1rem;
  display: inline-block;
  margin-right: 6px;

  small {
    margin-left: 5px;
    font-size: 0.9rem;
    color: #5f5f5f;
  }
}

.form-field {
  margin-bottom: 20px;

  &.error {
    :global(.p-component),
    :global(.p-chips-multiple-container) {
      border-color: #ef4831 !important;
      border-width: 2px !important;
    }
  }
}

.help-text {
  font-size: 0.9rem;
  color: #5f5f5f;
  text-align: left;
}

.error-message {
  font-size: 0.9rem;
  color: #e12d33;
  margin-top: 3px;
  display: flex;

  & > span {
    & > div:first-child {
      display: inline;
    }

    margin-left: 0.25rem;
    display: inline-flex;
    flex-direction: column;
    gap: 0.25rem;
  }
}

:global(.disable-dropdown-trigger) {
  :global(.p-dropdown-trigger) {
    display: none !important;
  }
}
