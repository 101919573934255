li[aria-label="null"] {
  display: none !important;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  :global(.p-disabled) {
    background-color: #f0f0f0 !important;
    opacity: 1 !important;
  }
  
  &.customer-view {
    height: 100vh;
  }

  :global(.col-6) {
    width: 50%;
  }

  :global(.col-12) {
    width: 100%;
  }

  .step-menus-container {
    border-bottom: 2px solid #e9ebee;
    padding: 15px 15% 20px 15%;
  }

  .step-menus-container-with-form {
    border-bottom: 2px solid #e9ebee;
    padding: 15px 15% 20px 15%;
    display: flex;
    flex-direction: column;

    .gpi-form-name-container {
      margin-left: -20%;
      width: 17%;

      .gpi-form-name-label {
        font-weight: bold;
      }

      .gpi-form-name-value {
        padding-top: 10px;
        width: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .step-menus {
      width: 100%;
      margin-top: -3rem;
    }
  }

  .page-footer {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 2px solid #e9ebee;
  }

  .step-content {
    flex-grow: 1;
    flex-basis: 0;
    overflow-y: auto;
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
  }

  :global(.step-title) {
    margin-bottom: 30px;
    line-height: 1.4rem;
  }

  :global(.form-section) {
    margin-bottom: 10px;

    :global(.form-section-header) {
      border-bottom: 3px solid #e9ebee;
      margin-bottom: 30px;

      :global(.form-section-title) {
        margin-bottom: 10px;
        font-size: 1.15rem;

        small {
          margin-left: 10px;
          font-weight: normal;
        }
      }
    }

    :global(.question-w-checkbox-container) {
      display: flex;
      align-items: center;
      margin-left: -5%;
      margin-bottom: 25px;

      &>label {
        margin: 0px 0px 0px 10px;
        font-weight: bold;
      }
    }

    :global(.form-section-content) {
      margin-left: 16%;
      margin-right: 16%;
      margin-bottom: 30px;


    }
  }

  .banner {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>div {
      display: flex;
      align-items: center;
    }

    .message {
      font-weight: normal;
      color: black;
    }

    .link {
      color: #01518A;
      cursor: pointer;
      text-decoration: underline;
    }

    .icon {
      margin-left: 16px;
      margin-right: 16px;
    }

    .close-icon {
      margin-right: 40px;

      &:hover {
        cursor: pointer;
      }
    }

    .step-navigator {
      margin-left: 80px;
      display: flex;
      gap: 10px;

      button {
        background: transparent;
        border: none;
        font-size: 13px;
        color: #4a93e6;
        font-weight: bold;
        border-bottom: 2px solid #4a93e6;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 2px;

        &:disabled {
          opacity: 0.4;
        }
      }
    }

    &.warning {
      background-color: #ffedcc;
      border-left: 10px solid #ffa701;

      .icon {
        color: #ffa701 !important;
      }
    }

    &.danger {
      background-color: #fbd1d1;
      border-left: 10px solid #ea1a1c;

      .icon {
        color: #ea1a1c !important;
      }
    }

    &.info {
      background-color: rgba($color: #0C687B, $alpha: 0.10);
      border-left: 10px solid #0C687B;

      .icon {
        color: #0C687B !important;
      }
    }
  }

  :global(.p-dropdown) {
    border: 0.0625rem solid #595958 !important;
  }

  .modal-header {
    margin-top: 5px;
  }

  .footer-content {
    margin-top: 18px;
  }
}

:global(.p-dropdown-panel) {
  max-width: 1px;

  &,
  ul,
  li {
    width: fit-content !important;
  }

  ul,
  li {
    min-width: 100%;
  }
}

:global(.radio-button-container) {
  align-items: center;
  display: flex;
  user-select: none;
  gap: 5px;

  &>*:hover {
    cursor: pointer;
  }

  label {
    text-align: left;
    font-size: 0.95rem;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    border: 3px solid gray;
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type="radio"]:checked:before {
    background: #00518a;
  }

  input[type="radio"]:checked {
    border-color: #00518a;
  }
}