.certificate-details-layout {
    position: fixed;
    padding: 0;
    top: 0;
    right: 0;
    display: block;
    width: 450px;
    height: 100%;
    z-index: 2;
    background: #fff;
    overflow: auto;
}

.details-font {
    font-size: 14px !important;
}

.loading-container {
    height: 100%;   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}