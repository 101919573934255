.search-icon-btn {
  background-color: #06548c !important;
  color: white !important;
  cursor: pointer !important;
}

.filter-icon-btn {
  background-color: #6c757d !important;
  color: white !important;
  cursor: pointer !important;
  margin-left: 0.2rem;
}

.filter-cancel-button {
  padding: 0 !important;
  width: 86px;
  height: 34px;
}

.search-filter-div {
  display: flex;
  padding-top: 0.5rem;
}

.filter-hr {
  border-bottom: none !important;
}

.header-text {
  padding-left: 0.5rem;
}

.set-filter-btn {
  background-color: #01518a;
  color: white;
}

.filter-inputs-div {
  button {
    margin-left: 0.5rem;
  }
}

.clear-filter-btn {
  color: #000001;
  border-color: #000001;
}

.set-filter-btn-disabled {
  opacity: 0.5;
}

.clear-filter-btn-disabled {
  cursor: default !important;
  opacity: 0.3;

  &:hover {
    background-color: #fffffe !important;
    color: #595958 !important;
  }
}

.cancel-filter-btn {
  background-color: #999999 !important;
  color: #fff !important;
}

.custom-filter-list {
  margin-top: 1rem;
}

.delete-filter-btn {
  background-color: #e12d33 !important;
  color: #fff !important;
}

.filter-menu-label {
  background-color: #f8f9fa;
}

.custom-filter-section {
  max-height: 40vh !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-filters-input {
  padding: 0;
  margin: 1rem;
}

.your-filters-text {
  margin-left: 1rem;

  padding: 1em 1em 0em 1em;
}

.c-clear-container {
  padding: 0 !important;
  padding: 0;
  margin-top: 25px;
  margin-bottom: 25px;
}

.filter-menu-text-container {
  padding: 0.5rem 0 0.5rem 0;
}

.filter-menu-text {
  margin: 1rem;
}

.header {
  display: flex;
  flex: 1;
  justify-content: space-between;

  .svg-inline--fa {
    cursor: pointer;
  }
}

.input-container-with-close {
  margin: 0;
}

.search-input-with-close {
  padding-right: 30px;
}

.search-close-icon {
  font-size: 24px;
  cursor: pointer;
}
