.header {
    display: flex;
    justify-content: space-between;
    margin-top: .25rem;
}

.btn-delete-notifications {
    font-weight: 500;

    .icon {
        margin-right: .5rem;
    }
}

.delete-confirmation-dialog-one-ul-design {
    left: 18px;
    top: 18px;
    width: 605.5px;
    height: 346px;
  
    :global(.p-dialog-header) {
      border-bottom: solid 1.7px #999999;
      padding-top: 20px;
      padding-bottom: 20px;
      border-top-right-radius: 0px !important;
      border-top-left-radius: 0px !important;
    }
  
    :global(.p-dialog-content) {
      padding-top: 60px;
      padding-bottom: 60px;
      border-bottom: solid 1.7px #999999;
  
      :global(.p-confirm-dialog-icon) {
        display: none;
      }
  
      :global(.p-confirm-dialog-message) {
        margin-left: 5rem;
      }
    }
  
    :global(.p-dialog-footer) {
      padding-top: 20px;
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
  
      :global(.p-button) {
        width: 100px;
      }
  
      :global(.p-confirm-dialog-accept) {
        background-color: #0088cc !important;
      }
  
      :global(.p-confirm-dialog-reject) {
        background-color: #707070 !important;
        color: white !important;
      }
    }
  }