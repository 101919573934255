$table-border-color: #cbcfd1;
.invoice-price-form {
  display: flex;
  gap: 0.7rem;
  .invoice-price-field {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    row-gap: 0.5rem;
    width: 50% !important;
  }

  :global(td.d-flex) {
    gap: 0.5em;
  }

  table {
    border: 1px solid $table-border-color;
    thead > tr > th {
      border: 1px solid $table-border-color !important;
      padding-right: 0 !important;
      border-width: 0 0 1px 0 !important;
      :global(.p-column-header-content) {
        border-right: 2px solid #ccc;
        padding: 5px 0;
      }
    }

    tbody > tr > td {
      border-right: 1px solid #ccc !important;
      &:last-child {
        border-right-width: 0 !important;
      }
    }
  }
}

.no-border {
  border: none !important;
}

.modal-body {
  margin-bottom: 25px;
  margin-top: 5px;
  max-width: 550px;
}
