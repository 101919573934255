.ecd {
    all: unset;
}

.not-allowed {
    cursor: not-allowed !important;
}

.calendar-panel {
    td {
        padding: 0.1rem !important;

        :global(.p-disabled) {

            &,
            * {
                pointer-events: auto !important;
            }
        }

        span {
            background-color: white !important;
        }
    }
}

.project-line-is-completed {
    color: #3eb489 !important;
}

.calendar-container {
    &:hover {
        cursor: pointer;
    }

    :global(svg) {
        &:hover {
            color: #01518a;
        }

        color: #003357;
    }
}

.calendar-icon {
    margin-right: 10px;
}