.footer--disabled {
  button:not(:global(.add-new-cert-btn-cancel)) {
    pointer-events: none;
    opacity: 0.5;
  }
}

.invalidStep__button {
  cursor: pointer;
}

.modal-header-hr {
  margin-bottom: 0;
}

.modal-expand-icon {
  margin-right: .5rem;
}

.expanded {

  :global(.p-dialog-header),
  :global(.p-dialog-footer) {
    border-radius: 0px;
  }

  :global(.p-dialog-footer) {
    border-radius: 0px;
    padding-bottom: .25rem !important;
  }
}

:global(.add-new-cert-dialog) {
  :global(.modal-footer-hr) {
    margin-bottom: 1rem !important;
  }

  :global(.p-dialog-footer) {
    padding-bottom: 1rem;
  }
}

.spinner {
  margin-top: -10rem;
}

.spinner-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body-content {
  height: 100%;
}