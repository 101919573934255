.custom-filter-section {
    max-height: 40vh !important;
    overflow-y: auto;
    overflow-x: hidden;
    .filter-menu-label {
        background-color: #f8f9fa;
    }
    .filter-menu-text-container {
        padding: 0.5rem 0 0.5rem 0;
    }
    .filter-menu-text {
        margin: 1rem;
    }
   
    .custom-filters-input {
        padding: 0;
        margin: 1rem;
    
        .search-filter-div {
            padding-top: 0.5rem;
            .input-container-with-close {
                margin: 0;
                width:100%;
                .search-input-with-close {
                    padding-right: 30px;
                }
                .search-close-icon {
                    font-size: 24px;
                    cursor: pointer;
                }
            }
            .search-icon-btn {
                background-color: #06548c !important;
                color: white !important;
                cursor: pointer !important;
            } 
        }
    }

    .your-filters-text{
        margin-left: 1rem;
        padding: 1em 1em 0em 1em;
    }
    .c-clear-container {
        padding: 0 !important;
        padding: 0;
        margin-top: 25px;
        margin-bottom: 25px;
        .filter-cancel-button {
            padding: 0 !important;
            width: 86px;
            height: 34px;
        }
    }
}
.filter-hr {
    border-bottom: none !important;
    margin-bottom:1em;
}
.cert-refiners-section {
    max-height: 60vh !important;
    overflow-y: auto;
    padding-bottom: 2em !important;
    .select-a-filter-text {
        padding: 0;
        text-align: center;
    }
    .cert-refiners-accordion {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}


