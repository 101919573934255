.display-none {
    display: none !important;
}

.drag-body {
    background-color: #cedbe9 !important;
    border-right: 0 !important;
}

.checkbox-body {
    background-color: #cedbe9 !important;
    border-left: 0 !important;
}
.checkbox {
    height: 16px;
    width: 16px;
    accent-color: blue;
}
.rowfrozen {
    background-color: #f5f7f6 !important;
}

.lastFrozen {
    border-bottom:2px solid #dee2e6;
}

.freeze-column {
    height: 32px;
}