.export-overlay-panel {
  width: 13rem;

  :global(.p-overlaypanel-content) {
    padding: 0 !important;
  }
  
  .export-buttons {
    text-align: left;
    width: 100%;
    font-size: 14px;
    background-color: transparent;
    
    &:hover {
      background-color: #b8b8b8 !important;
    }
  }

  .export-radio-btn-div {
    margin-top: 0.5rem;
    border: solid;
  }
}

.export-options-dialog {
    width: 240px;
    height: 270px;
  }
