.invoice-price-form {
  display: flex;
  gap: 0.7rem;
  .invoice-price-field {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    row-gap: 0.5rem;
    width: 50% !important;
  }
}
