.select-template-header {
  font-weight: bold;
}

.p-multiselect-footer .items-selected {
  padding: 10px 15px 10px 15px;
  background-color: #f8f9fa;
}

.picker-container {
  gap: 10px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;

  .p-multiselect {
    min-width: 30rem;
  }

  .p-multiselect-label-container{
 

    .p-multiselect-label{
      height: unset !important;
      max-height: 100px !important;
      white-space: pre-wrap;
    }
  }

  .download-button-container {
    button {
      margin-left: 0.5em;
    }

    position: relative;
    width: 150px;
    margin-right: -44px;
  }

  .product-types-container {
    position: relative;
    flex: 1;

    .p-multiselect {
      width: 100%;
    }
  }
}

