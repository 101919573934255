.iconSpacer {
    padding-right: 10px;
}

.iconLabelSpacer{
    padding-right: 8px;
}
.inProgressIcon {
    color: #ffbe5e;
}

.completedIcon {
    color:#007C8B;
}

.overdueIcon {
    color: red;
}

.notYetStartedIcon{
    color:#DDDDDD;
}