.withdraw-certificate-modal {
    .buttons-container {
        display: flex;
        justify-content: center;
    }
    
    .date-picker {
        width: 100%;
        height: 44px;
        border: 0.0625rem solid #595958;
        border-radius: 3px;
        padding: 0.6rem 1rem;
    }
    
    .react-datepicker__triangle {
        left: -117px !important;
    }
    
    .date-picker:focus-visible {
        border-color: #0a99ff;
        outline: 0;
    }
}