.container {
  display: flex;
  cursor: pointer;
}

.files {
  display: flex;
  justify-content: space-between;
}

.files:last-child {
    padding-bottom: 10px;
}

.link {
  color: #01518a;
}

.link:hover {
  color: #0a99ff;
}

