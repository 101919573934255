.cert-list-header {
  width: 100%;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;

  .cert-action-button {
    width: 100px;
    height: 35px;
    padding: 0;
    font-size: 14px;
  }

  .export-div{
    width: 130px;
    font-weight: bold;
    padding: 0px 0px 0px 20px !important;
  }

  .export-btn{
    width: 40px;
    background-color: transparent;

    &:hover {
      background-color: #b8b8b8 !important;
    }
  }

  .total-certs-div {
    font-size: 30px;
    font-weight: bold;
  }

  .buttons-div {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 0.5rem;
    padding-left: 0;
  }

  .buttons-div button,
  .fav-icon {
    margin-left: 0.5rem;
  }

  .process-btn {
    border-color: #01518a !important;
    color: #01518a !important;

    &:hover {
      background-color: #01518a !important;
      color: #fff !important;
    }
  }

  .process-btn-disabled {
    opacity: 0.5 !important;

    &:hover {
      background-color: #fff !important;
      color: #01518a !important;
    }
  }

  .withdraw-icon {
    padding: 0px !important;
    margin-bottom: -0.1rem;
    font-size: 20px !important;
  }

  .fav-btn {
    margin-left: 5px !important;
  }

  .clickable-status-filters-container {
    margin-left: 2rem;
  }

  .clickable-status-filters {
    font-size: 14px;
    font-weight: bold;
    margin-right: 0.5rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .clear-filter-btn {
    font-size: 14px;
    margin-left: 0.5rem;
    color: #0d7eff;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: rgb(21, 21, 79);
    }
  }

  .active-cert-count {
    color: #001524;
  }

  .expiring-soon-count {
    color: #ffa700;
  }

  .under-revision-count {
    color: #0a99ff;
  }

  .obsolete-count {
    color: #e12d33;
  }

  .withdrawn-count {
    color: #999999;
  }
}
