.header {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        font-size: 1.5em;
    }

    a {
        padding: 0px;
    }
}

.provide-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;

    svg {
        margin-left: 1em;
        font-size: 1.5em;
    }
}

.local-rep-provider-radio-buttons {
    display: flex;
    gap: 10px;
    margin-left: 20px;
}

.copy-section {
    display: flex;
    align-items: center;

    margin-top: 3px;
    margin-bottom: 3px;

    label {
        margin-right: 1em;
    }

    svg {
        margin-left: 2em;
    }
}

.label-section {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .collapse {
        margin-left: 1em;
    }
}

.btn-collapse {
    padding: 5px 8px !important;
    margin-left: 14px;
    font-size: 1rem !important;

    &:hover {
        opacity: 1 !important;
        color: #000000 !important;
    }
}

.select-copy-detail {
    min-width: 400px;

    :global(.p-dropdown-label) {
        padding: .35rem;
    }
}

.btn-collapse-all-container {
    text-align: right;
    margin-bottom: 1em;

    .btn-collapse-all {
        padding: 8px 12px !important;

        &:hover {
            opacity: 1 !important;
            color: #000000 !important;
        }
    }
}

.no-scheme-header, .loading {
    text-align: center;
}