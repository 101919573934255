.project-header-container {
  width: 100%;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  .left-section {
    width: 70%;
    display: flex;
    align-items: center;
    & > * {
      margin-left: 0.4em;
    }
  }
  .right-section {
    width: 30%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0.35rem;
  }
}
.cemOrderOwnerIcon {
  color: #122c49;
}

.flexHandlerIcon {
  color: #577e9e;
}

.e2eHandlerIcon {
  color: #bce4f7;
}

.taskHandlerIcon {
  color: #e5ddca;
}

.btn-icon {
  background: none;
  min-width: 44px;
  height: 35px;
  padding: 0;
  border-radius: 2px;
  font-size: 1rem;
}

.btn-icon:hover {
  background: #e2e6ea;
  border-color: #dae0e5;
}

.dropDownPadding {
  width: 70px;
  :global(.p-inputtext) {
    padding: 0.25em 0.75em;
    color: black;
    font-size: 1.3rem;
  }
}
.dropdownViewBy {
  :global(.p-inputtext) {
    padding: 0.25em 0.75em;
  }
}

:global(.p-dropdown-trigger):has(.drop-down-icon) {
  width: 1.5rem !important;
  .drop-down-icon {
    color: #000000;
    &::after {
      content: "arrow_drop_down";
    }
  }
}
