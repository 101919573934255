.overlayCloseButton {
    :global(button.p-overlaypanel-close.p-link){
        top: 1rem;
        right: 1rem;
        background-color: transparent;
        color: black;
        &:enabled:hover {
        background-color: transparent;
        color: black;
        }
    }
}

.fieldName {
    font-weight: 600;
    text-align: right;
    padding: 5px;
}

.fieldValue {
    padding: 5px;
    overflow-wrap: break-word;
}