.floatRight {
    float: right;
}

.disabledButton {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.canBeDisabled {
  pointer-events: none;
}

.tooltipText {
  visibility: hidden;
  position: absolute;
  z-index: 100000;
  // width: 100px;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  // padding: 10px 15px 10px 15px;
}

.hoverText {
  position: relative;
  display: inline-block;
  text-align: center;
}

.bottom {
  top: 25px;
  left: -50%;
}

.hoverText {
  position: relative;
  display: inline-block;
  text-align: center;
}

.hoverText:hover .tooltipText {
  visibility: visible !important;
}

.wrapper {
	min-height: calc(102vh - 162px);
	max-height: calc(102vh - 162px);
}

.container {
  max-width: 100vw;
  display: flex;
  height: 100%;
}

.main {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 30px;
  flex-grow: 1;
  min-width: 0px;
  height: 100%;
}

Button {
  cursor: pointer;
}

.defaultCursor {
  cursor: default;
}

.centerContents {
  align-items: center;
  display: flex;
}

.dataTableHeight {
  height: calc(99vh - 161px) !important;
}

.dataTableBody {
  height: 20px;
}

.data-table-template-list {
  :global(.p-datatable-scrollable .p-datatable-thead){
    z-index: 2 !important;
  }
}

.icon {
  cursor: pointer;
  height: 12px;
  width: 12px;
  color: #4d4d4d;
}

.elipsisContainer {
  overflow: hidden;
  padding-right: 0px;
  text-overflow: ellipsis;
}

.angleDownIcon {
  height: 20px;
  width: 20px;
  justify-content: center;
  padding: 1px;
}