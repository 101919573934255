.tab-container {
  width: fit-content;
  min-width: 100%;
  background-color: #ffffff;
  :global(.p-tabview .p-tabview-nav li .p-tabview-nav-link) {
    padding: 0.5rem 1.25rem;
  }

  :global(.p-tabview .p-tabview-panels) {
    padding: 0rem;
  }

  .tab-panel {
    padding: 1.25rem;
  }

  :global(.p-tabview .p-component) {
    width: auto;
  }

  :global(.p-tabview .p-tabview-nav .p-tabview-ink-bar) {
    background-color: #00518a;
  }

  :global(.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link) {
    border-color: #00518a;
    color: #00518a;
  }

  :global(
      .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus
    ) {
    box-shadow: inset 0 0 0 0.2rem #00518a29;
  }
}
