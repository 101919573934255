.download-container {
    height: 100%;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .drag-and-drop-container {
    font-size: 20px;
    color: #01518a;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .download-icon-container {
    color: #01518a;
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .download-icon {
    height: 76px;
    width: 75px;
  }
  
  .download-title {
    font-size: 20px;
  }
  
  .vl {
    border-left: 1px solid #000;
    height: 100%;
  }
  
  .file-upload {
    border: 1px solid black;
    padding: 1em;
    height: 70%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .progress-bar {
    width: 90%;
  }
  
  .create-options-container {
    .options-container {
        display: flex;
        flex:1;
        justify-content: center;
      .options {
        padding: 5rem 1rem 5rem 1rem;
      }
    }
  }
  

  .fileList{
      border-top: 1px solid gray;
      display: flex;
      flex:1;
      flex-direction: column;
      margin-bottom: 1em;
      overflow-y: scroll;
      max-height: 25vh;
     
      .box{
          padding:1em;
          flex:1;
          display: flex;
          flex-direction: row;
          border: 1px solid gray;
          .icon{
              display: flex;
              flex: .5;
              font-size: 2em;
              justify-content: flex-start;
          }
          .filename{
            display: flex;
            flex: 9;
            flex-direction: column;
          
            span{
                font-size: .75em;
                margin-top: .55em;
                color: #37B400;
            }
          }
          .exit{
            display: flex;
            flex: .5;
          }
          
      }
  }