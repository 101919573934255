.cert-list-view-container {
  width: 100vw;

  .cert-search-and-refiners-area,
  .cert-list-grid-area {}

  .cert-list-grid-area {
    width: 80vw !important;
    max-width: 80vw !important;

  }

  .cert-list-grid-area-full {
    width: 100vw !important;
    max-width: 100vw !important;

  }

  .cert-search-and-refiners-area {
    max-width: 30vw !important;
    width: 30vw !important;
    padding: 0;
  }
}

.enabler {
  button {
    border: 0;
    background-color: #4D4D4D;
    color: #E6E6E6;
    border: 0;
    background-color: #B3B3B3;
    color: #4D4D4D;
    font-size: 24px;
    padding: .2em;
    padding: .2em;

    &.hide {
      background-color: #B3B3B3;
      color: #4D4D4D;

      &:hover {
        background-color: #4D4D4D;
        color: #E6E6E6;
      }
    }


    &:hover {
      background-color: #4D4D4D;
      color: #E6E6E6;
    }

  }
}