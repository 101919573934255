.projectDetailsFlexListItem__container {
    height:4rem;
    border-bottom: 1px solid #cbcfd1;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    color:#383838;
    cursor: pointer;
    max-width:100%;

    .header {
        align-self: auto;
        margin: 0;
        padding-left: 1.5rem;
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow:hidden;
    }
    
    .status {
        align-self: auto;
        margin: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        font-size:0.75rem;
        font-weight: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow:hidden;
    }

    &-active{
        color: #144c71;
        border-right: 7px solid #144c71;
        background: rgba(20, 76, 113, 5%);
        cursor:default;
    }
}