.container {
  input {
    margin-top: 0.5rem;
  }

  p {
    padding-bottom: 0.5rem;
  }

  :global(.p-dialog-header) {
    padding: 0rem !important;
    padding-left: 1.5rem !important;
  }

  :global(.p-dialog-header-icons) {
    padding: 1rem;
  }

  .error-message {
    color: red;
    font-size: 0.85rem;
    margin-top: 0.5rem;
  }

  i {
    margin-right: 0.25rem;
    margin-top: 0.5rem;
    font-size: 0.85rem;
    font-weight: bold;
  }
}
