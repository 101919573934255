.cert-list-header {
  .total-certs-div {
    font-size: 30px;
    font-weight: bold;
  }

  .buttons-div button,
  .fav-icon {
    margin-left: 0.5rem;
  }

  .process-btn {
    border-color: #01518a !important;
    color: #01518a !important;

    &:hover {
      background-color: #01518a !important;
      color: #fff !important;
    }
  }

  .process-btn-disabled {
    opacity: 0.5 !important;

    &:hover {
      background-color: #fff !important;
      color: #01518a !important;
    }
  }

  .withdraw-icon {
    padding: 0px !important;
    margin-bottom: -0.1rem;
    font-size: 20px !important;
  }

  .fav-btn {
    margin-left: 5px !important;
  }

  .clickable-status-filters-container {
    font-family: "Open Sans";
  }

  .clickable-status-filters {
    font-size: 14px;
    font-weight: bold;
    margin-left: 0.5rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .clear-filter-btn {
    font-size: 14px;
    margin-left: 0.5rem;
    color: blue;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: rgb(21, 21, 79);
    }
  }

  .active-cert-count {
    color: #001524;
  }

  .expiring-soon-count {
    color: #ffa700;
  }

  .under-revision-count {
    color: #0a99ff;
  }

  .obsolete-count {
    color: #e12d33;
  }

  .withdrawn-count {
    color: #999999;
  }
}


