.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  :global(.p-field-checkbox) {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }
}
