.add-new-cert-prod-details-modal {
  display: flex;
  flex-direction: column;
  height: 100%;

  .add-new-cert-prod-details-header {
    margin-bottom: 1.25rem;

    .select-model-btn {
      border-color: #01518a !important;
      color: #01518a !important;

      &:hover {
        background-color: #01518a !important;
        color: #fff !important;

        &:disabled {
          background-color: #fff !important;
          color: #01518a !important;
        }
      }
    }
  }

  new-cert-prod-dtls-col-tbl {
    border: 1px solid black;
  }

  .new-cert-prod-dtls-rmrks-div {
    display: flex;
    flex-direction: column;

    .remarks-text-area-div {
      flex-grow: 1;
      // padding: 0rem 1.3rem 0rem 1.3rem;

      .remarks-text-area {
        width: 100%;
        height: 100%;
        border-radius: 0px !important;
      }
    }
  }

  .new-cert-prod-dtls-col-hdr {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .fl-upl-del-icon {
    cursor: pointer !important;
  }
}

.models-list-container {
  margin-bottom: 1.5rem;

  :global(.p-datatable-wrapper) {
    overflow-y: scroll !important;
  }
}

.product-standards-container {
  max-width: 100%;

  &,
  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  div {
    line-height: 1.5rem;
  }
}

tr:global(.p-highlight) td {
  background: #eef2ff;
  color: #4338ca;
}

.product-standards-container--loading {
  width: 100%;
  text-align: center;
}

.action-button {
  all: unset;
}

.actions-container {
  display: flex;
  gap: 1rem;
  padding: 0rem 1rem;

  button:disabled {
    opacity: .3;
  }
}

.actions-container--header {
  margin-bottom: .6rem;
  margin-right: 18px;
}

.btn-edit-mode--check {
  color: #196473;

  &-disabled {
    opacity: 0.3;
  }
}

.btn-edit-mode--close {
  color: #cd2b17;

  &-disabled {
    opacity: 0.3;
  }
}

.standards-dropdown {
  max-width: 100% !important;

  :global(.p-multiselect-items-label) {
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
    height: fit-content !important;
    padding: 0.5rem 0px 0.5rem 0.5rem;
  }
}

.disabled-section {
  opacity: 0.5;
  pointer-events: none;
}

.product-standard--selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
  color: #495057 !important;

  button {
    all: unset;
    color: black;
  }

  label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.product-standard__placeholder {
  font-size: 0.875rem;
}

.row--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.models-table__header {
  display: flex;
  justify-content: space-between;
}

:global(.p-datatable-frozen-tbody) :global(.p-checkbox) {
  visibility: hidden;
}

.batch-edit-row-model-name {
  white-space: nowrap;
  font-style: italic;
  line-height: 1.5rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
}