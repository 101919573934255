.dialog {
  border-radius: 0px;
  box-shadow: 0 1px 3px #0000004d;

  > :first-child {
    border-bottom: 1px solid #dee2e6;
  }

  :global(.p-dialog-footer) {
    border-top: 1px solid #dee2e6;
    padding: 0;
  }

  .spinner__container {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 2rem;
    padding-top: 5rem;
    .spinner {
      width: 5rem;
      height: 5rem;
      :global(.p-progress-spinner-circle) {
        stroke: #144c71 !important;
      }
    }
  }

  .footer {
    align-content: center;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    gap: 0.75rem;
    padding: 1rem;

    .button-cancel {
      min-width: 7rem;
      background: transparent;
      border-color: #b8b8b8;
      outline-color: #b8b8b8;
      color: #b8b8b8;

      :hover {
        background: transparent;
        color: #9e9e9e;
        border-color: #9e9e9e;
        outline-color: #9e9e9e;
      }
    }

    .button-save-wrapper {
      .button-save {
        background-color: #0088cc !important;
        min-width: 7rem;
      }
    }
  }

  .container {
    padding-top: 1.5rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto !important;

    h1 {
      margin-block-end: 0 !important;
    }

    .remarks-text-area {
      min-height: 8rem;
      resize: none;
    }

    .button-area {
      display: flex;

      .button-add {
        margin-left: auto;
        background-color: #0088cc !important;
        max-width: 7rem;
      }

      .text-error {
        color: #ffab13 !important;
        font-style: italic;
      }
    }
  }
}

.tooltip {
  width: 30rem;

  :first-child {
    border-right-color: white !important;
  }

  :last-child {
    background: white;
    color: #222222;
  }
}

.header {
  margin-top: 1em;
}

.badge {
  font-size: 1rem !important;
  min-width: 2rem !important;
  height: 2rem !important;
  line-height: 2rem !important;

  font-weight: 400 !important;
  border-radius: 1rem !important;
  margin-right: -0.2rem;
  box-shadow: 0rem 0rem 0rem 0.1rem white;
  background-color: #01518a;
}
.value-container-outer {
  overflow: auto;
  max-height: 15rem;
  margin-bottom: 1rem;
  .value-container-wrapper {
    .value-container {
      display: flex;
      flex: 1;
      flex-direction: column;

      .value-header {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        p {
          margin-left: 0.5em;
        }
      }
      .value-content {
        border-bottom: 1px solid #dee2e6;
        padding: 0.5em;
        word-wrap: break-word;
      }
    }
  }
}

@media only screen and (max-height: 696px) {
  .value-container-outer {
    max-height: 8rem !important;

    .value-container-wrapper {
      .value-container {
        .value-header {
          p {
          }
        }
        .value-content {
        }
      }
    }
  }
}

@media only screen and (max-height: 632px) {
  .value-container-outer {
    max-height: 5rem !important;

    .value-container-wrapper {
      .value-container {
        .value-header {
          p {
          }
        }
        .value-content {
        }
      }
    }
  }
}
