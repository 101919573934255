.refiner-card {
  .card-header {
    cursor: pointer;
    background-color: white;
  }

  .refiner-dropdown-placeholder {
    position: absolute;
  }
}

.p-multiselect-label-container {
  width: 15rem;
}

.p-multiselect .p-multiselect-label {
  height: 45px !important;
}

.refiner-dropdown-placeholder {
  height: 45px !important;
  position: absolute !important;
}

.p-multiselect-panel {
  z-index: 1 !important;
}

.ul-card {
  padding: 0 !important;
}

.s-refiner-height {
  height: 40px !important;
}

.s-container-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
  height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.s-select-container {
  padding: 0 !important;
  margin: 1rem;
}

.p-multiselect-footer {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.refiners__show-more {
  button {
    all: unset;
    color: #dc3545;
    text-decoration: underline;
  }
}