.actions-container {
    display: flex;
    flex-direction: column;
}

.action-item {
    all: unset;
    cursor: pointer;
    text-decoration: none;
    font-size: .85rem;
    display: flex;
    gap: .6rem;
    align-items: center;
    color: black;
    width: 100%;
    padding: .5rem .5rem;

    &:hover {
        background-color: #eef2ff;
    }
}

.action-button {
    all: unset;
}

.overlay-panel {
    :global(.p-overlaypanel-content) {
        padding: 0rem;
        overflow: hidden;
    }
}

.disabled {
    cursor: not-allowed !important;
    opacity: .5 !important;
}