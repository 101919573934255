.file-upload-children{
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.file-upload-children-label{
    font-size: 12px;
    color:#000000;


}
.file-upload-children-button{
    background-color: #C4C4C4;
    border: 0px;
    font-size: 12px;
    padding:.6em;
}   