.add-new-cert-timeline {
  padding-left: 1.8rem;
  padding-right: 1.8rem;


}

.add-new-cert-continue-btn {
  background-color: #0088cc !important;
  color: #fff !important;
}

.add-new-cert-close-btn {
  background-color: #0088cc !important;
  color: #fff !important;
}

.add-new-cert-back-btn {
  background-color: #888888 !important;
  color: #fff !important;
}

.add-new-cert-cancel-btn {
  background-color: #ffffff !important;
  border-color: #999999 !important;
  color: #999999 !important;
}

.add-new-cert-cancel-btn:hover {
  background-color: #999999 !important;
  border-color: none !important;
  color: #ffffff !important;
}

.p-dialog-content {
  overflow: hidden !important;
}
.close-btn{
  background-color: #06548c  !important;
  color:white;
}

.add-new-cert-modal-body {
  padding-right: 1.8rem;
  padding-left: 1.8rem;
}