.container {
    :global(.p-dialog-header-icons) {
        position: absolute;
        right: 15px;
        top: 20px;
    }

    :global(.p-dialog-header) {
        padding: 20px 0px 0px 0px !important;
    }

    :global(.p-dialog-footer) {
        padding: 0px 0px 20px 0px !important;
    }

    .modal-header {
        margin-top: 5px;
        margin-left: 30px;
    }

    .btn-ok {
        background-color: #0088CC !important;
        min-width: 120px
    }

    .footer-content {
        margin-top: 20px;
    }

    .modal-content {
        padding: 0 5%;

        color: #27272A !important;
    }

    ul {
        margin-top: 30px;
    }

    li:not(:last-child) {
        padding-bottom: 14px;
    }

    .closing-message {
        margin-top: 40px;
    }
}