
.projectDetailsOrderLineGrid__container {
    padding-top: 2rem;
    overflow: hidden;
    width:100%;

    .order-line-grid {
        height:100%; 
        border-bottom: solid 1px #cbcfd1 !important;
        > div:first-child {
            height:100%;
        }  

        > div:first-child > table:first-child > thead> tr > th > div:first-child{
            flex:1;
        }

        > div:first-child > table:first-child > tbody > tr > td {
            background:none
        }
    }
}