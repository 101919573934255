.project-mangement-container {
  width: 100vw;
  .project-management-refiners-container {
    max-width: 20vw !important;
    width: 20vw !important;
    padding: 0;
  }
  .project-management-main {
    width: 80vw !important;
    max-width: 80vw !important;
  }

  .project-management-main-full {
    width: 100vw !important;
    max-width: 100vw !important;
  }
  .refiner-showhide-btn {
    button {
      border: 0;
      background-color: #4d4d4d;
      color: #e6e6e6;
      border: 0;
      background-color: #b3b3b3;
      color: #4d4d4d;
      font-size: 24px;
      padding: 0.2em;

      &.hide {
        padding-left: 0.75rem !important;

        &:hover {
          background-color: #4d4d4d;
          color: #e6e6e6;
        }
      }

      &:hover {
        background-color: #4d4d4d;
        color: #e6e6e6;
      }
    }
  }

  :global(.p-datatable-tbody)
    tr:has(:global(td .p-checkbox > div > input[checked])):not(
      :global(.p-datatable-row-expansion)
    ):global(:not(:has(svg.svg-inline--fa.fa-circle-check))) {
    background: unset !important;

    td[role="cell"] {
      background: #d1eafd !important;
    }
  }
}
