.btn-proceed {
  background-color: #0088cc !important;
}

.btn-cancel {
  background-color: #ffffff !important;
  color: #8e8e8e !important;
  border-color: #8e8e8e !important;
}

.adjustments-table {
  margin: 1.5rem 0rem;
  max-height: 16vh;
  overflow: auto;
  .table-col {
    &.col-projectNumber {
      width: 8rem;
      max-width: 8rem;
    }
    &.col-orderLineNumber {
      width: 13rem;
      max-width: 13rem;
    }
    &.col-flexTemplate {
      width: 10rem;
      max-width: 10rem;
    }

    .col-text-div {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
    }
  }
}

.modal-body {
  margin: 0px !important;
}
