.select-container {
  display: flex;
  justify-content: space-between;

  .ul-form__control {
    width: 20%;
    margin: 0 15px;

    .select-label {
      font-size: 15px;
    }
  }

  .ul-select {
    max-width: 300px;
    min-height: 34px;
    font-size: 11px;
  }

  :global(.p-multiselect) {
    line-height: 0.5rem;
    height: 34px;
    font-size: 11px;
    margin-top: 12px;
    border-radius: 2px;
  }
}
