.yes-button {
    width: 130px !important;
    justify-content: center;
    margin: 0 !important;
    background-color: #00518A;
}

.no-button {
    float: left;
    width: 130px !important;
    justify-content: center;
    margin: 0 !important;
    background-color: #707070;
}

.content {
    margin: 0 auto;
}

.content label{
    display: inline-block;
    text-align: center;
    line-height: 50px;
}

.centerFooterButton {
    justify-content: center;
}

.divScroll {
    max-height: 10rem;
    height: auto;
    border: 1px solid gray;
    overflow-y: auto;
}

.divTextOverFlow {
    overflow-x: auto;
}

.templatesCannotBeDeletedList {
  white-space: nowrap;
}

ul {
    list-style-type: disc;
}

hr {
    margin-top: 0px;
    margin-bottom: 2rem;
}

.dialog-close-button {
    :global(.p-dialog-header-icon.p-dialog-header-close.p-link) {
        &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 0 !important;
        }
    }
}