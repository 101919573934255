.file-upload-container {
  display: block;
  padding: 0.4rem 0.6rem;
  border: 0.0625rem solid #595958;

  &>div {
    display: flex;
    align-items: center;

    .file-upload-button {
      padding: 6px 12px;
      font-size: 0.9rem;
    }
  }

  .drag-area {
    flex-grow: 1;
    text-align: right;
    margin-right: 20px;
  }
}

.uploaded-file {
  border: solid 1px #595958;
  padding: 1rem 1.3rem;

  &:first-child {
    border-top: none;
  }

  &.success {
    .message {
      color: #146615;
    }
  }

  &.error {
    .message {
      color: red;
    }
  }
}

.filename-and-message-container {
  flex-grow: 1;
}

.close-icon-container {
  position: relative;

  .close-icon {
    position: absolute;
    top: -20px;
    right: 0px;

    &:hover {
      cursor: pointer;
    }
  }
}

.file-details {
  display: flex;
  align-items: center;
  gap: 20px;
}

.filename {
  all: unset;
  margin-bottom: 5px;

  &:disabled {
    cursor: default !important;
  }

  &.success {
    color: #01518A;

    &:hover {
      text-decoration: underline;
      color: #0A99FF;
    }
  }
}

.progress-bar {
  min-height: 9px !important;
  margin-top: 10px;
}