:global(.p-frozen-column) {
    z-index: 1;
}

.container {
    width: 100%;
    height: 100%;

    .handler-cell--saving {
        display: flex;
        align-items: center;
        gap: .5rem;

        span {
            opacity: .7;
        }
    }

    .edit-handler-container {
        display: flex;
        align-items: center;
        gap: .75rem;

        .edit-handler__autocomplete {
            input {
                padding: .25rem .25rem !important;
                height: 2.25rem;
                border-right: none;
                width: 15rem;
            }

            button {
                height: 2.25rem;
                background: none;
                border-color: #ced4da;
                border-left: none;
                color: #252525;
            }
        }
    }

    .edit-handler__btn-confirm,
    .edit-handler__btn-cancel {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;

        &:disabled {
            opacity: .5;
        }

        svg {
            font-size: 1.25rem;
        }
    }

    .edit-handler__btn-confirm {
        svg {
            color: green;
        }
    }

    .edit-handler__btn-cancel {
        svg {
            color: red;
        }
    }
}