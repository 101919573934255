.btn-yes {
    background-color: #0088CC !important;
}

.btn-no {
    background-color: #FFFFFF !important;
    color: #8E8E8E !important;
    border-color: #8E8E8E !important;
}

.modal-body{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    
    .modal-body-content{
        display:flex;
        flex-direction: column;
        gap: 1rem;

        .modal-body-text {
            font-weight: 600;
        }
    }
}

.title {
     padding:0 !important;
}