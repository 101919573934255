.container {
    :global(.p-dialog-header-icons) {
        position: absolute;
        right: 15px;
        top: 20px;
    }

    :global(.p-dialog-content) {
        padding: 0px !important;
    }

    .modal-header {
        margin-top: 5px;
        margin-left: 30px;
    }

    hr {
        border-color: #cfcfcf;
        border-bottom: 0px;
    }

    :global(.p-dialog-header) {
        padding: 20px 0px 0px 0px !important;
    }

    :global(.p-dialog-footer) {
        padding: 0px 0px 25px 0px !important;
    }

    .footer-content {
        display: flex;
        justify-content: center;
        margin-top: 18px;
    }

    .btn-save {
        background-color: #0088CC !important;
        min-width: 120px;
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .btn-cancel {
        background-color: #707070 !important;
        color: white !important;
    }

    .modal-content {
        label {
            margin-bottom: 20px;
            display: block;
            text-align: left;
        }

        .form-name-input {
            width: 100%;
            max-width: 470px;

            &.error {
                border: 2px solid #ef4831 !important;
            }
        }

        .error-message {
            color: red;
            font-size: .85rem;
            margin-top: 5px;
        }

        padding: 8% 10%;
    }
}