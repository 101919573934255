.dialog {

    :global(.p-dialog-header),
    :global(.p-dialog-content) {
        padding-bottom: 0rem;
    }

    .description {
        line-height: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .confirmation-message {
        margin-top: 2rem;
    }

    .certificate-numbers-container {
        display: flex;
        align-items: center;
        gap: .35rem;
        max-width: 100%;

        label {
            flex-shrink: 1;
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .btn-certificate-numbers-info {
            all: unset;
            font-size: .9rem;
        }
    }

    table {
        width: 100%;
    }
}

.associated-certificate-number--title {
    font-weight: bold;
    margin-bottom: 1rem;
}

.associated-certificate-number--list {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    overflow: auto;
    flex-grow: 1;

    div {
        min-height: 1.25rem;
    }
}

.associated-certificate-numbers--panel {
    :global(.p-overlaypanel-content) {
        height: 100%;
    }
}

.overlay-panel--content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.file-name-ecm-link {
    text-decoration: unset;
}

.ecm-node-id-download-btn {
    all: unset;
    color: #0d6efd;
}