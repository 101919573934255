$invoiceWidth: 400px;
.table-container {
  width: 100%;

  .project-detail-orderline-list-grid-container {
    :global(.p-multiselect-label-container) {
      width: auto;
    }

    :global(.p-multiselect .p-multiselect-label) {
      padding: 0.25rem;
      height: auto !important;
    }

    :global(.p-calendar .p-inputtext) {
      width: 7rem;
      height: 2rem;
    }

    :global(.p-calendar-w-btn-right .p-datepicker-trigger) {
      padding: 0 0.3rem;
      background-color: #f9fafa;
      border: 1px solid #cbcfd1 !important;
      color: #a1a2a2;
    }

    :global(.p-inputtext) {
      width: 95% !important;
      height: 2rem;
    }

    .project-detail-handler-container {
      :global(.p-inputtext) {
        padding-top: 0.4rem !important;
      }
    }

    padding: 0;

    :global(.p-datatable) {
      border: solid 1px #cbcfd1;
    }

    :global(.p-datatable-table) {
      min-width: 100%;
    }

    :global(.p-datatable-wrapper) {
      height: auto;
      overflow: hidden;
    }

    :global(.p-datatable-loading-overlay) {
      z-index: 6;
    }

    :global(.p-datatable-thead) {
      z-index: 5;
    }

    :global(.p-datatable-emptymessage) {
      font-style: italic;
      font-weight: normal;

      td {
        border: none;
        background-color: #ffffff !important;
        border: 0 !important;
        justify-content: center;
      }
    }

    :global(.p-datatable .p-datatable-thead > tr > th) {
      background: #ededed !important;
      color: #222222;
      border: 1px solid #cbcfd1;
      border-width: 0 0 1px 0;
    }

    :global(th .p-column-header-content) {
      display: flex !important;
      border-right: 2px solid #ccc;
      padding-right: 0.5rem;
      min-height: 22px;
    }

    :global(th .p-column-header-content .p-column-title) {
      width: 100%;
    }

    :global(th.p-sortable-column .p-column-header-content .p-column-title) {
      width: calc(100% - 28px);
    }

    :global(.p-datatable .p-sortable-column .p-sortable-column-icon) {
      margin-left: 0;
    }

    :global(.p-datatable .p-datatable-tbody > tr > td .p-row-toggler) {
      height: 1rem;
      width: 1rem;
      color: #222222;
    }

    :global(
        .p-datatable .p-datatable-tbody > tr.p-datatable-row-expansion > td
      ) {
      padding: 0;
    }

    :global(.p-datatable .p-datatable-tbody > tr > td) {
      color: #222222;
      font-weight: normal;
      border: 1px solid #cbcfd1;
      border-width: 0 0 1px 0;
      background: #ffffff;
    }

    .action-column-container {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-around;
      align-items: center;
      padding-right: 0.5rem;

      .action-edit {
        margin-right: 0.5rem;
        cursor: pointer;
      }

      .action-delete {
        cursor: pointer;
      }
    }
  }

  :global(table:has(.invoice-price-template)) {
    & > tbody > tr > :global(td.invoicePrice),
    & > thead > tr > :global(th.invoicePrice) {
      flex: 1 1 $invoiceWidth !important;
      width: $invoiceWidth !important;
    }
  }
}

.project-line-is-completed {
  color: #3eb489 !important;
}

.check-icon {
  color: #146615 !important;
  margin-right: 0.2rem;
}

.project-arrows-rotate-div {
  padding-right: 0.5rem;

  .project-arrows-rotate {
    cursor: pointer;
    color: #ffa700;
  }
}

.project-checkbox-div {
  padding-right: 0.5rem;
}

.project-checkbox {
  cursor: pointer;
  width: 22px !important;
}

.is-updating {
  opacity: 0.5;
}

.scroller {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -40px;
  left: -40px;
}
