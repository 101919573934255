.dialog {
  .dialog-header__title {
    border-bottom: 2px solid #c5c3c3;
    padding-bottom: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0px;
    font-size: 1.25rem;
  }

  :global(.p-dialog-header-icon) {
    position: absolute;
    top: 1.75rem;
    right: 1.5rem;
  }

  .dialog-body {
    p {
      font-weight: 600;
      line-height: 1.45rem;
    }

    margin: 2rem 1rem;
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    gap: 0.35rem;
    border-top: 2px solid #c5c3c3;
    padding-top: 1.8rem;

    button {
      min-width: 7rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
    }

    .btn-confirm {
      background-color: #007ac4;
    }

    .btn-cancel {
      background-color: #656565;
    }
  }
}
