    .e-control-wrapper {
        border-radius: 3px !important;
        border: 0.0625rem solid #595958 !important;
    }

    .e-ddl.e-input-group.e-control-wrapper .e-input {
        font-size: 12px;
        padding-left: 2px;
    }
    
    .e-ddl.e-input-group .e-input-group-icon,.e-ddl.e-input-group.e-control-wrapper .e-input-group-icon:hover {
        color: #595958 !important;
        font-size: 12px !important;
    }
    
    .e-ddl.e-input-group.e-control-wrapper.e-input-focus::before, .e-ddl.e-input-group.e-control-wrapper.e-input-focus::after {
        background: none !important;
        padding-left: 3px !important;
    }
    
    .e-ddl.e-input-group input.e-input::placeholder {
        color: #595958 !important;
    }

    // .e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard.e-valid-input.e-focus {
    //     padding-left: 3px !important;
    // }
