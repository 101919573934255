.yes-button {
  width: 130px !important;
  justify-content: center;
  margin: 0 !important;
  background-color: #00518a !important;
}

.no-button {
  float: left;
  width: 130px !important;
  justify-content: center;
  margin: 0 !important;
  color: #707070;
  border-color: #707070;
  background-color: #ffffff !important;
  &:hover {
    color: #444444 !important;
    border-color: #444444 !important;
  }
}

.cancel-button {
  float: left;
  width: 130px !important;
  justify-content: center;
  margin: 0 !important;
  background-color: #707070;
}

.content {
  margin: 0 auto;
}

.content label {
  display: inline-block;
  text-align: center;
  line-height: 50px;
}

ul {
  list-style-type: disc;
}

hr {
  margin-top: 0px;
  margin-bottom: 2rem;
}

.dialog-close-button {
  :global(.p-dialog-header-icon.p-dialog-header-close.p-link) {
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0 !important;
    }
  }
}
