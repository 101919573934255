.header {
  background: #f2f0f0;
  padding: 10px 15px;
  display: flex;
}

.country-image {
  height: 78px;
  width: 78px;
  margin-top: auto;
  margin-bottom: auto;
}

.no-country {
  border-radius: 50%;
  background: #cccccc;
  height: 78px;
  width: 78px;
  margin-top: auto;
  margin-bottom: auto;
}

.label-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-left: 0.5rem;
}

.status {
  width: 6rem;
  color: #fff;
}

.product-label {
  font-size: 20px;
  font-weight: 600;
  display: -webkit-box;

  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.country-label {
  font-size: 16px;
}

.active {
  background: #001524;
}

.expiring {
  background: #ffa700;
}

.revision {
  background: #0a99ff;
}

.obsolete {
  background: #e12d33;
}

.withdrawn {
  background: #999999;
}
