// th div.p-column-header-content > span {
// }

th {
  position: sticky !important;
  background-color: #ebebea !important;
}

.cert-prod-list-grid-container {
  margin-top: 1rem;

  .p-datatable-thead {
    z-index: 2;
  }

  .text-overflow-ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-width: 90px !important;
  }

  thead > tr {
    background-color: #e6e6e6;
  }

  .p-reorderable-column:not(.p-frozen-column) {
    z-index: 0 !important;
  }

  div > div > table > tbody > tr:nth-child(even) {
    background: #f5f5f5;
  }

  .p-datatable-row-expansion {
    background-color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
