.dialog {
  width: 95%;
  max-width: 80rem;

  :global(.pi-spinner) {
    margin-right: 0.5rem;
  }

  :global(.p-dialog-header) {
    border-bottom: 2px solid #c5c3c3;
  }

  :global(.p-dialog-footer) {
    border-top: 2px solid #c5c3c3;
    padding-top: 1.75rem;
  }

  :global(.p-dialog-content) {
    overflow-y: auto !important;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.field {
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    // color: black;

    &.required::after {
      content: "*";
      color: red;
      margin-left: 0.25rem;
    }
  }

  :global(.p-dropdown),
  :global(.p-calendar),
  :global(.p-multiselect),
  :global(.p-autocomplete),
  textarea,
  input {
    width: 100%;
  }

  &.customer-visible-field {
    label {
      color: #0088cc;
      font-weight: bold;
    }
  }
}

.form {
  margin-top: 1.5rem;

  & > div:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;

  .btn-save {
    background-color: #007ac4 !important;
    font-weight: 600;
    min-width: 7rem;
    display: flex;
    justify-content: center;
  }

  .btn-cancel {
    background-color: #656565 !important;
    font-weight: 600;
  }
}

.task-name-field,
.milestone-name-field {
  width: 60%;
}

.task-handler-field,
.milestone-handler-field {
  flex-grow: 1;
}

.order-line-association-field,
.predecessor-field,
.flex-task-status-field,
.on-hold-reason-field {
  width: 50%;
}

.start-date-field,
.due-date-field,
.duration-field {
  flex-grow: 1;
}

.customer-visibility-field {
  width: 50%;
}

.order-line-association-field {
  :global(.p-multiselect) {
    border: 1px solid #ced4da;
  }
}

.predefined-task-description {
  font-style: italic;
  font-size: 0.9rem;
  margin-top: 0.25rem;
  margin-left: -0.1rem;
}

.predefined-task-suggestion-item {
  color: black;
}

.invalid-error-message {
  color: #e24c4c;
  margin-top: 0.25rem;
  font-size: 0.875rem;

  &::after {
    content: ".";
  }
}

.flex-project-association-info-tooltip {
  max-width: 35rem;

  :global(.p-tooltip-arrow) {
    display: none !important;
  }

  :global(.p-tooltip-text) {
    background-color: white !important;
    color: black !important;
    line-height: 1.5rem;
  }
}

.spinner__container {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 2rem;

  .spinner {
    width: 3rem;
    height: 3rem;

    :global(.p-progress-spinner-circle) {
      stroke: #144c71 !important;
    }
  }
}

.customer-indicator-container {
  padding-top: 1rem;
  display: flex;
  align-items: end;
  justify-content: end;
  .indicator-text {
    font-style: italic;
    align-self: center;
    font-size: 0.85rem;
  }
}
