$defaultColor: #000;
.association-table {
  :global(.pi-chevron-down:before) {
    content: "\e70d";
    font-family: "e-icons" !important;
    color: $defaultColor;
  }

  :global(.p-dropdown-item.p-highlight) {
    color: $defaultColor;
  }

  :global(.p-dropdown-items-wrapper) {
    border: 1px solid $defaultColor !important;
  }

  :global(
      .p-dropdown-panel
        .p-dropdown-items
        .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover
    ) {
    color: $defaultColor;
    background: #eef2ff;
  }

  :global(td) {
    padding: 0 !important;
  }

  :global {
    .p-autocomplete {
      &:hover {
        input,
        button {
          border-color: #0a99ff !important;
        }
      }

      &:focus-within {
        button {
          border-color: #0a99ff !important;
          box-shadow: 0.1rem 0 0 0.1rem #0a99ff !important;
        }

        input {
          box-shadow: -1px 0 0 0.1rem #0a99ff !important;
        }
      }
    }
  }

  :global(.p-dropdown),
  :global(.p-multiselect) {
    max-width: 100%;
    font-size: 0.8rem !important;

    margin: 0.35rem 0rem;
  }

  :global(.p-dropdown-label),
  :global(.p-multiselect-label) {
    padding: 0.5rem 0.7rem;
  }

  .duplicate-error {
    background: #ffdee2;
  }

  .autocomplete-wrapper {
    width: 100%;
    height: 100%;
    padding: 2px 8px;
  }

  .auto-complete {
    width: 100%;
    :global(.p-button) {
      background: #ffffff !important;
    }

    input {
      padding: 0.25rem 0.25rem !important;
      height: 2.25rem;
      border-right: none;
      width: 15rem;
    }

    button {
      height: 2.25rem;
      border-color: #ced4da;
      border-left: none;
      color: #252525;
    }
  }

  .button-invalid {
    button {
      border-color: #e24c4c;
    }
  }

  .action-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    .button:hover,
    .button {
      padding: 2px 4px;
      border: none;
      background-color: rgb(0, 21, 36);
      color: #fff;
      border-radius: 5px;
      margin: 8px;
    }

    .icon {
      font-size: 20px;
      border-radius: 0;
      font-weight: 900;
    }
  }

  .button-wrapper-error {
    padding: 2.5rem 0;
  }

  .column-wrapper {
    padding: 0;
  }

  .invalid-column-error {
    background-color: #ffdee2 !important;
  }
}
