.calendar-container {
  width: 288px;
  height: fit-content;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
}

.navigation-container {
    height: 35px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.btn-navigation {
  border: none;
  height: 16px;
  width: 8px;
  background: none;

  &:disabled,
  &[disabled] {
    opacity: 0.4;
  }
}

:global(.react-datepicker) {
    font-size: 12px;
}

:global(.react-datepicker__header) {
  background-color: white;
  padding: 0;
}

:global(.react-datepicker__day-name),
:global(.react-datepicker__day),
:global(.react-datepicker__time-name) {
  width: 33.6px;
  height: 33.6px;
  line-height: 2.6;
}

:global(.react-datepicker__day--keyboard-selected), :global(.react-datepicker__day--selected) {
    background-color: #CCCCCC !important;
    color: black;
    border-radius: 100% !important;
    font-weight: normal;
}

:global(.react-datepicker__day--outside-month) {
    color: #D2D2D2;
}

:global(.react-datepicker__input-container) {
    input {
        color: #222222;

        &:disabled {
            color: #222222;
        }
    }
}

:global(.react-datepicker__day:hover) {
    border-radius: 100%;
    background-color: #EBEBEB;
}

:global(.react-datepicker__day-names) {
  background-color: #fafafa;
  height: 40px;
}

.year-dropdown,
.month-dropdown {
  border: solid 2px #e5e5e5;
  border-radius: 2px;
  outline: none;

  &:hover {
      cursor: pointer;
  }
}

.btn-navigation-prev {
    margin-right: 25px;
}

.btn-navigation-next {
    margin-left: 25px;
}