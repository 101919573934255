.btn-comment {
  &,
  &:hover {
    all: unset;
    background: none !important;
  }

  margin-left: 0.2rem;

  &:hover {
    color: #01518a !important;
  }

  &:disabled {
    color: #a9a9a9 !important;

    &:hover {
      color: #a9a9a9 !important;
    }
  }

  color: #003357 !important;
  &-icon {
    padding: 3px !important; 
  }
}

.btn-comment-disabled{
  cursor: default !important;
}

.comment-tooltip {
  :global(.p-tooltip-text) {
    color: #222222 !important;

    div {
      margin-top: 0.5rem;
      text-overflow: ellipsis !important;
      overflow: hidden !important;
    }
  }

  & > * {
    border-color: white !important;
    background-color: white !important;
  }
}
