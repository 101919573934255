.ul-datasetToolbox a[disabled]:focus,
.ul-datasetToolbox a[disabled]:hover {
  color: #595958;
}

.ul-app__body {
  //padding-right: 2rem;
  padding-left: 0rem !important;
}

.ul-body-2 {
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgb(42, 49, 58);
}

.ul-progressCircle {
  background: white;
}

.font-weight-bold {
  font-weight: bold;
}

.p-0 {
  padding: 0 !important;
}

.p-3 {
  padding: 3rem !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.plr-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center !important;
}

.align-content-center {
  align-content: center !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.p-progressbar {
  height: 0.5rem !important;
}

.float-right {
  float: right;
}

.modal-header-hr {
  border-bottom: none !important;
  border: 1px solid #d3d3d3;
  width: 200%;
  margin-left: -50%;
}

.modal-footer-hr {
  border-bottom: none !important;
  border: 1px solid #d3d3d3;
  margin-left: -24px;
  margin-right: -24px;

  button {
    cursor: pointer !important;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.w-10 {
  width: 10% !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.text-center {
  text-align: center;
}

.text-italic {
  font-style: italic;
}

.text-red {
  color: red;
}

.text-blue {
  color: #08c;
}

.display-none {
  display: none !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.d-none {
  display: none;
}

.default-save-button {
  background-color: #0088cc !important;
  color: #fff !important;
}

.default-cancel-button {
  background-color: #ffffff !important;
  border-color: #999999 !important;
  color: #999999 !important;
}

.default-cancel-button:hover:not([disabled]) {
  background-color: #999999 !important;
  color: #ffffff !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

div {
  &.p-dropdown {
    &:hover {
      border-color: #0a99ff !important;
    }
  }

  &.p-inputwrapper-read-only {
    .p-inputtext {
      pointer-events: none;
    }
  }

  &.p-multiselect &.p-component &.p-inputwrapper {
    border-color: #0a99ff !important;
    box-shadow: 0 0 0 0.1rem #0a99ff !important;
  }

  &.p-inputwrapper-focus {
    &:focus {
      border-color: #0a99ff !important;
      box-shadow: 0 0 0 0.1rem #0a99ff !important;
    }

    &:visited {
      border-color: #0a99ff !important;
      box-shadow: 0 0 0 0.1rem #0a99ff !important;
    }
  }
}

.p-inputtext {
  &:focus {
    border-color: #0a99ff !important;
    box-shadow: 0 0 0 0.1rem #0a99ff !important;
  }
}

.p-multiselect {
  &:hover {
    border-color: #0a99ff !important;
    box-shadow: 0 0 0 0.1rem #0a99ff !important;
  }
}

.p-inputtext {
  &:hover {
    border-color: #0a99ff !important;
  }

  &:focus {
    border-color: #0a99ff !important;
  }
}

.p-checkbox {
  &.p-highlight {
    border-color: #0a99ff !important;
    background: #0a99ff !important;
  }

  .p-checkbox-box.p-component.p-highlight {
    border-color: #0a99ff !important;
    background: #0a99ff !important;
  }

  .p-checkbox-box.p-component {
    &:hover {
      border-color: #0a99ff !important;
    }
  }
}

.p-checkbox-box.p-highlight {
  border-color: #0a99ff !important;
  background: #0a99ff !important;
}

.p-checkbox.p-component {
  .p-checkbox-box.p-highlight {
    border-color: #0a99ff !important;
    background: #0a99ff !important;
  }
}

th {
  &.p-resizable-column {
    .p-column-header-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      display: unset !important;

      span {
        div {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }
}

.centered {
  display: flex;
  justify-content: center;
  flex: 1;
}

.p-progressbar {
  min-height: 4vh !important;
  width: 100% !important;
}

.p-focus {
  border-color: #0a99ff !important;
  box-shadow: 0 0 0 0.1rem #0a99ff !important;
}

.p-dropdown-item.p-highlight {
  color: #0a99ff !important;
}

.p-multiselect-item.p-highlight {
  color: #0a99ff !important;
  // box-shadow: 0 0 0 0.1rem #0a99ff !important;
}

.p-multiselect-item {
  &:focus {
    box-shadow: inset 0 0 0 0.15rem #0a99ff !important;
  }
}

.p-radiobutton-box.p-highlight {
  border-color: #0a99ff !important;
  background: #0a99ff !important;
}

.p-multiselect-token {
  color: black !important;
}

.footer-center-button {
  display: flex;
  justify-content: center;
}

.center-loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-link {
  all: unset;
  color: #01518a;

  &:hover {
    color: #0a99ff;
  }
}
.custom-confirm-dialog {
  .p-dialog-header {
    padding: 10px 0px 10px 0px !important;
    border-bottom: 2px solid #cfcfcf;
    .p-dialog-header-icons {
      margin-right: 20px;
    }
    .p-dialog-title {
      margin-top: 5px;
      margin-left: 30px;
      font-size: 1.1rem;
    }
  }
  .custom-confirm-dialog-body {
    padding-top: 1rem;
    font-size: 1rem;
    color: #495057;
    .custom-space-below {
      margin-bottom: 1rem;
    }
  }
  .p-dialog-footer {
    border-top: 2px solid #cfcfcf;
    padding: 0px 0px 25px 0px !important;
  }
  .custom-confirm-dialog-footer {
    padding: 20px 0px 0px 0px !important;
    display: flex;
    justify-content: center;
    .custom-confirm-dialog-accept-btn {
      //
    }
    .custom-confirm-dialog-accept2-btn {
      background-color: #0088cc !important;
      color: #ffffff !important;
    }
    .custom-confirm-dialog-reject-btn {
      border-color: #888888 !important;
      color: #888888 !important;
      background-color: #ffffff !important;
    }
  }
}
.custom-tooltip.p-tooltip .p-tooltip-arrow {
  border-color: transparent;
}
.custom-tooltip.p-tooltip .p-tooltip-text {
  background-color: white;
  color: #222222;
}

.ul-bg-accent {
  background-color: #06548c;
}

.text-container-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.remove-button-focus:focus {
  outline: none !important;
  box-shadow: none !important;
}
