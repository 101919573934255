.componentGap {
    margin-top: 3em;
}

.project-template-container {
    height: 100%;
}

.project-template__body {
    max-height: 100vh;

    :global(.p-blockui-container) {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .footer {
        min-height: 45px;
    }
}

.loader-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.assocSchemeAccordion {
    max-height: 30vh;
    overflow-y: auto;
}

.project-template__body {
    height: 100%;

    .project-template__header {
        padding-bottom: 40px;
    }

    .project-template__associatedSchemes {
        padding-bottom: 40px;
    }

    .project-template__e2eMilestones {
        padding-bottom: 20px;
        flex-grow: 1;
        height: 0px;

        &>div {
            height: 100%;
        }

        :global(.p-datatable),
        :global(.p-datatable-wrapper),
        :global(.p-datatable-table) {
            height: 100% !important;
            max-height: unset !important;
        }
    }
}

.project-template__spinner {
    display: flex;
    flex-direction: column;
    align-items: center;

    .spinner {
        stroke: #363da1;
    }

    .spinner-label {
        font-size: 18px;
        color: white;
    }
}