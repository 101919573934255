.ul-search {
  border: 1px solid;
  position: relative;
  margin-top: 0.5rem;
  input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    padding: 0.4rem 1.8rem 0.4rem 0.75rem !important;
    border: none;
    line-height: 1rem;
  }
  button {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 0 !important;
    width: 2rem !important;
  }

  .clear-button {
    &:hover {
      cursor: pointer;
    }
    height: 34px;
    z-index: 2;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 34px;
  }
}
