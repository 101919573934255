.step-item {
  display: flex;
  align-items: center;
  padding-top: .8rem;
  opacity: 1;

  &:hover {
    cursor: pointer;
  }
}

.step-icon {
  font-weight: bold;
  margin-right: 10px;
  font-size: 1.5rem !important;
}

.step-description {
  font-size: 1.2rem !important;
}

:global {
  .ul-progressSteps__item.-active {
    font-weight: 600;
  }
  .ul-progressSteps__item.-complete {
    opacity: 0.5;
  }
  .ul-progressSteps__item.-warning {
    opacity: 0.5;
  }
  .ul-progressSteps__item.-danger {
    opacity: 0.5;
  }
}