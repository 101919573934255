.gmawb-clickable-ui {
    border-radius: 5px;
    border: 1px solid transparent;
    border-color: transparent;
    padding: 3px;
    &:hover {
        cursor: pointer;
        background-color: none;
        border: 1px solid #494dee;
        transition: all 0.2s ease;
    }
}

.gmawb-clickable-ui-disabled {
    padding: 3px;
}