form.invoice-form {
  display: flex;
  height: 34px;
  align-items: center;
  gap: 0.25rem;
  .button-group {
    display: flex;
    margin-left: 5px;
    button {
      padding: 0px !important;
      height: 25px !important;
      width: 25px !important;
      &[type="submit"] {
        color: #146615 !important;
      }
      &[type="button"] {
        color: #e12d33 !important;
      }
      &[disabled] {
        opacity: 0.5;
      }
    }

    svg {
      font-size: 20px !important;
    }
  }

  & > :global(.p-inputnumber),
  & > :global(.p-dropdown) {
    height: 34px;
  }
}

.invoice-button {
  :global(svg) {
    &:hover {
      color: #01518a;
    }
    color: #003357;
  }
}

.completed {
  color: #3eb489 !important;
}
