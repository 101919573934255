.datatable {
    margin-top: 1.5rem;

    th,
    td {
        padding: .75rem .8rem !important;
        font-size: 0.925rem;
    }
}

.no-wrap-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.on-hold-reason-dropdown-panel {
    max-width: 20rem !important;
    width: 20rem !important;

    li {
        width: fit-content !important
    }
}