$y-spacing: 1.5rem;

@mixin reset-panel {
  :global .p-panel-icons {
    height: auto;
  }
  :global .p-panel-header {
    padding: 0 $y-spacing !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    position: relative;
    background-color: white;
    border-radius: 0;
    justify-content: start;
    :global .p-link:focus {
      box-shadow: none;
    }

    :global .p-panel-title {
      margin: 1rem 0;
      flex-grow: 1;
      font-weight: 600;
    }

    :global .p-panel-toggler {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      opacity: 0;
    }
  }
}

.body {
  :global .p-divider-horizontal {
    border-bottom: 0.1rem solid #cccccc;
    margin: 0;
  }
}

.custom-panel {
  .hidden {
    display: none;
  }
  .refiner-header {
    :global .p-panel-content {
      padding: 0 !important;

      :global .p-panel:not(.refiner-header) {
        :global .p-panel-header {
          padding-left: 30px !important;
        }
        :global .p-panel-content {
          padding: 15px !important;
        }
      }
    }

    & > :global(.p-panel-header) {
      background-color: #f5f7f7 !important;
      & > :global(.material-icons) {
        scale: 1.25;
      }
      & > :global(.p-panel-title) {
        font-weight: bolder;
      }
    }
  }
  @include reset-panel;
  .disabled-icon {
    display: none;
  }
  .disabled {
    pointer-events: none !important;
    opacity: 0.5 !important;
    :global .p-panel-content {
      display: none;
      overflow: hidden;
      padding: 0 !important;
    }
    .disabled-icon {
      display: block !important;
    }
    :global(i.material-icons) {
      display: none;
    }
  }
}

:global(i.material-icons) {
  cursor: pointer !important;
}

.custom-tree-panel {
  :global .p-panel-header {
    font-size: 1rem !important;
    padding: 0 !important;
    flex-direction: row-reverse;
    border: none;
  }

  :global .p-panel-content {
    border: none;
    padding: 0;
    padding-left: 1rem;
    padding-top: 0.3rem;
  }

  :global .p-panel-toggler {
    padding: 0 !important;
    justify-content: start !important;
  }
  :global .p-panel-title {
    margin: 0.35rem 0 !important;
    font-weight: bolder !important;
  }

  .custom-tree-content {
    :global .p-panel-content {
      padding: 0;
      padding-left: 1.5rem !important;
      padding-top: 0.5rem !important;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}

.helper-text {
  padding: 1rem;
  border-bottom: 0.13rem solid #cccccc;

  label {
    font-size: 13px;
  }
}

.color-red {
  color: #dc3545;
}

.refiner-item-template {
  display: flex;
  flex-direction: row;
  .refiner-caption {
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  i {
    margin-right: -4px;
    &:hover {
      cursor: pointer;
    }
  }
}

.clear-button {
  background-color: #fff !important;
  color: #000003 !important;
  margin-bottom: 10px;
  padding: 0.5rem 1.25rem !important;
  font-weight: bold;
  border-radius: 3px;
}

.selected-refiner-wrapper {
  & > :global(.p-panel-header) {
    background-color: #f5f7f7 !important;
    border-radius: 0 !important;
  }

  & > :global(.p-toggleable-content) {
    :global(.p-panel-content) {
      border: none !important;
    }
  }
}

.your-filters {
  padding: 1.25rem;
  padding-top: 0;
  label {
    margin-bottom: 0.5rem;
    display: block;
  }
}
