$border: solid #bfbfbf 1px;

.container {
  :global(.p-accordion-header) {
    display: none;
  }

  :global(.p-toggleable-content) {
    padding: 0px !important;

    & > :global(.p-accordion-content) {
      padding: 0px !important;
      border: none !important;
    }
  }

  .number-of-factories {
    margin-bottom: 10px;

    .label {
      margin-bottom: 10px;
    }

    .input {
      max-width: 120px;
    }
  }

  :global(.form-section-content) {
    margin-left: 8% !important;
    margin-right: 8% !important;
  }

  .btn-collapse-all-container {
    text-align: right;

    .btn-collapse-all {
      padding: 8px 12px !important;

      &:hover {
        opacity: 1 !important;
        color: #000000 !important;
      }
    }
  }

  .factory-sites-information-container {
    margin-top: 10px;
    border-top: $border;

    .factory-site-information {
      border: $border;
      border-top: none;

      .header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: solid #bfbfbf 1px;
        height: 39px;

        .left {
          margin-left: 30px;
        }

        .left-15 {
          margin-left: 15px;
        }

        .right {
          gap: 15px;
        }

        .title {
          margin-bottom: 0px;
          margin-top: 0px;
        }

        .collapse-icon {
          margin-right: 15px;

          &:hover {
            cursor: pointer;
          }
        }

        .info-checkbox {
          margin-right: 10px;
        }

        & > div {
          display: flex;
          align-items: center;
        }

        .factory-site-info-src-options-container {
          margin-bottom: 5px;
          margin-top: 5px;
          gap: 10px;
          justify-content: flex-end;
          display: flex;
          flex-wrap: wrap;
        }

        .delete-factory-site-information {
          padding: 10px 14px 10px 14px;
          border-left: solid #bfbfbf 1px;

          .trash-icon {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .content {
        margin: 30px 60px;

        &.hidden {
          height: 0px;
          width: 0px;
          margin: 0px !important;
          overflow: hidden;
        }
      }
    }
  }

  .add-new-factory-site-information-container {
    :global {
      margin: 40px 0px;
    }

    .add-new-factory-button {
      padding: 10px 14px;
      border: solid #000000 1px;
      border-radius: 3px;
      background-color: #ffffff;
      color: #000000;
      font-weight: 600; //semi-bold
      font-size: 18px;
      width: 150px;
      height: 44px;

      &:hover {
        cursor: pointer;
        background-color: #000000;
        color: #ffffff;
        border-style: none;
      }
    }
  }
}

.delete-confirmation-dialog {
  :global(.p-dialog-header) {
    border-bottom: solid 1.7px #999999;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  :global(.p-dialog-content) {
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: solid 1.7px #999999;

    :global(.p-confirm-dialog-icon) {
      display: none;
    }
  }

  :global(.p-dialog-footer) {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;

    :global(.p-button) {
      width: 100px;
    }

    :global(.p-confirm-dialog-accept) {
      background-color: #0088cc !important;
    }

    :global(.p-confirm-dialog-reject) {
      background-color: #707070 !important;
      color: white !important;
    }
  }
}

.invisible {
  visibility: hidden;
}

[data-tooltip] {
  &:hover {
    &::after,
    &::before {
      z-index: 10 !important;
    }
  }
}
