.assign-collaborator-dialog {
  border-radius: 0px;
  box-shadow: 0 1px 3px #0000004d;
  > :first-child {
    border-bottom: 1px solid #dee2e6;
  }

  :global(.p-dialog-footer) {
    border-top: 1px solid #dee2e6;
    padding: 0;
  }

  .spinner__container {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 2rem;
    padding-top: 5rem;
    .spinner {
      width: 5rem;
      height: 5rem;
      :global(.p-progress-spinner-circle) {
        stroke: #144c71 !important;
      }
    }
  }

  .footer {
    align-content: center;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    gap: 0.75rem;
    padding: 1rem;

    .button-cancel {
      min-width: 7rem;
      background: transparent;
      border-color: #b8b8b8;
      outline-color: #b8b8b8;
      color: #b8b8b8;

      :hover {
        background: transparent;
        color: #9e9e9e;
        border-color: #9e9e9e;
        outline-color: #9e9e9e;
      }
    }

    .button-save-wrapper {
      .button-save {
        background-color: #0088cc !important;
        min-width: 7rem;
      }
    }
  }

  .content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto !important;

    .dropdown {
      .icon {
        cursor: pointer;
        margin-left: 0.5rem;
      }
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
    }
  }
}

.tooltip {
  width: 30rem;

  :first-child {
    border-right-color: white !important;
  }

  :last-child {
    background: white;
    color: #222222;
  }
}

.assign-co-container {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border: 0;
  border-radius: 6px;

  .assign-co-label {
    margin-left: 0.25rem;
    font-size: 1rem;
    font-weight: 550;
  }
}

.template-error-label {
  font-size: 1em;
  font-weight: 550;
}
.template-error-label-mb1 {
  font-size: 1em;
  font-weight: 550;
  margin-bottom: 1em;
}
.header {
  margin-top: 1em;
}
