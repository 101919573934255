.certificate-attachments__container {
  padding: 0.5rem;

  .container {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    display: flex;
    cursor: pointer;
  }

  .title__section {
    font-weight: 700;
    padding-bottom: 0.3rem;
  }
}

.files {
  display: flex;
  justify-content: space-between;
}

.files:last-child {
  padding-bottom: 1rem;
}

.link {
  color: #01518a;
}

.link:hover {
  color: #0a99ff;
  cursor: pointer;
  text-decoration: underline;
}

.delete-icon {
  cursor: pointer;
}
