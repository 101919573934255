.link-btn {
    font-size: 16px;
    margin-left: 0.5rem;
    color: #01518A;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
    border:0px;

    &:hover{
        color: #01518A;
    }
  
  
  }