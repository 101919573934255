.not-allowed {
  cursor: not-allowed !important;
}

.date-picker-div {
  cursor: pointer;
  width: 100%;
  margin: 0 !important;
  height: 2.75rem !important;
}

.date-picker-div-adhoc {
  cursor: default;
  width: 100%;
  margin: 0 !important;
  height: 2.75rem !important;
}

.calendar {
  input {
    padding: 2px 3px;
  }
}

.edit-mode {
  padding: 9.5px 3px;
}

.calendar-panel {
  td {
    padding: .1rem !important;

    :global(.p-disabled) {
      &,
      * {
        pointer-events: auto !important;
      }
    }

    span {
      background-color: white !important;
    }
  }
}

.calendar-container {
  color: #003357;
  &:hover {
      cursor: pointer;
      color: #01518a;
  }
}
