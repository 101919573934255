@mixin cert-scheme-dropdown-padding {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@mixin center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  text-align: center;
}

.add-new-cert-dialog {
  .p-dialog-content {
    .add-new-cert-modal-body {
      padding: 0.5rem 4rem 1rem 4rem;
      overflow-y: auto;
      height: calc(100% - 135px);

      .add-new-cert-modal-body-content {
        height: 100%;

        .change-cert-scheme-div {
          @include cert-scheme-dropdown-padding;
          margin-top: 1rem;

          .change-cert-scheme-btn {
            padding: 0.3rem;
            cursor: pointer !important;
          }

          .change-cert-scheme-btn:hover {
            color: lightgrey;
          }
        }

        .select-cert-scheme-div {
          display: flex;
          justify-content: center;
          align-items: center;
          @include cert-scheme-dropdown-padding;

          .select-cert-scheme-label {
            margin-right: 0.5rem;
          }

          .p-dropdown {
            width: 40rem;
          }
        }

        .add-new-cert-grouped-flds-container {
          margin-top: 2rem;

          .add-new-cert-grouped-flds-hdr>span {
            margin-left: 1rem;
            font-weight: bold;
            font-size: 20px;
            border: solid 2px lightgray;
            background-color: #f8f9f9;
            padding: 0.5rem;
            border-radius: 5px 5px;
          }

          .add-new-cert-grouped-flds-inputs {
            border: solid 2px rgb(226, 226, 226);
            border-radius: 5px 5px;
            margin-top: -1rem;
            padding: 3rem 1rem 1rem 1rem;
            min-height: 10rem;

            .dynamic-fld-div {
              padding: 1rem;
              margin-left: 2rem;

              .dynamic-fld-lbl {
                padding-bottom: 0.5rem;
              }

              .p-dropdown,
              .p-inputtext {
                width: 100% !important;
              }
            }

            .dynamic-fld-card {
              .dynamic-fld-card-hdr {
                text-align: center;
                border: solid 1px #cad1da;
                font-size: 20px;
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
                background-color: #dedede;
              }

              .dynamic-fld-card-body {
                padding: 0rem;

                .p-inputtext {
                  border: solid 1px #cad1da;
                  border-radius: 0px 0px !important;
                }

                .p-dropdown {
                  height: 90% !important;
                  border-radius: 0px;
                }
              }
            }

            .data-grouped-field {
              display: inline;
            }
          }
        }
      }
    }

    .loading-cert-attr-div {
      height: 40vh;
      @include center-content;

      .fetching-attr-load-label {
        margin-bottom: 1rem;
      }

      >div div {
        @include center-content;
      }
    }
  }
}