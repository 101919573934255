.project-order-details-container {
  label {
    text-align: right;
  }

  .field-row {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .w-15 {
    width: 15%;
  }

  .w-35 {
    width: 35%;
  }

  .w-85 {
    width: 85%;
  }

  .ul-input-style {
    width: 100%;

    input {
      width: 100%;
      border: 0.0625rem solid #595958;
      border-radius: 3px;
      box-shadow: none;
      padding: 0.6rem 1rem;
      line-height: 1.4;
      font-size: 1rem;
      min-height: 2.75rem;
    }

    input:focus {
      box-shadow: none !important;
    }
  }

  .ul-select-style {
    width: 100%;
    border: 0.0625rem solid #595958 !important;
    border-radius: 3px !important;
    box-shadow: none !important;
  }

  .ul-select-style:hover {
    border-color: #0a99ff !important;
  }

  input:disabled {
    background: #f0f0f0;
    opacity: 1 !important;
  }

  input:disabled:hover {
    border-color: #595958;
  }

  .add-error {
    color: #e12d33;
  }

  .add-helper {
    font-size: 12px;
  }
}