.customer-name-lookup-container {
  :global(.p-datatable) , :global(.p-datatable-wrapper) {
    height: 100%;
  }

  :global(.p-paginator), .table-container {
    border: solid 3px #ECEEEF;
    border-width: 3px 3px 3px 3px !important;
  }

  :global(.p-paginator) {
    margin-left: -3px;
    margin-right: -3px;
  }
  
  .search-btns-div {
    display: flex;
    justify-content: end;
    align-items: end;
    padding-right: 0px;
    button {
      margin-left: 1rem;
    }
  
    .clear-btn {
      background-color: #6c757d;
      color: #fff;
    }
  
    .search-btn {
      background-color: #007bff;
      color: #fff;
    }
  }
}