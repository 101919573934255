.btn-select-model {
  padding: 8px 12px;
  font-weight: normal !important;
  margin-top: 12px;
  font-size: 0.85rem;
}

.select-model-container {
  font-weight: bold;
  margin-bottom: 20px;

  label {
    text-align: left;
    display: block;
  }
}

.chips {
  min-height: 45px;

  ul {
    width: 100%;
    border: 0.0625rem solid #595958;
  }

  li {
    margin: 3px 0px
  }

  :global(.p-chips-token-icon::before) {
    content: "\e90b" !important;
  }

  &.manual-input-disabled {
    input {
      background-color: unset !important;
      display: none;

      &:hover {
        cursor: default !important;
      }
    }
  }
}

.product-types__multi-select__panel {
  :global(.p-multiselect-header) :global(.p-checkbox) {
    display: none !important;
  }
}

.model-name--red {

  &,
  :global(.p-chips-token-label) {
    color: red;
  }

}

.custom-chip {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  display: flex;
  gap: .5rem;
  cursor: default;

  button {
    all: unset;
    display: flex;
    align-items: center;
  }
}

.container {

  :global(.p-chips-token),
  :global(.p-multiselect-token),
  .custom-chip {
    background-color: #e6e6e6 !important;
    color: #000001 !important;
    border-radius: 3px !important;
  }
}

.product-types__multi-select {
  :global(.p-multiselect-token-icon) {
    &:before {
      content: "\e90b" !important;
    }
  }

  :global(.p-multiselect-label) {
    display: flex;
    flex-wrap: wrap;
    height: fit-content !important;
    row-gap: .5rem;
  }
}

.customer-input-models-error-message {
  color: #E12D33;
  line-height: 1.5rem;
  font-size: .9rem;
  margin-bottom: 1.5rem;
}

.btn-model-search {
  all: unset;
  text-decoration: underline;
}