.container {
    font-size: 0.9rem;
    color: #e12d33;
    margin-top: 3px;

    .btn-view-details {
        all: unset;
        margin-right: 5px;
        margin-left: 3px;
    }
}

.title__value {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
}

.values-container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.btn-close-overlay {
    all: unset;
}

.overlay-panel-title {
    display: flex;
    justify-content: space-between;
}

.details-overlay-panel {
    width: 100vw;
    max-width: 500px;
    color: black;

    :global(.p-overlaypanel-content) {
        padding: 1rem;
    }

    .overlay-panel-body {
        margin-top: 1rem;
        line-height: 1.4rem;
    }

    .overlay-panel-footer {
        margin-top: 1.25rem;

        button {
            all: unset;
            border-radius: .2rem !important;
        }

        .btn-replace {
            padding: .6rem 1.3rem !important;
            background-color: #00508C;
            color: white;
            margin-right: .5rem;
        }

        .btn-keep {
            color: #00508C;
            border: #00508C 1px solid;
            padding: .6rem 1.75rem !important;

            &:hover {
                background-color: #f4f4f4;
                ;
            }
        }
    }
}