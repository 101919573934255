$table-border-color: #cbcfd1;
.custom-table {
  border: 1px solid $table-border-color;
  thead > tr > th {
    border: 1px solid $table-border-color !important;
    padding-right: 0 !important;
    border-width: 0 0 1px 0 !important;
    :global(.p-column-header-content) {
      border-right: 2px solid #ccc;
      padding: 5px 0;
    }
  }

  tbody > tr > td {
    border-right: 1px solid #ccc !important;
    font-size: 0.9rem;
    line-height: 1.5rem;
    &:last-child {
      border-right-width: 0 !important;
    }
  }
}
