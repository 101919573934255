.pmr-handler-reassignment-dialog {

    border-radius: 0px;

    > :first-child {
        padding: 0.5rem;
        border-bottom: 1px solid #dee2e6;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    :global(.p-dialog-footer) {
        border-top: 1px solid #dee2e6;
        padding: 0;
    }

    :global(.p-dialog-header-icons) {
        align-self: center;
    }
    .spinner__container {
        display: flex;
        flex-direction: column;
        align-self: center;
        gap: 2rem;
        padding-top: 3rem;

        .spinner {

            width: 5rem;
            height: 5rem;

            :global(.p-progress-spinner-circle) {
                stroke: #144c71 !important;
            }
        }
    }

    .header {
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .footer {
        align-content: center;
        display: flex;
        justify-content: center;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        gap: 0.75rem;
        padding: 1rem;

        .button-cancel {
            height: 2.5rem;
            width: 7rem;
            background: transparent;
            border-color: #b8b8b8;
            outline-color: #b8b8b8;
            color: #b8b8b8;
            margin: 0;

            :hover {
                background: transparent;
                color: #9e9e9e;
                border-color: #9e9e9e;
                outline-color: #9e9e9e;
            }
        }

        .button-save-wrapper {
            background: #0088cc;
            border: 1px solid #a9dff7;

            .button-save {
                height: 2.5rem;
                width: 7rem;
                margin: 0;
                background: #0088cc;
                border: none;

                :hover {
                    border: none;
                }
            }

            :hover {
                background: #0182c2;
            }
        }
    }

    .content {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        display: flex;
        flex-direction: column;
        overflow-y: auto !important;

        .dropdown {

            .icon {
                cursor: pointer;
                margin-left: 0.5rem;
            }

            display: flex;
            flex-direction: column;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
}

.tooltip {
    width: 30rem;

    :first-child {
        border-right-color: white !important;
    }

    :last-child {
        background: white;
        color: #222222;
    }
}

.tooltip-content {
    line-height: 1.5rem;
}