.cert-refiners-section {
    max-height: 60vh !important;
    overflow-y: auto;
    padding-bottom: 2em !important;
}


.cert-refiners-accordion {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.select-a-filter-text {
    padding: 0;
    text-align: center;
 
}