.container {
  max-width: 100vw;
  display: flex;

  .main {
    padding-left: 30px;
    padding-right: 30px;
    flex-grow: 1;
    min-width: 0px;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 20px;
    padding-right: 5px;

    .banner {
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div {
        display: flex;
        align-items: center;
      }

      .message {
        font-weight: normal;
        color: black;
        margin-left: 5px;

        &.no-margin {
          margin-left: unset !important;
        }
      }

      .link {
        color: #01518a;
        cursor: pointer;
        text-decoration: underline;
      }

      .icon {
        margin-left: 3px;
        margin-right: 3px;
      }

      .close-icon {
        margin-right: 20px;

        &:hover {
          cursor: pointer;
        }
      }

      &.warning {
        background-color: #ffedcc;
        border-left: 10px solid #ffa701;

        .icon {
          color: #ffa701 !important;
        }
      }

      &.info {
        background-color: rgba($color: #0c687b, $alpha: 0.1);
        border-left: 10px solid #0c687b;

        .icon {
          color: #0c687b !important;
        }
      }
    }

    .sub-header {
      display: flex;
      flex-direction: row;
      gap: 20px;
      margin-top: 20px;

      .search-container {
        flex-grow: 1;
        text-align: right;

        & > span,
        input {
          width: 100%;
          max-width: 400px;
        }

        input {
          padding: 10px 20px;
          border-color: #252525;
          border-radius: 4px;
        }

        i {
          right: 25px;
          font-weight: bold;
        }
      }

      .icon-button {
        background: none;
        padding: 0px;
        color: black !important;
        border: none !important;
      }

      .btn-launcher {
        background-color: #06548c !important;
        color: white !important;
      }

      .trash-icon {
        &:not([disabled]):hover {
          cursor: pointer;
        }

        &[disabled] {
          opacity: 0.5;
        }
      }

      & > span {
        margin-top: 8px;
      }
    }
  }

  :global(.p-resizable-column) :global(.p-column-header-content) {
    display: flex !important;

    :global(.p-column-title) {
      width: calc(100% - 28px);
    }
  }

  td,
  th {
    min-width: 40px !important;
  }

  :global(.p-datatable-wrapper) {
    min-height: calc(100vh - 165px);
    max-height: calc(100vh - 165px);
  }

  :global(.text-overflow-ellipsis) {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-width: 90px !important;
  }

  :global(.column-fixed-width-left) {
    width: 90% !important;
  }
}

.delete-confirmation-dialog {
  :global(.p-dialog-header) {
    border-bottom: solid 1.7px #999999;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  :global(.p-dialog-content) {
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: solid 1.7px #999999;

    :global(.p-confirm-dialog-icon) {
      display: none;
    }
  }

  :global(.p-dialog-footer) {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;

    :global(.p-button) {
      width: 100px;
    }

    :global(.p-confirm-dialog-accept) {
      background-color: #0088cc !important;
    }

    :global(.p-confirm-dialog-reject) {
      background-color: #707070 !important;
      color: white !important;
    }
  }
}

.delete-confirmation-dialog-one-ul-design {
  left: 18px;
  top: 18px;
  width: 605.5px;
  height: 346px;

  :global(.p-dialog-header) {
    border-bottom: solid 1.7px #999999;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }

  :global(.p-dialog-content) {
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: solid 1.7px #999999;

    :global(.p-confirm-dialog-icon) {
      display: none;
    }

    :global(.p-confirm-dialog-message) {
      margin-left: 5rem;
    }
  }

  :global(.p-dialog-footer) {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

    :global(.p-button) {
      width: 100px;
    }

    :global(.p-confirm-dialog-accept) {
      background-color: #0088cc !important;
    }

    :global(.p-confirm-dialog-reject) {
      background-color: #707070 !important;
      color: white !important;
    }
  }
}

.tab-menu {
  a {
    border-bottom: none !important;
    box-shadow: none !important;
  }

  :global(.p-highlight) {
    border-top: 3px solid #b10820;

    a {
      color: #495057 !important;
    }
  }

  li:not(:global(.p-highlight)) {
    a {
      background-color: #ebebeb !important;
    }
  }
}
