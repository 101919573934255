.refiner-date-type {
  width: 79px;
  margin-right: 0px;
}

.refiner-dropdown-placeholder {
  &:after {
    content: "" !important;
  }

  &:not(:first-child) {
    display: none !important;
  }
}

.calendar-refiner__past-due {
  margin: 0rem 0rem 1.75rem;
  display: flex;
  align-items: center;

  label {
    margin-left: 0.5rem;
  }
}

.calendars-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.show-more {
  margin-top: 0.75rem;
  font-weight: bold;
  color: #007bff;

  button {
    all: unset;
    text-decoration: underline;

    &:disabled {
      opacity: 0.6;
    }
  }
}

.container {
  .checkboxes-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .field-checkbox {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  &.expanded {
    width: 20vw;
    min-width: 20vw;
    max-width: 20vw;
    max-height: 100vh;
  }

  position: relative;

  & > .body {
    & > .header {
      background-color: #f8f9fa;
      min-height: 55px;
      display: flex;
      align-items: center;
      width: 100%;

      label {
        font-weight: 600 !important;
        font-size: 16px;
        margin-left: 1rem;
      }
    }

    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
      border-bottom: 0.13rem solid #cccccc;
    }
  }

  .btn-expand {
    border: 0;
    background-color: #b3b3b3;
    color: #4d4d4d;
    font-size: 24px;
    padding: 0.2em;
    position: absolute;
    right: -30px;
    z-index: 1000;
  }

  .refiners-container {
    flex-grow: 1;
    overflow-y: scroll;
  }

  .selected-filters-container {
    .custom-filter-container {
      border-bottom: 0.13rem solid #cccccc;
      padding: 0.5rem 0rem 1.5rem;
      margin-bottom: 1rem;

      & > div {
        padding: 0rem 0.7rem;
      }

      .custom-filter__label {
        display: block;
        color: #595958;
        font-size: 0.875rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .custom-filter__input-container {
        margin: 0px;
        flex-grow: 1;
      }

      .custom-filter__input {
        width: 100%;
        padding: 0.4rem 2rem 0.4rem 0.75rem;
        min-height: 1rem;
      }

      .custom-filter__btn-search {
        background-color: #06548c !important;
        color: white !important;
        cursor: pointer !important;
        padding: 0rem 0.5rem;
      }

      .custom-filter__clear-icon {
        cursor: pointer;
        font-size: 1rem;
        height: 1.1rem;
      }
    }

    padding: 1rem 0rem;
    overflow-y: scroll;
    max-height: 35%;
    height: fit-content;
    min-height: 30%;

    & > label {
      color: #595958;
      font-size: 0.875rem;
      font-weight: bold;
    }

    .btn-clear-container {
      margin-top: 30px;
      text-align: center;

      button {
        background-color: white !important;
        color: #000003 !important;
        padding: 0.5rem 1.25rem !important;
        margin-bottom: 10px;

        &:disabled:hover {
          cursor: unset !important;
        }
      }
    }

    .your-filters__label,
    .selected-filters {
      padding: 0rem 0.7rem;
    }

    .selected-filters {
      margin-top: 6px;

      .refiner-filters {
        &:not(:last-of-type) {
          margin-bottom: 12px;
          padding-bottom: 12px;
          border-bottom: 0.13rem solid #cccccc;
        }

        button {
          padding: 0px;
          background-color: unset !important;
        }

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          label {
            font-weight: bold;
            font-size: 16px;
          }

          .left {
            display: flex;
            align-items: center;

            &:hover,
            & > *:hover {
              cursor: pointer;
            }
          }

          .btn-remove-refiner-filters {
            text-align: center;
            width: 25px;

            i {
              top: -0.4rem !important;
              margin-top: 0px !important;
            }
          }
        }

        .body {
          margin-top: 10px;

          .selected-filter {
            &:not(:last-of-type) {
              margin-bottom: 10px;
            }

            display: flex;
            align-items: center;
            justify-content: space-between;

            label {
              text-align: left;
              margin: 0px 24px;
              min-width: 1px;
              overflow-wrap: break-word;
            }

            .btn-remove-filter {
              text-align: center;
              width: 24px;

              i {
                top: 0.2rem;
                font-size: 18px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .helper-text-container {
    padding: 1rem;
    border-bottom: 0.13rem solid #cccccc;

    label {
      font-size: 13px;
    }
  }

  .filters-container {
    margin-bottom: 30px;

    .field {
      border-bottom: 0.13rem solid #cccccc;

      &.expanded {
        .field-header {
          border-bottom: 0.0625rem solid #cccccc;
        }
      }

      .field-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 1.2rem;
        padding: 0.4rem 0;

        &:hover {
          cursor: pointer;
        }

        .label {
          font-size: 14px !important;
          font-weight: 600 !important;
        }

        i {
          font-size: 30px;
        }
      }

      .field-control {
        padding: 30px 1.2rem;
      }
    }

    .read-only {
      pointer-events: none !important;
      opacity: 0.5;
    }
  }

  .numeric-refiners {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .numeric-refiner__input {
      width: 100%;
    }

    .numeric-refiner__label {
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
    }

    input {
      padding: 0.35rem;
    }
  }
}

.color-red {
  color: #dc3545;
}

.multiselect-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  .multiselect-items {
    width: 100%;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .custom-filter {
      width: 100%;
      span {
        display: block;
      }
      input {
        width: 100%;
      }
    }
    button {
      text-align: center;
    }
  }
}

.refiner-calendar {
  table {
    td {
      padding: 0 !important;
    }
  }
}
.input-container {
  display: inline-block;
  width: 100%;
  input {
    display: inline;
    min-width: 250px;
  }
  .input-hidden-label {
    height: 0;
    visibility: hidden;
    margin: 0 2rem;
  }
}

.custom-option {
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
