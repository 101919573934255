.container {
    width: 500px;
    background-color: white;
  
    .close-icon-container {
      text-align: right;
      margin-right: 15px;
      margin-bottom: 10px;
  
      .close-icon {
        &:hover {
          cursor: pointer;
        }
      }
    }
  
    .row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 6px;
      padding-right: 10px;
      padding-left: 10px;
      margin-bottom: 10px;
  
      .label {
        text-align: right;
        font-weight: bold;
        flex-basis: 50%;
      }
  
      .value {
        flex-basis: 50%;
      }
    }
  }
  
  :global(.popover-arrow) {
    left: 2px !important;
    width: 1rem;
    height: 1rem;
    margin: 0.3rem 0;
    border-top: unset !important;
    background-color: white;
  
    &:before,
    &:after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  
    &:before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.45);
    }
  
    &:after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff;
    }
  }

  .information-icon{
    cursor: pointer;
  }