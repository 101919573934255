.header-container {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2em;

    h4 {
        text-align: center;
        padding: 0;
    }

    label {
        text-align: center !important;
    }
}

.select-copy-detail {
    min-width: 420px;
    margin-top: 3px;
    margin-bottom: 3px;

    :global(.p-dropdown-label) {
        padding: .35rem .7rem;
    }
}

.header {
    flex: 1;
    display: flex;
    justify-content: space-between;

    svg {
        font-size: 1.5em;
    }

}

.add-rep-button {
    background-color: white;
    color: black;
    border-color: black;
    margin-top: 30px;
}

.accordion-header {
    margin-bottom: 2em;
    line-height: 1.2rem;
}