.page-container {
  padding: 0.5rem 0.5rem 0;
  .link-btn {
    background-color: #0088cc;
    color: white;
  }
  .delete-btn {
    background-color: #e12d33;
    color: white;
  }
  .header-container {
    padding: 0.25rem 0;
    .button-container {
      text-align: right;
    }
    .note-container {
      font-style: italic;
      span {
        font-size: 0.9rem;
      }
    }
  }
  .datatable-container {
    :global(.p-datatable-wrapper) {
      height: calc(100vh - 70px);
      overflow-y: scroll;

      table {
        thead {
          z-index: 2;
        }
      }
    }

    :global(th.p-resizable-column .p-column-header-content) {
      display: flex !important;
    }
    :global(th.p-resizable-column .p-column-header-content .p-column-title) {
      width: calc(100% - 28px);
    }
    :global(.p-datatable-emptymessage td) {
      width: 100%;
    }
    .datatable-emptymessage-container {
      width: 100%;
      justify-content: center;
      text-align: center;
      align-items: center;
      display: flex;
      height: calc(100vh - 130px);
      .datatable-emptymessage-label {
        font-size: 0.8rem;
        font-style: italic;
      }
    }
    .already-selected {
      pointer-events: none;
      opacity: 0.5;
    }
    .gridaction-container {
      width: 100%;
      button {
        padding: 0.1rem;
        font-size: 1rem;
        color: #000000 !important;
      }
      .linkToWbAndEcm {
        color: #0a99ff !important;
        font-style: italic;
        text-decoration: underline !important;
        padding-right: 2px;
        background: none;
        border: none;
        width: 100%;
        &:hover {
          color: #01518a !important;
          cursor: pointer;
        }
        &:disabled {
          pointer-events: none;
          opacity: 0.5;
        }
        span {
          padding-right: 0.25em;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
          text-align: right;
        }
      }
    }
  }
}

.hideHeaderCheckbox {
  :global(.p-column-header-content) {
    visibility: hidden;
  }
}
