.equipment-radio-type-container {
  display: flex;
  gap: 30px;

  & > div {
    flex-grow: 1;
    flex-basis: 0;
  }

  &-select {
    :global(
        .p-multiselect-label-container
          > .p-multiselect-label
          > .p-multiselect-token
      ) {
      background-color: #e6e6e6;
      color: #000001 !important;
      border-radius: 3px;
    }
  }
}

.equipment-radio-type-other-container {
  margin-top: 29px;

  :global(.form-field) {
    margin-bottom: 0px !important;
  }
}

.modulation-type-options-container {
  margin-left: 20px;
}

.modulation-type-field {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 18px;

  .checkbox-container {
    margin-bottom: 0px !important;
  }

  .other-modulation-type {
    width: 40%;
  }
}

.modulation-options-help-text {
  font-size: 0.9rem;
  color: #5f5f5f;
  margin-bottom: 40px;
  display: block;
  text-align: left;
}

.radio-technology-field {
  margin-bottom: 25px;

  & > h5 {
    font-size: 1rem;
    margin-bottom: 18px;
  }
}

.selected-radio-technology-chip {
  border: 1px solid #01518a;
  color: #01518a;
  padding: 10px 20px;
}

.container {
  :global(.p-multiselect-label) {
    & > *:not(:last-child)::after {
      content: ", ";
    }
  }
}
