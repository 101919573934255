
* {
  font-family: "Open Sans", "Segoe UI", "Helvetica Neue";
}

html {
  font-size: 100%;
}

body {
  overflow: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
