.projectDetailsFlexList__container {
  .list-counter {
    padding: 0 1.5rem 0.75rem;
    font-size: 0.75rem;
    font-weight: 700;
    display: block;
  }

  .list-container {
    overflow-y: auto;
    border-top: 1px solid #cbcfd1;
    height: fit-content !important;
    max-height: 22.75rem;
  }
}
