.import-result-container{
    display: flex;
    flex:1;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.link-btn {
    font-size: 14px;
    margin-left: 0.5rem;
    color: blue;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: rgb(21, 21, 79);
    }
  }