.nav-tab__container {
    margin-top: 10px;
    flex-grow: 1;

    :global(.p-card .p-card-body) {
        padding: 0;
    }

    :global(.p-card .p-card-content) {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0.5rem;
    }

    .nav-tab__card {
        width: 100vw;
        height: 100%;
        :global(.p-toolbar) {
            padding-top: 0px !important;
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 0px;
            background: #ebebea;
            flex-wrap: nowrap;
            border-bottom: none;
        }
        :global(.p-card-body) {
            height: 100%;
        }
        :global(.p-card-content) {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .nav-tab__toolbar {
        :global(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link) {
            border-top-width: 2px;
            border-right-width: 0px;
            border-bottom-width: 0px;
            border-left-width: 0px;
            border-top-width: 2px;
            background: #ebebea;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
        }
        :global(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link) {
            background: #fff;
            border-color: #c02032;
            color: #c02032;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
        }

        :global(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link) {
            background: #ebebea;
            border-color: transparent;
        }
        :global(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus) {
            box-shadow: none;
        }
    }

    .nav-tab__toolbar--right {
        display: flex;
        flex-direction: row;
        justify-items: center;
        flex-wrap: nowrap;

        &-items {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            &:last-child {
                margin-left: 1.25rem;
            }
            &-collapse-icon {
                cursor: pointer;
                margin-right: 1.25rem;
                margin-left: 1.25rem;
            }
        }
    }

    .nav-tab__content {
        margin-top: 1.25rem;

        :global(.p-datatable-wrapper) {
            height: calc(100vh - 280px);
            overflow: auto;
        }

        .collapsed__container {
            width: 120px;
            height: 150px;
            word-wrap: break-word;
            &-text {
                white-space: pre-wrap;
                line-height: 1.5rem;
            }
        }
    }
}
