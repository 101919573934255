.btn-keep {
    background-color: #01518A !important;
}

.btn-adjust {
    background-color: #0088CC !important;
}

.btn-cancel {
    background-color: white !important;
    border-color: #888888 !important;
    color: #888888 !important;
}

.adjustments-table {
    margin: 1.5rem 0rem;
}

.action-note {
    font-style: italic;
    color: #000;
    margin-left: .25rem;
}

.modal-body {
    margin: 0px !important;
}

.keep-note {
    margin-bottom: .75rem;

    label {
        font-weight: bold;
        color: #01518A
    }
}

.adjust-note {
    label {
        font-weight: bold;
        color: #0088CC
    }
}

.selected-lines-info {
    font-weight: bold;
    margin-top: 0.75rem;
    margin-bottom: -1rem;
}