.button-container {
  margin: 30px 0 0 20px;
  display: flex;
  width: 25%;
}

.export-dropdown {
  right: 0%;
}

.apply {
  margin-right: 5px;
  line-height: 0px;
  height: 34px;
}

.export {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="white" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>')
    center right no-repeat;
  line-height: 0px;
  height: 34px;
}

.generate-container {
  position: relative;
}

.generate {
  margin: 20px auto;
  display: block;
}

.label {
  margin-left: 8px !important;
}

:global(.p-checkbox.p-component .p-checkbox-box.p-highlight) {
  border-color: #01518a !important;
  background: #01518a !important;
}
