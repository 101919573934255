.dynamic-field-date-div {
  input {
    width: 100%;
    height: 2.5rem;
  }
}

.dynamic-field-multiselect {
  width: 100%;
}

.p-multiselect-panel {
  z-index: 9999 !important;
}

.dynamic-field-dropdown-panel {
  max-width: 790px;

  &,
  ul,
  li {
    width: fit-content !important;
  }

  ul,
  li {
    min-width: 100%;
  }
}
