.tree-select__container{

    width:100%;

    &-control{
        width:100%;
    }

    :global(.p-treeselect-label-empty){
        overflow: visible;
        visibility: visible;
    }

    :global(.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label){
        padding:0.75rem 0.75rem;
    }

    .tree-selected__chips{
  
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        gap: 10px;
   
        &-item{
            padding:0.75rem;
            padding-top:0;
            padding-bottom:0;
        }
    }

    .tree-select__others{
        margin-top:1rem;
    }

}

.tree-select--single{
    :global(.p-checkbox .p-checkbox-box){
        border-radius: 0.75rem;
    }
}

.tree-select__buttons{
    :global(.p-button.p-button-rounded) {
        border-radius: 6px;
    }
    &--expand{
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-right: 0px;
        padding-left: 0px;
        width: 2rem;
        height: 2rem;
        margin-right:0.5rem;
        
        :global(.p-button-label){
            font-size: 1.75rem;
            font-weight: 400;
        }
    }

    &--expand-minus{
        
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-right: 0px;
        padding-left: 0px;
        width: 2rem;
        height: 2rem;
        margin-right:0.5rem;
        
        :global(.p-button-label){
            font-size: 1.75rem;
            font-weight: 400;
            margin-bottom:5px;
        }

    }

}