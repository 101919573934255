.custom-badge {
  display: inline-block;
  border-radius: 10px;
  padding: 0.05rem 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.3px;
  text-align: center;
  min-width: 200px;
  max-width: 200px;

  &.inprogress {
    background-color: #0a99ff;
    color: #ffffff;
  }

  &.notscheduled {
    background-color: #595958;
    color: #ffffff;
  }

  &.completed {
    background-color: #146615;
    color: #ffffff;
  }

  &.onhold {
    background-color: #ffa700;
    color: #8a5340;
  }

  &.cancelled {
    background-color: #cccccd;
    color: #444444;
  }

  &.notstarted {
    background-color: #80a8c4;
    color: #ffffff;
  }

  &.awaitingassignment {
    background-color: #096072;
    color: #ffffff;
  }
}

.custom-dropdown {
  min-width: 160px !important;
  max-width: 160px !important;

  :global(.p-dropdown-label) {
    padding-top: 3px;
    padding-bottom: 2px;
    margin: 0;
  }
}

.cancel-modal {
  border-radius: 0px;

  .footer {
    align-content: center;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    gap: 0.75rem;

    .button-cancel {
      height: 2.5rem;
      width: 7rem;
      background: transparent;
      border-color: #b8b8b8;
      outline-color: #b8b8b8;
      color: #b8b8b8;
      margin: 0;

      :hover {
        background: transparent;
        color: #9e9e9e;
        border-color: #9e9e9e;
        outline-color: #9e9e9e;
      }
    }

    .button-save {
      height: 2.5rem;
      width: 7rem;
      margin: 0;
      background: #0088cc;
      border: 1px solid #a9dff7;

      :hover {
        background: #016a9e;
      }
    }
  }
}