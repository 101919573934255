.project-mangement-container {
  width: 100vw;
  .project-management-refiners-container {
    max-width: 30vw !important;
    width: 30vw !important;
    padding: 0;
  }
  .project-management-main {
    width: 80vw !important;
    max-width: 80vw !important;

  }

  .project-management-main-full {
    width: 100vw !important;
    max-width: 100vw !important;

  }
} 
.enabler {
  button {
    border: 0;
    background-color: #4D4D4D;
    color: #E6E6E6;
    border: 0;
    background-color: #B3B3B3;
    color: #4D4D4D;
    font-size: 24px;
    padding: .2em;
    padding: .2em;

    &.hide {
      background-color: #B3B3B3;
      color: #4D4D4D;

      &:hover {
        background-color: #4D4D4D;
        color: #E6E6E6;
      }
    }


    &:hover {
      background-color: #4D4D4D;
      color: #E6E6E6;
    }

  }
}