.new-cert-prod-dtls-overrides {
  .p-tabview.p-component {
    .p-tabview-nav-container {
      background-color: #ebebea !important;

      .p-tabview-nav-content {
        .p-tabview-nav {
          background-color: #ebebea !important;

          li {
            a {
              background-color: #ebebea !important;
              color: gray !important;

              &:focus {
                box-shadow: inset 0 0 0 0.15rem transparent !important;
              }
            }
          }

          li {
            &.p-tabview-selected.p-highlight {
              a {
                color: black !important;
              }
            }
          }
        }

        .p-tabview-ink-bar {
          background-color: black !important;
        }
      }
    }
  }
}

.new-cert-prod-dtls-col-override {
  div.p-datatable {
    div.p-datatable-wrapper {
      max-height: 25rem;
      overflow: scroll;
      border: 1px solid rgb(213, 210, 210) !important;
    }
  }
}

.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover td {
  background: #e9ecef;
  color: #495057;
}

.add-attachment-bulk-body-overrides {
  .p-tabview.p-component {
    .p-tabview-nav-container {
      background-color: #ebebea !important;

      .p-tabview-nav-content {
        .p-tabview-nav {
          background-color: #ebebea !important;

          li {
            a {
              background-color: #ebebea !important;
              color: gray !important;
              padding: 0.5rem 1rem;

              &:focus {
                box-shadow: inset 0 0 0 0.15rem transparent !important;
              }
            }
          }

          li {
            &.p-tabview-selected.p-highlight {
              a {
                color: black !important;
              }
            }
          }
        }

        .p-tabview-ink-bar {
          background-color: black !important;
        }
      }
    }

    .p-tabview-panels {
      padding: 0;
    }
  }

  .p-datatable-wrapper {
    height: 12rem;
    overflow-y: scroll;
    border: 1px solid rgb(213, 210, 210) !important;

    .p-datatable-emptymessage>td {
      border: none;
    }
  }
}