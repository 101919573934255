.date-picker-row{
    margin-top:0.5rem;
}

.react-datepicker-popper{
    z-index: 99999 !important;
}

.d-refiner-height {
    height: 40px !important;
  }
  
.d-container-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    height: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
}