$border: solid #bfbfbf 1px;

.number-of-items {
    margin-bottom: 10px;

    .label {
        margin-bottom: 10px;
    }

    .input {
        max-width: 120px;
    }
}

.items-container {
    margin-top: 10px;
    border-top: $border;

    .item-container {
        border: $border;
        border-top: none;

        &.no-content {
            .collapse-icon {
                visibility: hidden;
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-bottom: solid #bfbfbf 1px;
            min-height: 39px;

            .left {
                margin-left: 30px;
                flex-grow: 1;
            }

            .left-15 {
                margin-left: 15px;
            }

            .right {
                gap: 15px;
            }

            .title {
                margin-bottom: 0px;
                margin-top: 0px;
                flex-grow: 1;
                display: flex;
                align-items: center;
            }

            .collapse-icon {
                &.fa-angle-right {
                    margin-right: 15px;
                }

                &.fa-angle-down {
                    margin-right: 11px;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .info-checkbox {
                margin-right: 10px;
            }

            &>div {
                display: flex;
                align-items: center;
            }

            .custom-options-container {
                margin-bottom: 5px;
                margin-top: 5px;
                gap: 10px;
                justify-content: flex-end;
                display: flex;
                flex-wrap: wrap;
            }

            .delete-item {
                padding: 10px 14px 10px 14px;
                border-left: solid #bfbfbf 1px;

                .trash-icon {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .content {
            margin: 30px 60px;

            &.hidden {
                width: 0px;
                height: 0px;
                margin: 0px;
                overflow: hidden;
            }
        }
    }
}

.btn-collapse-all-container {
    text-align: right;

    .btn-collapse-all {
        padding: 8px 12px !important;

        &:hover {
            opacity: 1 !important;
            color: #000000 !important;
        }
    }
}

.delete-confirmation-dialog {
    :global(.p-dialog-header) {
        border-bottom: solid 1.7px #999999;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    :global(.p-dialog-content) {
        padding-top: 60px;
        padding-bottom: 60px;
        border-bottom: solid 1.7px #999999;

        :global(.p-confirm-dialog-icon) {
            display: none;
        }
    }

    :global(.p-dialog-footer) {
        padding-top: 20px;
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;

        :global(.p-button) {
            width: 100px;
        }

        :global(.p-confirm-dialog-accept) {
            background-color: #0088cc !important;
        }

        :global(.p-confirm-dialog-reject) {
            background-color: #707070 !important;
            color: white !important;
        }
    }
}