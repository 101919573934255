@mixin reset-header-footer {
  border: {
    top-right-radius: 3px !important;
    top-left-radius: 3px !important;
  }
  min-width: 250px;
  padding: 8px 15px !important;
}

@mixin horizontal-line {
  content: "";
  display: block;
  border: 1px solid #c5c3c3;
}

.ul-modal-wrapper {
  :global(.p-dialog-header) {
    @include reset-header-footer();
    border-bottom: 1px solid #ccc !important;
  }

  :global(.p-dialog-footer) {
    @include reset-header-footer();
    border-top: 1px solid #ccc !important;
  }
  :global(.p-dialog-content) {
    padding-top: 1.5rem;
  }
}

.prime-modal-wrapper {
  :global(.p-dialog-content) {
    padding-bottom: 0;
    &::after {
      @include horizontal-line();
      margin: 15px 0;
    }

    &::before {
      @include horizontal-line();
      margin-bottom: 15px;
    }
  }
}
