.container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.action {
  display: flex;
  font-size: 14px;
  color: #000000;
  align-items: center;
  gap: 0.3rem;
  &.disable {
    cursor: default !important;
    opacity: 0.5;
  }
}

.active {
  cursor: pointer !important;

  &:hover {
    background-color: #eef2ff;
  }
}

.disabled {
  cursor: default !important;
}

.icon {
  font-size: 24px;
  color: #000000;
}

.line {
  border-bottom: none !important;
  border: 1px solid #d3d3d3;
  opacity: 0.4;
  margin-left: -9px;
  margin-right: -9px;
}
