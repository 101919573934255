.container {
    p {
        font-size: 18px;
    }

    :global(.p-dialog-header) {
        padding: 0rem !important;
        padding-left: 1.5rem !important;
    }

    :global(.p-dialog-header-icons) {
        padding: 1rem;
    }
}