.badge-container {
  position: relative;
}
.badge {
  font-size: 1rem !important;
  min-width: 2rem !important;
  height: 2rem !important;
  line-height: 2rem !important;

  font-weight: 400 !important;
  border-radius: 1rem !important;
  margin-right: -0.2rem;
  box-shadow: 0rem 0rem 0rem 0.1rem white;

  &:first-of-type {
    margin-left: 0.2rem;
  }
  &:last-of-type {
    margin-right: 0.2rem;
  }
}
// .ordercreator {
//   background-color: #122c49 !important;
//   color: #fff !important;
// }
.flexhandler {
  background-color: #122c49 !important;
  color: #fff !important;
}
.gmawbhandler {
  background-color: #577e9e !important;
  color: #fff !important;
}
.taskhandler {
  background-color: #bce4f7 !important;
  color: #000 !important;
}
.milestonehandler {
  background-color: #e5ddca !important;
  color: #000 !important;
}

.extrahandler {
  background: #e6e6e6;
  font-size: 0.75rem !important;
  max-width: 0.25rem !important;
  width: 0.25rem !important;
  height: 1.5rem !important;
  line-height: 1.5rem !important;

  font-weight: 800 !important;
  border-radius: 1rem !important;
  margin-right: -0.2rem;
  color: #030303;
  box-shadow: 0rem 0rem 0rem 0.05rem white;
}

.tooltip {
  :first-child {
    border-right-color: white !important;
  }

  :last-child {
    background: white;
    color: #222222;
  }

  .tooltip-displayName {
    font-weight: bolder;
    padding-bottom: 0.5rem;
  }

  .tooltip-handlerLabel {
    font-style: italic;
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
}

.overlay-panel-container {
  padding-top: 0.5rem;
  & > div {
    padding: 1rem !important;
  }

  & > button {
    background: transparent !important;
    color: #030303 !important;
    width: 0.75rem !important;
    height: 0.75rem !important;
    top: 0.5rem !important;
    right: 1rem !important;

    &:hover {
      background: transparent !important;
      color: #030303;
    }

    & > span {
      font-size: 0.75rem;
    }
  }

  .overlay-panel-extra-handlers {
    padding-top: 0.5rem;
    max-height: 10rem;
    height: auto;
    overflow-y: auto;
  }
}

.blank-collaborator-icon {
  color: #003357;
}
