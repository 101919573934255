.container {
  display: flex;
  width: 100%;
  margin-left: 21.5%;

  .step-dots {
    display: flex;
    align-items: center;
  }

  .main-actions {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 72%;
    justify-content: space-between;
  }

  .other-actions {
    text-align: left;
    margin: 0 3rem;
    display: flex;
    align-items: center;
    flex-direction: row;

    button {
      margin-left: auto;

      &:disabled:hover {
        background-color: #fffffe !important;
        color: #000003 !important;
        border-color: #000003 !important;
      }
    }

    .btn-save-close {
      margin-left: 15px;
      background: #dee2e6 !important;
      color: #000003 !important;
      border-color: transparent;

      &:hover {
        background-color: #d9dce0 !important;
      }

      &:disabled:hover {
        background-color: #dee2e6 !important;
        color: #000003 !important;
        border-color: transparent !important;
      }
    }
  }

  .hidden {
    visibility: hidden;
  }
}
