.no-standard-toast {
  width: 90%;
  top: 30%;
  position: absolute;
  z-index: 99999 !important;

  .no-standard-toast-message {
    padding: 1rem;
    background-color: red;
    color: #fff;

    .no-standard-toast-icon-close {
      float: right;
      color: #fff;
      cursor: pointer;
      position: relative;
      right: 0;
    }
  }
}

.psn-confirmation-container {
  h5 {
    margin: 0;
  }

  .footer-container {
    display: flex;
    justify-content: center;
    padding: 20px 0px 0px 0px !important;
  }

  .modal-header {
    margin-top: 5px;
    margin-left: 30px;
  }

  hr {
    border-color: #cfcfcf;
    border-bottom: 0px;
  }

  :global(.p-dialog-header) {
    padding: 10px 0px 10px 0px !important;
    border-bottom: 2px solid #cfcfcf;
  }

  :global(.p-dialog-footer) {
    padding: 0px 0px 25px 0px !important;
    border-top: 2px solid #cfcfcf;
  }

  .footer-content {
    margin-top: 18px;
  }

  .body-content {
    line-height: 22px;
    padding-right: 8px;
    padding-left: 8px;
    margin-bottom: 0px;
  }

  .btn-no {
    background-color: #888888 !important;
    color: #fff !important;
  }

  :global(.p-dialog-content) {
    padding-bottom: 1.5rem;
  }

  :global(.p-dialog-header-icons) {
    margin-right: 20px;
  }
}

.btn-view-standard-details {
  all: unset;
  margin-left: 1rem;
  color: #08c;
  text-decoration: underline;
}

.view-edit-standard-details-note {
  font-style: italic;
  font-size: 0.85rem;
  margin-top: 0.25rem;
  margin-bottom: -1.5rem;
  color: #838383;
}

.certDetails-error-input {
  :global(.p-inputtext:hover) {
    border-color: red !important;
  }
  :global(.p-inputtext:focus) {
    border-color: red !important;
    box-shadow: 0 0 0 0.1rem red !important;
  }
  input {
    border-color: red !important;

    &:focus-visible {
      outline-color: red !important;
    }
  }
}

.certDetails-error-multiselect > div > div:last-child {
  border: 1px solid red !important;
  &:focus {
    border-color: red !important;
    box-shadow: 0 0 0 0.1rem red !important;
  }

  &:focus-visible :hover {
    outline-color: red !important;
  }
}

.certDetails-error-standard {
  border-color: red !important;
  &:focus-visible :hover {
    outline-color: red !important;
  }
}

.certDetails-error-brand {
  border-color: red !important;

  &:enabled:hover {
    border-color: red !important;
  }
  &:enabled:focus {
    border-color: red !important;
    box-shadow: 0 0 0 0.1rem red !important;
  }

  &:focus-visible {
    outline-color: red !important;
  }
}
