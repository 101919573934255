.favorites {
    background: none;
    min-width: 44px;
    height: 35px;
    padding: 0;
    border-radius: 2px;
}

.favorites:hover {
    background: #e2e6ea;
    border-color: #dae0e5;
}

.sub-title{
    font-size: 16px;
    font-weight: bold;
}

.save-button{
    margin-left: 0.5rem;
    line-height: .75;
}

.def-and-clear{
    margin: 5px 0 0 0;
}

.set-as-default, .clear-filters {
    display: inline-block;
    width: 50%;
}

.clear-filters {
    text-align: right;
    color: #25c3ff;
    cursor: pointer;
    padding: 0 11px 0 0;
}

.clear-filters:hover {
    color: #00def5;
}

.fav-header {
    border-bottom: solid 0.5px #ebebeb;
    padding-bottom: 10px;

    input[type=text] {
        line-height: 25px;
        margin: 0 5px 0 0;
        width: 81%;
    }

    input[type=button] {
        height: 32px;
        font-size: 12px !important;
    }
}

.elipsis {
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fav-body {
    padding-top: 10px;

    .list {
        margin: 10px 0 0 0;


        .item:hover{
            background-color:#f2f2f2;
        }

        .item {
            //display: inline-block;
            width: 100%;
            margin: 0 0 2px 0;
            cursor:pointer;

            .fav, .fav-action {
                display: inline-block;

                .action {
                    width: 40px;
                    text-align: center;
                }

                .action:hover {
                    background-color: #f2f2f2;
                    border-radius: 5px;
                }
            }

            .fav-star {
                color: #61bfa2;
            }

            .fav-action {
                width: 79px;
                text-align: right;
            }

            .fav {
                min-width: 170px;
            }
        }
    }
}

.edit-checkbox{
    margin-top: 10px;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 10px;
}

.edit-label{
    text-align: center;
    font-size: 18px;
}