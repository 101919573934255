.template-header__container {
  padding-bottom: 10px;

  .template-header__row {
    .text-label {
      padding-bottom: 5px;
    }

    .text-box__container {
      .text-box {
        width: 95%;
        padding: 0.5rem 0.7rem !important;
      }
    }

    .required-field {
      color: red;
    }

    .template-details__container {
      padding-bottom: 10px;

      .template-details__text {
        padding-bottom: 10px;
      }
    }

    .text-box--error {
      &:focus {
        border-color: #e24c4c !important;
        box-shadow: 0 0 0 0.1rem #e24c4c !important;
      }
    }

    .dropdown-flex__column {
      align-self: normal;

      .dropdown-flex__container {
        .dropdown-flex {
          width: 100%;

          :global(.p-dropdown-label),
          :global(.p-multiselect-label) {
            padding: 0.5rem 0.7rem;
          }
        }
      }
    }

    .template-icon__container {
      align-self: center;

      .template-icon-alignment {
        gap: 20px;
        align-items: center;
        justify-content: left;
        padding-right: 50px;

        .icon {
          cursor: pointer;
          height: 18px;
          width: 18px;
          color: #4d4d4d;
          &--disabled {
            opacity: 0.6;
          }
        }
      }
    }
    .template-icon--without-error {
      padding-top: 20px;
    }
  }
}
