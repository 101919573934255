.time-entry-dialog {
  :global(.p-dialog-header) {
    color: #222222;
  }
  :global(.p-dialog-content) {
    color: #222222;
    padding-bottom: 0;
    overflow-y: auto !important;
  }
  :global(.p-dialog-footer) {
    color: #222222;
  }
  :global(.p-button) {
    padding: 0;
    color: #222222 !important;
  }
}
.text-bold {
  font-weight: bold;
}
.text-light-blue {
  color: #0a99ff;
}
.text-brown {
  color: #d38d29;
}
.text-red {
  color: #c02032;
}
.popover-icon {
  border: none;
  background: #fff;
  padding: 0;
}
.header-container {
  display: flex;
  margin-top: -1rem;
  gap: 1rem;
  .header-label {
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
  .header-label-assignhours {
    display: flex;
    .left {
      width: 50%;
    }
    .right {
      width: 50%;
      justify-content: end;
      color: #0a99ff;
      font-style: italic;
    }
  }

  .assign-hours-container {
    .dropdown-assign-hours {
      :global(.p-multiselect-label-container) {
        width: 20rem;
      }
      :global(.p-multiselect-label) {
        height: auto !important;
        padding: 0.5rem 0.75rem;
      }
    }
  }
  .select-week-container {
    .select-week-btn-left {
      margin-right: 5px;
      height: 36px;
      background-color: transparent;
      border: 1px solid darkgrey;
      color: #595959 !important;
    }
    .select-range {
      height: 35px;
      width: 215px;
    }
    .dropdown-week-range {
      :global(.p-inputtext) {
        padding: 0.5em 0.75em;
      }
      :global(.p-dropdown-trigger) {
        display: none;
      }
    }
    .select-week-btn-right {
      margin-left: 5px;
      height: 36px;
      background-color: transparent;
      border: 1px solid darkgrey;
      color: #595959 !important;
    }
  }
}

.confirmation-checkbox {
  margin-top: 20px;
  margin-bottom: 20px;
}
.dialog-footer {
  display: flex;
  &.center-items {
    justify-content: center;
  }
  &.right-items {
    justify-content: end;
  }
  .dialog-cancel-btn {
    background-color: #ffffff;
    border-color: #999999;
    color: #999999;
    min-width: 7rem;
    border-radius: 5px;
  }
  .dialog-save-btn {
    background-color: #0088cc;
    color: #ffffff;
    min-width: 7rem;
    border-radius: 5px;
  }
}
.timeentry-container {
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  .time-entry-table {
    padding-top: 10px;
  }
  .distribute-switch-container {
    display: flex;
    align-items: center;
    .distribute-switch-label {
      font-weight: 600;
      margin-right: 0.5rem;
    }
    .distribute-switch {
      &.is-on {
        & > :global(.p-inputswitch-slider) {
          background-color: #2196f3 !important;
        }
      }
    }
  }

  .btn-check {
    color: green;
    font-size: 1.2em;
    cursor: pointer;
    margin-right: 10px;
  }

  .btn-times {
    color: red;
    font-size: 1.2em;
    cursor: pointer;
  }
}
.input-number {
  :global(.p-inputnumber-input) {
    width: 3rem;
    padding: 0.25rem;
    text-align: center;
    color: #222222;
  }
  &.input-error {
    :global(.p-inputnumber-input) {
      color: #c02032;
      border-color: #c02032;
    }
  }
}
.loading-div {
  height: 30vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.datatable-container {
  .column-header-padding-left {
    padding-left: 0.5rem;
  }
  & > :global(.p-datatable > .p-datatable-wrapper) {
    height: 45vh;
    overflow-y: auto;
  }
  & > :global(.p-datatable) {
    border: solid 1px #cbcfd1;
  }
  & > :global(.p-datatable .p-datatable-thead > tr > th) {
    background: #fcfcfc !important;
    color: #222222;
    font-weight: 600;
    padding: 0;
  }
  &
    > :global(
      .p-datatable .p-datatable-thead > tr > th > .p-column-header-content
    ) {
    width: 100%;
  }
  &
    > :global(
      .p-datatable
        .p-datatable-thead
        > tr
        > th
        > .p-column-header-content
        > .p-column-title
    ) {
    width: 100%;
  }
  .day-column-header-container {
    .day-column-header-top {
      background-color: #f5f5f5;
      text-align: center;
      border-bottom: 1px solid #cbcfd1;
      padding: 0.5rem 0;
    }
    .day-column-header-bottom {
      text-align: center;
      padding: 0.5rem 0;
    }
  }
  .day-column-body {
    text-align: center;
    width: 100%;
  }
  .task-name {
    margin-left: 0.2rem;
  }
}
