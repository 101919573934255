.table-container {
  width: 100%;
  .project-list-grid-container {
    padding: 0;
    :global(.p-datatable) {
      border: solid 1px #cbcfd1;
      border-bottom: 0;
    }
    :global(.p-datatable-table) {
      min-width: 100%;
    }
    & > :global(.p-datatable > .p-datatable-wrapper) {
      height: calc(100vh - 160px);
      overflow-y: auto;
    }
    :global(.p-datatable-loading-overlay) {
      z-index: 8;
    }

    :global(.p-datatable-thead) {
      z-index: 7;
    }
    &
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > table
          > tbody
          > .p-datatable-emptymessage
      ) {
      font-style: italic;
      font-weight: normal;
      position: absolute;
      top: 50%;

      td {
        border: none;
        background-color: #ffffff !important;
        border: 0 !important;
        justify-content: center;
      }
    }
    &
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > .p-datatable-table
          > .p-datatable-thead
          > tr
      ) {
      & > th {
        background-color: #f5f7f7 !important;
        color: #222222;
        border: 1px solid #cbcfd1;
        border-width: 0 0 1px 0;
        & :global(.p-column-header-content) {
          display: flex !important;
          border-right: 2px solid #ccc;
          padding-right: 0.5rem;
        }

        &:nth-last-child(2) :global(.p-column-header-content) {
          border-right: none;
        }
      }
    }

    :global(th .p-column-header-content .p-column-title) {
      width: 100%;
    }
    :global(th.p-sortable-column .p-column-header-content .p-column-title) {
      width: calc(100% - 28px);
    }
    :global(.p-datatable .p-sortable-column .p-sortable-column-icon) {
      margin-left: 0;
    }
    :global(.p-datatable .p-datatable-tbody > tr > td .p-row-toggler) {
      height: 1rem;
      width: 1rem;
      color: #222222;
    }
    &
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > .p-datatable-table
          > .p-datatable-tbody
          > tr
      ) {
      & > td {
        background: #f5f7f7;
        color: #222222;
        font-weight: 600;
        border: 1px solid #cbcfd1;
        border-width: 0 0 1px 0;
        width: 100%;
        min-height: 45px;
      }
    }
    :global(
        .p-datatable .p-datatable-tbody > tr.p-datatable-row-expansion > td
      ) {
      background: #ffffff;
      width: 100%;
      padding: 0;
    }

    &.on-last-page
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > .p-datatable-table
          > .p-datatable-tbody
      )::after {
      content: "- You have reached the end of the list -";
      font-style: italic;
      text-align: center;
      width: 100%;
      display: block;
      padding: 0.25rem;
      color: #222222;
      font-weight: 600;
    }
    .header-expand-collapse-icon {
      transform: rotate(-45deg);
      cursor: pointer;
    }
  }
  .text-align-center {
    text-align: center;
  }

  .pagination-container {
    width: 100%;
    :global(.ul-pagination) {
      border: 1px solid #cccccc;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  .project-row-expander-div {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    padding-right: 0.5rem;
    gap: 0.3rem;
    .project-row-expander {
      cursor: pointer;
      margin: 0px 0.3rem;
    }
    .project-arrows-rotate {
      cursor: pointer;
      color: #ffa700;
    }
  }
  .project-checkbox-div {
    padding-right: 0.5rem;
  }
  .project-checkbox {
    cursor: pointer;
    width: 22px !important;
  }

  .project-opennewtab {
    cursor: pointer;
  }
  .icon-action-column {
    cursor: pointer;
  }

  .actions-item {
    display: flex;
    gap: 5px;

    button {
      background-color: transparent;
      border: none;
      padding: 0;
    }

    .button-disabled {
      cursor: default !important;
    }
  }
}
