.show-hide-button {
    margin: 0 !important;
}

.show-hide-button {
    background: none;
    width: 44px;
    height: 35px;
    padding: 0;
    border-radius: 2px;
}

.show-hide-button:hover {
    background: #e2e6ea;
    border-color: #dae0e5;
}

.table-header {
    background-color: #fff !important;
    justify-content: center !important;
}

.checkbox {
    height: 16px;
    width: 16px;
    accent-color: blue;
}

.rowfrozen {
    background-color: #e4e6e6 !important;
}

.title-caption {
    border-bottom: solid 0.5px #d8d8d8;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}


.show-hide-multiple-overlay-panel {
    max-height: 40rem;
    height:40rem;
    max-width: 25rem;
    width:25rem;
    overflow: hidden;
    :global(button.p-overlaypanel-close.p-link) {
        top: 1rem;
        right: 1rem;
        background-color: transparent;
        color: black;

        &:enabled:hover {
            background-color: transparent;
            color: black;
        }
    }
}

.detached-column-header {


    > div > table > tbody {
        display: none;
    }

    .drag-body {
        background-color: #cedbe9 !important;
        border-right: 0 !important;
    }
    
    .checkbox-body {
        background-color: #cedbe9 !important;
        border-left: 0 !important;
        accent-color: blue;

        > div {
            justify-content: center;
        }
    }

    .other-headers {
        padding: 0.5rem 0.5rem !important;
    }
}


.tabview-container {
    :global(p.tabview-nav) {
        justify-content: space-around;
    }

    :global(.p-tabview-ink-bar) { 
        background-color: #346c91 !important;
    }
    .tabview-custom-active > a{
        color: #346c91 !important; 
        border-color: #346c91 !important;

        &:focus{
            box-shadow: none !important;
        }
    }

    > div:nth-child(2) {
        padding: 0px;
        padding-top:1rem;
        padding-bottom: 2rem;
    }

    .tabview-item {
        overflow:auto;
        max-height: 28rem;
        height:28rem;
    }
}