.your-filters-header{
    padding-top:0.05rem;
    padding-left:0.9rem;
    padding-right:0.9rem;
  }
  
  .your-filters-body{
    padding-top:0.5rem;
    padding-left:2rem;
    padding-right:0.9rem;
  }
  
  