$table-border-color: #cbcfd1;
.custom-modal {
  table.custom-table {
    border: 1px solid $table-border-color;
    thead > tr > th {
      border: 1px solid $table-border-color !important;
      padding-right: 0 !important;
      border-width: 0 0 1px 0 !important;
      :global(.p-column-header-content) {
        border-right: 2px solid #ccc;
        padding: 5px 0;
      }
    }

    tbody > tr > td {
      border-right: 1px solid #ccc !important;
      &:last-child {
        border-right-width: 0 !important;
      }
    }
  }

  .loader {
    div {
      width: 65px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 100%;
    min-height: 250px;
  }
}
