.d-flex {
  display: flex;
  gap: 1rem;
  align-items: start;
  &.d-flex-row {
    flex-direction: row;
  }
  &.d-flex-column {
    flex-direction: column;
  }
  &.d-flex-align-items-center {
    align-items: center;
  }

  :global(input) {
    width: 350px;
  }

  :global(.p-button):not(:global(.p-button-secondary)) {
    background-color: #0088cc;
  }
}

.custom-tooltip {
  &:not(.active) {
    display: none !important;
  }
  :global(.p-tooltip-text) {
    background-color: white !important;
    color: #444;
    font-size: 0.75rem;
    padding: 0.5rem;
    border-radius: 3px;
  }
  :global(.p-tooltip-arrow) {
    display: none;
  }
}
