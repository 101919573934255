.body {
  padding-bottom: 1rem;

  .text {
    margin-bottom: 1.5rem;
  }
  .dropdown {
    margin-bottom: 1.25rem;
  }

  :global(.p-dropdown-trigger):has(.drop-down-icon) {
    width: 1.5rem !important;
    .drop-down-icon {
      color: #000000;
      &::after {
        content: "arrow_drop_down";
      }
    }
  }
}

.loading-div {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.btn-confirm {
  background-color: #0088cc !important;
}

.btn-cancel {
  background-color: #ffffff !important;
  color: #8e8e8e !important;
  border-color: #8e8e8e !important;
}

.modal-body{
  margin: 1rem !important;
}

.generate-status-modal {
  :global(.p-dialog-content) {
    overflow-y: auto !important;
  }
}