.grid-action-popover-item {
    color: black;
    padding: .5em;
}

.active {
    cursor: pointer !important;

    &:hover {
        background-color: #EEF2FF;
    }
}

.disabled {
    cursor: default !important;
    opacity: 0.4;
}

.grid-action-popover {
    min-width: 167px !important;
}