.certificate-details-layout {
  padding: 0;
  top: 0;
  right: 0;
  width: 470px !important;
  z-index: 2;
  overflow: auto;
  font-size: 14px !important;

  .p-sidebar-header {
    padding: 0 !important;
  }

  .p-sidebar-content {
    padding: 0 !important;
  }

  .loading-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
