.add-button {
    margin-left: 1em;
    width: 44px;
    height: 44px;
}

.container {
    margin: 0 6%;
}

.scheme-error__icon {
    color: red;
    margin-left: .5rem;
}

.pick-list-container {
    margin-top: 2rem;
    margin-bottom: 5rem;
    width: 65vw;
}

.add-scheme-container {
    width: 100%;

    .body {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;
        gap: 4rem;

        &>div {
            flex-basis: 0;
            flex-grow: 1;
        }

        .add-scheme {
            display: flex;
            align-items: center;
        }
    }
}

.scheme-name-container {
    gap: 2rem;

    &.invalid-scheme {
        color: #EA2110;
    }

    .scheme-name {
        flex-shrink: 0;
    }
}

.error-message {
    color: red;
    display: flex;
    gap: .35rem;
    font-weight: normal;
    font-size: .9rem;
    line-height: 1.25rem;
    margin-top: .6rem;
    margin-bottom: 1rem;

    svg {
        margin-top: .2rem;
    }
}

.delete-confirmation-dialog {
    :global(.p-dialog-header) {
        border-bottom: solid 1.7px #999999;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    :global(.p-dialog-content) {
        padding-top: 60px;
        padding-bottom: 60px;
        border-bottom: solid 1.7px #999999;

        :global(.p-confirm-dialog-icon) {
            display: none;
        }
    }

    :global(.p-dialog-footer) {
        padding-top: 20px;
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;

        :global(.p-button) {
            width: 100px;
        }

        :global(.p-confirm-dialog-accept) {
            background-color: #0088cc !important;
        }

        :global(.p-confirm-dialog-reject) {
            background-color: #707070 !important;
            color: white !important;
        }
    }
}

.scheme-list {
    flex-grow: 1;
}

.header-items {
    display: flex;
    flex: 1;
    flex-direction: row;

    .table {
        display: flex;
        flex: .5;
        justify-content: center;


        max-height: 30vh;
        overflow: auto;

        .list {

            display: flex;
            flex: 1;
            flex-direction: column;

            .list-item {
                margin-bottom: .25em;
                max-width: 95%;

                .text {


                    align-items: center;
                }

                &:hover {
                    background-color: rgb(244, 244, 244);
                }

            }

        }
    }
}