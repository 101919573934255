
.project-header-container{
    width: 100%;
    padding-top: 15px;
    display: flex;
    .left-section{
        padding-left:1em;
        width: 70%;
        display: flex;
        align-items: center;
        & > * {
            margin-left:.4em;
        }
    }
    .right-section{
        width: 30%;
        display: flex;
        justify-content: end;
        align-items: center;
    }
}
.cemOrderOwnerIcon {
    color:#122C49;
}

.flexHandlerIcon {
    color:#577E9E;
}

.e2eHandlerIcon{
    color:#BCE4F7;
}

.taskHandlerIcon {
    color: #E5DDCA;
}
  
.btn-icon {
    background: none;
    min-width: 44px;
    height: 35px;
    padding: 0;
    border-radius: 2px;
}

.btn-icon:hover {
    background: #e2e6ea;
    border-color: #dae0e5;
}
.vl{
    background-color:#caced1;
    width: 1px; height: 1.5em; 
    display: inline-block;
    margin:0 .9em !important;
}
.dropDownPadding {
    width:70px;
	:global(.p-inputtext){
		padding:.25em .75em;
        color:black;
        font-size:1.3rem;
	}
}
.dropdownViewBy {
	:global(.p-inputtext){
		padding:.25em .75em;
	}
}