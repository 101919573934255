.certificate-linked-products__container {
  padding: 1rem 0.5rem 0.5rem 0.5rem;

  .title__section {
    font-weight: 700;
  }

  .table-row__span {
    word-wrap: break-word;
  }
}
