.container {
  width: 100%;
  height: 40rem;
  .modal-header {
    margin-left: 30px;
  }

  .modal-footer {
    padding: 5px 0;
  }

  hr {
    border-color: #cfcfcf;
    border-bottom: 0px;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 20px;

    display: flex;
    justify-content: space-around;

    .modal-left-content {
      width: 100%;
      height: 100%;
      .data-fields {
        padding: 5px;
      }

      .data-field-select-all {
        padding: 10px 30px;
      }

      .data-field-button {
        width: 100%;
        text-align: left;
        box-shadow: none !important;
      }

      .data-field-button-active {
        background-color: gray;
        color: white;
      }

      .data-field-button:focus {
        background-color: gray;
        color: white;
      }

      .data-field-button-active {
        background-color: gray;
        color: white;
      }
    }

    .modal-right-content {
      border: whitesmoke 1px solid;
      border-radius: 10px;
      height: 30rem;
      overflow-y: scroll;
      padding: 2rem;
      width: 100%;

      .details-content-container {
        .details-header {
          font-weight: bold;
          padding-top: 20px;
          padding-left: 5px;
        }
        .details-field {
          padding: 5px;
        }
      }

      .details-header {
        font-weight: bold;
        padding-top: 20px;
        padding-left: 5px;
      }
    }
  }

  .modal-content::-webkit-scrollbar {
    display: none;
  }
}
