.project-details__container {
  $project-details-height: 30rem;

  display: grid;
  justify-content: space-between;
  background: #f8f8f8;
  border-top: 1px solid #cbcfd1;
  border-bottom: 1px solid #cbcfd1;
  overflow: hidden;
  height: $project-details-height;
  max-height: $project-details-height;
  grid-template-columns: 20% 45% 32% 3%;

  &-no-record {
    grid-template-columns: 20% 80%;
  }

  &-collapsed {
    grid-template-columns: 20% 77% 3%;
  }

  :global(.p-input-icon-right) {
    width: 100%;
  }

  :global(.p-card .p-card-body) {
    padding: 0;
  }

  :global(.p-card .p-card-content) {
    padding: 0;
  }

  .right-panel-hidden {
    grid-template-columns: 0.75fr 2fr;
  }

  .left-panel {
    grid-column: 1;
    box-shadow: none;
    border-left: 1px solid #cbcfd1;
    border-top: none;
    border-bottom: none;
    border-right: 1px solid #cbcfd1;
    border-radius: 0px;
    overflow: hidden;

    .search-box__container {
      padding: 1.5rem;
    }

    .search-box {
      width: 100%;
    }
  }

  .center-panel {
    grid-column: 2;
    border-left: 1px solid #cbcfd1;
    border-right: 1px solid #cbcfd1;
    border-radius: 0px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: $project-details-height;
    max-height: $project-details-height;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 1.5rem;
  }

  .right-panel {
    grid-column: 3;
    border-left: 1px solid #cbcfd1;
    border-right: 1px solid #cbcfd1;
    border-radius: 0px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: $project-details-height;
    max-height: $project-details-height;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 1.5rem;
  }

  .collapse-panel {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    border-right: 1px solid #cbcfd1;
    border-radius: 0px;
    text-align: center;

    .collapse-button {
      font-size: 0.5rem;
      padding: 0;
      outline: none;
      border: none;
      background: none;
      color: black;

      &:hover {
        outline: none;
        border: none;
        background: none;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  .no-record-found__container {
    display: flex;
    border-left: 1px solid #cbcfd1;
    border-right: 1px solid #cbcfd1;
    border-radius: 0px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: $project-details-height;
    max-height: $project-details-height;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: center;
    align-items: center;

    .label {
      font-style: italic;
    }
  }
}

.order-information-note {
  color: #4e74bb;
  font-size: 12px;
  font-style: italic;
  margin-top: -5px
}