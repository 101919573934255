.delete-confirmation-dialog {
    :global(.p-dialog-header) {
      border-bottom: solid 1.7px #999999;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  
    :global(.p-dialog-content) {
      padding-top: 60px;
      padding-bottom: 60px;
      border-bottom: solid 1.7px #999999;
  
      :global(.p-confirm-dialog-icon) {
        display: none;
      }
    }
  
    :global(.p-dialog-footer) {
      padding-top: 20px;
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
  
      :global(.p-button) {
        width: 100px;
      }
  
      :global(.p-confirm-dialog-accept) {
        background-color: #0088cc !important;
      }
  
      :global(.p-confirm-dialog-reject) {
        background-color: #707070 !important;
        color: white !important;
      }
    }
  
    .note {
      max-width: 580px;
      margin-top: 20px;
    }
  }