.new-cert-prod-dtls-col-hdr {
  font-weight: bold;
  margin-bottom: .5rem;
}

.invalid-file {
  color: red;
}

.new-cert-prod-dtls-col-hdr-lbl {
  font-weight: normal;
  font-size: 0.9em;
}

.progressbar-div {
  margin-top: 0.3rem;

  :global(.p-progressbar) {
    min-height: 8px !important;
  }

  :global(.p-progressbar-value) {
    background: #00518A !important;
  }
}

.success {
  color: #146615;
}

.new-cert-prod-dtls-att-link {
  color: #01518A;
  cursor: pointer;
}

.new-cert-prod-dtls-att-link:hover {
  color: #0A99FF;
  text-decoration: underline;
}

.new-cert-prod-dtls-fl-upld-div {
  min-height: 70%;
  border: 1px solid rgb(213, 210, 210);

  .fl-upld-btn-div {
    height: 70%;
    display: flex;
    justify-content: space-between;

    .fl-upld-col-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .fl-upld-col-right {
      display: flex;
      justify-content: end;
      align-items: center;
    }
  }

  .fl-upld-col-file-hdr {
    border: solid 1px #cccccc;
    padding: 1rem;
  }

  .fl-upld-col-file-list-div {
    overflow-y: auto;
    max-height: 10rem;

    .fl-upld-col-file-item {
      border-bottom: solid 1px #cccccc;
      border-left: solid 1px #cccccc;
      border-right: solid 1px #cccccc;
      padding: 1rem;
    }

    .fl-upld-img-icon {
      padding-top: 0.5rem;
    }

    .fl-upld-prcnt-div {
      text-align: right;
    }
  }

  .p-tabview.p-component {
    .p-tabview-nav-container {
      background-color: #ebebea !important;

      .p-tabview-nav-content {
        .p-tabview-nav {
          background-color: #ebebea !important;

          li {
            a {
              background-color: #ebebea !important;
              color: black !important;
            }
          }
        }
      }
    }
  }
}