.radio-technology-dialog {
    width: 75vw;
    min-height: 90%;

    .dialog-title {
        margin-top: 0px;
        margin-bottom: 0px;
        font-weight: normal;
        font-size: 1.15rem;
    }

    :global(.p-dialog-content) {
        overflow: auto !important;
    }

    .help-text {
        margin-bottom: 20px;
        display: inline-block;
    }

    :global(.p-dialog-header-icon) {
        color: #1A1A1A !important;
    }

    :global(.p-dialog-header) {
        border-bottom: 2px solid #b9b9b9;
        padding: 12px 20px;
    }

    :global(.p-dialog-footer) {
        display: flex;
        gap: 15px;
        padding-top: 20px;
        justify-content: center;
    }

    .radio-button-container {
        gap: 8px;
    }

    .radio-technology-type-field {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 18px;
        
        .checkbox-container {
            margin-bottom: 0px !important;
        }

        .other-radio-technology-type {
            width: 40%;
        }
    }

    .radio-type-options-container {
        margin-left: 12px;
    }

    .other-radio-technology-container {
        padding: 20px;
        margin-right: 20px;
        flex-grow: 1;

        input {
            width: 100%;
        }
    }

    .btn-apply {
        &:disabled {
            background-color: #01518A !important;
        }
    }
}