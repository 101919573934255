.container {
  margin-bottom: 18px;

  &.disabled {
    opacity: .8
  }
  
  .checkbox-container {
    display: flex;
    align-items: flex-start;

    .checkbox-label {
      user-select: none;
      text-align: left;
    }

    .help-text-right {
      margin-left: 8px;
      margin-bottom: -5px;
      font-size: 0.85rem;
    }
    :global(.p-checkbox.p-component .p-checkbox-box.p-highlight) {
      background-color: #00518a !important;
      border-color: #00518a !important;
    }

    .checkbox {
      margin-right: 8px;
    }

    :global(.p-checkbox-box) {
      width: 20px;
      height: 20px;
    }
  }

  .help-text-bottom {
    font-size: 0.9rem !important;
    color: #5f5f5f !important;
    margin-left: 30px;
  }
}
