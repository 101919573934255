.p-input-icon-left {
  width: 100%;

  .p-inputtext {
    width: 100%;
  }
}

.mdl-srch-results-grid-large {
  margin-top: 1rem;

  .p-datatable-wrapper {
    height: calc(63vh - 260px);
  }

  .text-overflow-ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}

.react-tiny-popover-container {
  z-index: 9999;
}

.mdl-srch-results-grid-small {
  margin-top: 1rem;

  .p-datatable-wrapper {
    height: calc(50vh - 210px);
  }

  .text-overflow-ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-width: 90px !important;
  }
}

.mdl-srch-container {
  height: 100%;

  .p-datatable-wrapper {
    height: calc(80vh - 210px);
  }
}

.text-overflow-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  min-width: 90px !important;
}

.react-datepicker-popper {
  z-index: 99999 !important;
}