.project-details-info__container {
    .project-details-info-header {
        margin-bottom: 0.5rem;
    }

    .project-details-info-item {
        display: grid;
        grid-template-columns: 40% 60%;

        &-collapsed {
            display: grid;
            grid-template-columns: 25% 75%;
        }

        &-key {
            margin: 0;
            white-space: wrap;
            overflow-wrap: break-word;
            text-wrap: wrap;
        }

        &-value {
            margin: 0;
            white-space: wrap;
            overflow-wrap: break-word;
            align-content: end;
        }
    }
}

.agent-information-note {
    color: #4e74bb;
    font-size: 12px;
    font-style: italic;
    margin-top: -5px
}