.table-container {
  width: 100%;

  td {
    position: relative;
  }

  .project-milestone-list-grid-container {
    padding: 0;

    :global(.p-datatable) {
      border: solid 1px #cbcfd1;
    }

    :global(.p-datatable-table) {
      min-width: 100% !important;
    }

    :global(.p-datatable-wrapper) {
      height: auto;
      overflow: hidden;
    }

    :global(.p-datatable-loading-overlay) {
      z-index: 4;
    }

    :global(.p-datatable-thead) {
      z-index: 3;
      display: table-header-group;
    }

    :global(.p-datatable-tbody) {
      display: table-row-group;
    }

    :global(.p-datatable-emptymessage) {
      font-style: italic;
      font-weight: normal;
      height: 45px !important;

      td {
        border: none;
        background-color: #ffffff !important;
        border: 0 !important;
        justify-content: center;
        text-align: center;
        width: 100% !important;
      }
    }

    &
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > .p-datatable-table
          > .p-datatable-thead
      ) {
      & > tr {
        background-color: #fcfcfc !important;
      }
    }

    &
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > .p-datatable-table
          > .p-datatable-thead
          > tr
      ) {
      & > th {
        background: #fcfcfc !important;
        color: #222222;
        border: 1px solid #cbcfd1;
        border-width: 0 0 1px 0;
      }
    }

    :global(th .p-column-header-content) {
      display: flex !important;
      padding-right: unset !important;
      border-right: unset !important;
    }

    :global(th .p-column-header-content .p-column-title) {
      width: 100%;
    }

    :global(th.p-sortable-column .p-column-header-content .p-column-title) {
      width: calc(100% - 28px);
    }

    :global(.p-datatable .p-sortable-column .p-sortable-column-icon) {
      margin-left: 0;
    }

    :global(.p-datatable .p-datatable-tbody > tr > td .p-row-toggler) {
      height: 1rem;
      width: 1rem;
      color: #222222;
    }

    :global(.p-datatable .p-datatable-tbody > tr),
    :global(.p-datatable .p-datatable-thead > tr) {
      display: table-row;
    }

    :global(.p-datatable .p-datatable-tbody > tr > td),
    :global(.p-datatable .p-datatable-thead > tr > th) {
      display: table-cell;
    }

    :global(.p-datatable .p-datatable-tbody > tr > td:nth-child(2)),
    :global(.p-datatable .p-datatable-tbody > tr > td:nth-child(3)) {
      vertical-align: top;
    }

    &
      > :global(
        .p-datatable
          > .p-datatable-wrapper
          > .p-datatable-table
          > .p-datatable-tbody
          > tr
      ) {
      & > td {
        background: #ffffff;
        color: #222222;
        font-weight: normal;
        border: 1px solid #cbcfd1;
        border-width: 0 0 1px 0;
        padding: 0 !important;

        > div {
          min-height: 100% !important;
          height: 100% !important;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 0px !important;
        }

        $defaultColHeight: 2.75rem !important;

        :global(.milestone-tbl-col) {
          height: $defaultColHeight;
          width: 100%;
          margin: 0px !important;
          align-items: center;
          display: flex;
        }

        :global(.milestone-tbl-col-date) {
          width: 100%;
          margin: 0px !important;
          height: 100%;
          align-items: center;
          display: flex;
          text-align: center;
          padding: 10px;
        }

        .orderline-cell-container {
          display: flex;
          gap: 0.5rem;
          width: 100%;
          align-items: center;
          height: $defaultColHeight;
          padding: 10px !important;
          margin: 0 !important;

          .task-checkbox {
            cursor: pointer;
          }

          .milestone-number {
            flex-grow: 1;
            min-width: 90px !important;
            cursor: pointer;

            div {
              white-space: nowrap !important;
              overflow: hidden !important;
              text-overflow: ellipsis !important;
            }

            .custom-row-expander {
              margin-right: 0.5rem;
              cursor: pointer;
            }
          }

          .kebab-icon {
            text-align: right;
            display: flex;
            align-items: center;

            .action-icon {
              margin-left: 0.75rem;
              cursor: pointer;
            }

            button {
              padding: 0.1rem;
              width: 1.5rem;
            }

            span {
              color: #222222;
            }
          }
        }

        .milestone-cell-container {
          display: flex;
          width: 100%;
          padding: 10px !important;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;

          .left {
            cursor: default;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            min-width: 90px !important;
            width: 100%;
            cursor: pointer;

            .task-checkbox {
              margin-right: 0.5rem;
              cursor: pointer;
            }

            .custom-row-expander {
              margin-right: 0.5rem;
              cursor: pointer;
            }
          }
        }

        .wbs-predecessor-cell-container {
          display: flex;
          width: 100%;
          justify-content: center;
          padding: 10px;
        }

        .wbs-cell-container {
          display: flex;
          width: 100%;
          justify-content: center;
          padding: 10px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .order-line-overdue {
          color: #c02032 !important;
        }
      }
    }
  }

  .is-timestamping {
    opacity: 0.5;
  }

  .project-line-is-completed {
    color: #3eb489 !important;
  }

  .check-icon {
    color: #146615 !important;
    margin-right: 0.2rem;
  }

  .collaborator-milestone-container {
    position: relative;

    border-bottom: 1px solid rgb(221, 221, 221);

    &:last-of-type {
      border-bottom: unset;
    }
  }
}

:global(.recently-changed) {
  font-weight: bold;

  &:before {
    content: "• ";
    color: #0a99ff;
    font-weight: bold;
  }
}
.predecessor-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
