.custom-dialog {

    // Header and Content styles
    :global(.p-dialog-header) {
        color: #222222;
    }

    :global(.p-dialog-content) {
        color: #222222;
        padding-bottom: 0;
        overflow-y: auto !important;
    }

    :global(.p-dialog-footer) {
        color: #222222;
    }

    :global(.p-button) {
        padding: 0;
        color: #222222 !important;
    }

    .dialog-footer {
        display: flex;
        justify-content: center;

        .dialog-cancel-btn,
        .dialog-save-btn {
            flex: 1;
            min-width: 5rem;
            max-width: 7rem;

            height: 3rem;
            border-radius: 5px;
            padding: 0.5rem 1rem;

            &.dialog-cancel-btn {
                background-color: #ffffff;
                border-color: #999999;
                color: #999999;
            }

            &.dialog-save-btn {
                background-color: #0088cc;
                color: #ffffff;
            }
        }
    }

    // horizontal line in footer
    .modal-footer-hr {
        border: 1px solid #cecece;
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

    .loading-div {
        height: 200px;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    // table styles
    .custom-table {
        margin-top: 1rem;

        :global(.p-datatable) {
            border: 1px solid #cecece;
            border-radius: 5px;
            overflow: hidden;

            :global(.p-datatable-header) {
                font-size: 1rem;
                font-weight: bold;
                background-color: #f5f5f5;
                border-bottom: 1px solid #cecece;
            }

            :global(.p-datatable-thead > tr > th) {
                background-color: #f5f5f5;
                color: #333333;
                text-align: left;
                padding: 0.5rem;
            }

            :global(.p-datatable-tbody > tr > td) {
                padding: 0.5rem;
                color: #222222;
            }
        }
    }

    // calendar container
    .new-ecd-container {
        margin-top: 1.5rem;
        display: flex;

        .new-ecd-label {
            flex: 2;
            font-size: 1rem;
            color: #222222;
            margin-right: 1rem;
            text-align: right;
            margin-top: .7rem;
        }

        .new-ecd-calendar {
            flex: 1.8;

            :global(.p-inputtext) {
                width: 100%;
                border: 1px solid #cecece;
                border-radius: 5px 0 0 5px !important;
                border-right: none;
                padding: 0.5rem;
                padding-left: 1rem !important;
            }

            :global(.p-calendar) {
                width: 100%;
                display: flex;
                align-items: center;
                border-right: none;
            }

            :global(.p-calendar-trigger) {
                margin-left: 0;
                background-color: transparent;
                border: none;
            }

            :global(.p-button) {
                background-color: transparent;
                border-left: none;
                box-shadow: none;
                border-color: #cecece;
            }
        }
    }

    .body-container {
        padding: 1rem;
        font-size: 1rem;
        color: #333333;
    }

    .table-container {
        margin-bottom: 1rem;
    }

    .custom-table {
        width: 100%;
    }
}

.input-instructions {
    line-height: 1.7rem;
    margin-bottom: 2rem;
}

.loading-div {
    height: 200px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.not-allowed {
    cursor: not-allowed !important;
}

.calendar-panel {
    td {
        padding: 0.1rem !important;

        :global(.p-disabled) {

            &,
            * {
                pointer-events: auto !important;
            }
        }

        span {
            background-color: white !important;
        }
    }
}

.calendar-container {
    max-width: 17rem;

    .note {
        font-style: italic;
        margin-top: .5rem;
        line-height: 1.25rem;
    }
}