.custom-badge {
  border-radius: 10px;
  padding: 0.05rem 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.3px;
  text-align: center;
  min-width: 200px;
  &.inprogress {
    background-color: #0a99ff;
    color: #ffffff;
  }

  &.notscheduled {
    background-color: #595958;
    color: #ffffff;
  }

  &.completed {
    background-color: #146615;
    color: #ffffff;
  }

  &.onhold {
    background-color: #ffa700;
    color: #8a5340;
  }

  &.cancelled {
    background-color: #cccccd;
    color: #444444;
  }

  &.notstarted {
    background-color: #80a8c4;
    color: #ffffff;
  }

  &.awaitingassignment {
    background-color: #096072;
    color: #ffffff;
  }
}