.btn-ok {
  background-color: #0088cc !important;
}

.adjustments-table {
  margin: 1.5rem 0rem;

  .table-col {
    &.col-orderLineNumber {
      width: 12rem;
      max-width: 12rem;
    }
    &.col-projectName {
      width: 8rem;
      max-width: 8rem;
    }
    &.col-previousECD {
      width: 6rem;
      max-width: 6rem;
    }
    &.col-orderLineECD {
      width: 6rem;
      max-width: 6rem;
    }

    .col-text-div {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
    }
  }
}

.modal-body {
  margin: 0px !important;
}
