.divScroll {
    max-height: 500px;
    overflow: auto;
    height: auto;
    padding: 0px;
}

.itemLabel {
    white-space: nowrap;
    margin-top: 10px;
    margin-bottom: 3px;
}

.ul {
    list-style-type: none;
    padding: 0px;
}

.header {
    font-weight: 600;
}

.overlayContent {
    justify-content: right;
    align-items: center;
}

.overlayCloseButton {
    :global(button.p-overlaypanel-close.p-link){
        top: 1rem;
        right: 1rem;
        background-color: transparent;
        color: black;
        &:enabled:hover {
        background-color: transparent;
        color: black;
        }
    }
}