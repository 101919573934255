.container {
  .modal__header {
    margin-top: 5px;
    margin-left: 30px;
  }

  .modal__header-hr {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  hr {
    border-color: #cfcfcf;
    border-bottom: 0px;
  }

  :global(.p-dialog-header) {
    padding: 20px 0px 0px 0px !important;
  }

  :global(.p-dialog-footer) {
    padding: 0px 0px 25px 0px !important;
  }

  .footer-content {
    margin-top: 18px;
  }

  .btn-save {
    background-color: #0088CC !important;
    width: 8rem !important;
  }

  .btn-cancel {
    background-color: #585858;
    color: white;
    width: 8rem !important;
  }

  .assign-flex-handler__dropdown {
    width: 100%;
  }

  .modal__body {
    display: flex;
    justify-content: center;
    padding: 3rem 2rem 1rem 2rem;
  }

  :global(.p-dialog-header-close) {
    right: 1rem;
    position: absolute;
    top: 1rem;
  }
}