.attachment-container {
  margin-bottom: 1rem;

  .attachment-header {
    font-weight: bold;
    margin-bottom: 0.2rem;

    .attachment-header-note {
      font-style: italic;
      font-size: 0.9rem;
      font-weight: 400;
    }
  }

  .attachment-body {
    min-height: 70%;
    border: 1px solid rgb(213, 210, 210);

    .attachment-panel-body {
      padding: 1rem;
      position: relative;

      .attachment-dropzone {
        z-index: 2;
        background-color: rgba(0, 136, 204, 0.1);
        border: 2px solid #08c;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .attachment-dropzone-content {
          text-align: center;
          margin-bottom: 0.2rem;
          padding-top: 1rem;
          z-index: 9;

          .attachment-dropzone-content-icon {
            color: #08c;
            font-weight: bold;
            font-size: 1.5rem;
          }

          .attachment-dropzone-content-text {
            background-color: #08c;
            padding: 0.5rem;
            color: white;
            border-radius: 1rem;
            margin-top: 1rem;
          }
        }

        &.invalid {
          background-color: rgb(255, 167, 0, 0.1);
          border: 2px solid #ffa700;

          .attachment-dropzone-content {
            .attachment-dropzone-content-icon {
              color: #ffa700;
            }

            .attachment-dropzone-content-text {
              background-color: #ffa700;
            }
          }
        }
      }

      .attachment-add-file-container {
        margin-bottom: 0.2rem;

        .attachment-add-file-buttons-container {
          display: flex;

          .left-section {
            width: 65%;
            display: flex;
            justify-content: start;
            align-items: end;

            .legends {
              display: flex;

              .legend {
                display: flex;
                justify-content: start;
                align-items: center;
                font-size: 1rem;

                .legend-label {
                  margin-right: 1.5rem;
                  font-style: italic;
                }
              }
            }
          }

          .right-section {
            width: 35%;
            display: flex;
            justify-content: end;
            align-items: end;
            flex: 1;

            .invalid-add-file-info {
              font-style: italic;
              color: #e12d33;
              font-size: 0.85rem;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }

  .gridaction-container {
    button {
      padding: 0.1rem;
      font-size: 1rem;
      color: #000000 !important;
    }

    .fileresult {
      margin-right: 0.2rem;
      font-style: italic;
      font-size: 0.9rem;
    }
  }

  .grid-filename {
    font-size: 1rem;
  }

  .grid-filename,
  .gridaction-container {
    .error {
      color: #e12d33;
    }

    .success {
      color: #096072;
    }

    .warning {
      color: #f5a102;
    }
  }

  .icon-1 {
    color: #146615;
    font-size: 1rem;
    margin-right: 0.3rem;
  }

  .icon-2 {
    color: #ffa700;
    font-size: 1rem;
    margin-right: 0.3rem;
  }

  .icon-3 {
    color: #0a99ff;
    font-size: 1rem;
    margin-right: 0.3rem;
  }
  .icon-4 {
    color: black;
    font-size: 1rem;
    margin-right: 0.3rem;
  }
}

a {
  cursor: pointer;
  color: #0d6efd;

  &.link {
    color: #01518a;
  }

  &.link:hover {
    color: #0a99ff;
    cursor: pointer;
    text-decoration: underline;
  }
  &.link-error {
    color: red;
  }
}

.datatable-emptymessage-container {
  width: 100%;
  height: 8rem;
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;

  .datatable-emptymessage-label {
    font-size: 0.8rem;
    font-style: italic;
  }
}
