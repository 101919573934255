.template-footer__container {
    display:flex;
    justify-content: flex-end;

    .template-footer__row {
        display: flex;
        gap:20px;

        .publish-button{

            border: none !important;
            background:#3F47B5;
            &:disabled {
                opacity:0.6;
                &:hover{
                       opacity:0.6;
                }
            }
            &:hover{
                background:#363da1;
            }

        }

        .save-as-draft-button {
            border-color:#3F47B5;
            color:#3F47B5;
            background:transparent;
            &:disabled{
                opacity:0.6;
                &:hover{
                    opacity:0.6;
                }
            }
            &:hover{
                border-color:#363da1;
                color:#363da1;
            }
        }

        .cancel-button {
            background:#595957;
            border: none !important;
            &:disabled{
                opacity:0.6;
                &:hover{
                    opacity: 0.6;
                }
            }
            &:hover{
                background:#4b4b49;
            }
        }
    }
}
