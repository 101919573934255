.e-ddl.e-input-group .e-input-group-icon,.e-ddl.e-input-group.e-control-wrapper .e-input-group-icon:hover {
    color: #000000 !important;
    font-size: 13px !important;
}

span.e-input-group-icon.e-ddl-icon.e-search-icon {
    color: #000000 !important;
    font-weight: 900 !important;
    font-size: 10px !important;
}

.e-dropdownbase .e-list-item.e-item-focus, .e-dropdownbase .e-list-item.e-active, .e-dropdownbase .e-list-item.e-active.e-hover, .e-dropdownbase .e-list-item.e-hover {
    background-color: #ECF5FC !important;
    color: #000000 !important;
}

.e-dropdownbase {
    border: 2px solid #000000 !important;
    font-weight:400;
}

.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
    content: "\e70d" !important;
    font-family: "e-icons" !important;
}