.add-attachments-container {
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  :global(.new-cert-prod-dtls-overrides),
  :global(.p-tabview),
  :global(.p-tabview-panels) {
    height: 100%;
  }

  :global(.new-cert-prod-dtls-fl-upld-div) {
    height: calc(100% - 20px);
  }

  :global(.p-tabview-panels) {
    height: calc(100% - 58px);
  }

  :global(.fl-upld-col-file-list-div) {
    min-height: calc(100% - 85px) !important;
  }
}

.btn-close {
  background-color: #ffffff !important;
  border-color: #999999 !important;
  color: #999999 !important;

  &:hover {
    background-color: #999999 !important;
    border-color: none !important;
    color: #ffffff !important;
  }
}
