.dropdown-panel-currencies {
  .text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.p-dropdown-items-wrapper > .p-dropdown-items) {
    width: unset !important;
    & > :global(li) {
      width: unset !important;
    }
  }
}

.dropdown-wrapper-currencies {
  align-items: center;
  :global(.p-dropdown-label.p-inputtext) {
    height: unset !important;
  }
}
