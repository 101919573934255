.grid-action-popover-item {
  color: black;
  padding: 0.5em;
}

.active {
  cursor: pointer !important;

  &:hover {
    background-color: #eef2ff;
  }
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
}

.grid-action-popover {
  min-width: 167px !important;
}

.line-break {
  margin: 0.25rem 0px !important;
}

.icon-image {
  margin-right: 0.25rem;
  width: 50%;

  img,
  svg {
    width: 1.3rem;
    height: 1.2rem;
    vertical-align: middle;
  }
}

.action-text-display {
  vertical-align: middle;
}