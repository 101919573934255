.show-hide-button{
    margin: 0!important;
}

.show-hide-button:hover{
    background-color: rgb(180,180,180);
}

.title-caption {
    border-bottom: solid 0.5px #d8d8d8;
    margin-bottom: 10px;
}

.table-header{
    background-color: #fff !important;
    justify-content: center !important;
}

.drag-body{
    background-color: #cedbe9 !important;
    border-right: 0 !important;
}

.checkbox-body{
    background-color: #cedbe9 !important;
    border-left: 0 !important;
}

.checkbox{
    height: 16px;
    width: 16px;
}

.freeze-column{
    height: 32px;
}

.rowfrozen{
    background-color: #e4e6e6 !important;
}



