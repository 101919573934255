.wrapper {
    display: flex;
    font-size: 10px;
}

.icon {
    margin: -3px;
}

.more {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s;
    margin: 3px;
    font-size: 13px;
    font-weight: bold;
}

.circle {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 50%;
    transition: transform 0.2s;
    font-weight: 600;
    cursor: default;
    font-size: 16px;
}

.verticalLine {
    transform: rotate(90deg);
    translate: -184%;
    width: 20%;
}

.wrapPosition {
    z-index: 100;
    position: relative;
    display: flex;
    align-items: center;
}

.tooltipScroll {
    max-height: 300px;
    overflow: auto;
    height: auto;
}

.tooltipContainer {
    margin: 10px;
}

.handler {
    font-size: 12px;
}

.collaboratorName {
    font-size: 16px;
    font-weight: 600;
}

.avatarInitialsContainer {
    justify-content: center;
    display: flex;
}