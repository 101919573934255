.show-hide-button {
    margin: 0 !important;
}

.show-hide-button {
    background: none;
    width: 44px;
    height: 35px;
    padding: 0;
    border-radius: 2px;
}

.show-hide-button:hover {
    background: #e2e6ea;
    border-color: #dae0e5;
}

.title-caption {
    border-bottom: solid 0.5px #d8d8d8;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;    
}

.table-header {
    background-color: #fff !important;
    justify-content: center !important;
}

.drag-body {
    background-color: #cedbe9 !important;
    border-right: 0 !important;
}

.checkbox-body {
    background-color: #cedbe9 !important;
    border-left: 0 !important;
}

.checkbox {
    height: 16px;
    width: 16px;
    accent-color: blue;
}

.freeze-column {
    height: 32px;
}

.rowfrozen {
    background-color: #e4e6e6 !important;
}

.overlay-close-button {
    :global(button.p-overlaypanel-close.p-link) {
        top: 1rem;
        right: 1rem;
        background-color: transparent;
        color: black;

        &:enabled:hover {
            background-color: transparent;
            color: black;
        }
    }
}