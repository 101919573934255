.dialog {
  :global(.p-dialog-header) {
    padding: 1rem !important;
  }
}

.model-name--red {
  color: red !important;
}

.mdl-srch-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .psn-search {
    background: none;
    border: none;

    span {
      margin-left: 0.5rem;
      text-decoration: underline;
      color: blue;
      cursor: pointer;
    }
  }

  .psn-info-icon {
    cursor: pointer;
  }

  .psn-search-bar {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mdl-srch-dtls-separator {
    margin-top: 0.75rem;
    border-top: solid 1px lightgrey;
  }

  .mdl-srch-dtls-input {
    padding-top: 0.7rem;
  }

  .mdl-srch-dtls-collapse-btn {
    border: solid 1px;
    font-weight: bold;
    padding: 0.1rem 0.75rem 0.1rem 0.75rem;
    cursor: pointer;
  }

  .mdl-srch-txt-item-div {
    margin-top: 0.5rem;
    width: 100%;

    .mdl-srch-txt-item {
      margin-left: 0.5rem;
      background-color: #cbedff;
      border: 0.5px solid #73b6ff;
      height: 30px;
      padding: 4px;
      width: 150px;
      display: inline-block !important;
      vertical-align: top;

      .mdl-srch-txt-item-close {
        padding-right: 0.2rem;
        float: right;
        cursor: pointer;
      }
    }
  }

  .mdl-srch-input-div {
    width: 100%;
  }

  .from-to-date-div {
    display: flex;
  }

  .mdl-srch-dtl-date-div {
    padding-top: 0.8rem;
  }

  .mdl-srch-dtl-date {
    padding-top: 0.5rem;
  }

  .create-model-div {
    display: flex;
    align-items: center;
  }

  .search-result-label {
    font-weight: bold;
    font-size: 1.3rem;
    padding-right: 0.75rem;
    vertical-align: bottom;
  }

  .create-new-frm-wb {
    background-color: #51a5ff;
    border-color: #51a5ff;
    color: #fff;
    padding: 0.5rem 1.5rem;
  }

  .create-new-custom-model {
    margin-left: 1rem;
    background-color: #28a745;
    color: #fff;
  }

  :global(.p-column-header-content) {
    width: 100%;
  }

  :global(.p-column-title) {
    width: calc(100% - 55px);
  }

  :global(.p-dialog-content) {
    overflow-y: auto !important;
  }

  td {
    &>div {
      max-width: 100%;
      width: 100%;

      &.modelName {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.main-search-container,
.mdl-srch-dtls-separator {
  input {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }

  :global(.p-dropdown-label) {
    padding: 0.4rem 1.75rem 0.4rem 0.4rem !important;
  }
}

.ip-model-details {
  position: relative;
}


.mdl-srch-footer-btns {
  width: 100%;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;

  button {
    margin-left: 0.5rem;
  }

  .mdl-srch-apply-btn {
    background-color: #007bff;
    color: #fff;
  }

  .mdl-srch-apply-close-btn {
    background-color: #17a2b8;
    color: #fff;
  }

  .mdl-srch-cancel-btn {
    background-color: #6c757d;
    color: #fff;
  }
}

.models-mapper-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  overflow-y: auto;
  margin-top: 0.25rem;

  .all-models-table-container {
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    position: relative;

    tr.already-selected {
      :global(.p-checkbox) {
        opacity: 0.3;
      }
    }
  }

  .actions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    &>button {
      display: block;
      padding: 0.5rem 0.5rem;
      font-size: 0.9rem;
      background-color: #e6e6e6;

      &:disabled {
        background-color: rgba(230, 230, 230);
        opacity: 0.5;
      }

      &:hover:not(:disabled) {
        background-color: #cccccc;
      }
    }
  }

  .selected-models-total {
    font-weight: bold;
    text-align: left;
    margin-bottom: 12px;
  }

  .mapped-models-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .model-variants-table-container {
      flex: 1 1;
      flex-basis: 0;
      min-height: 0px;
      display: flex;
      flex-direction: column;
    }
  }

  .all-models-table,
  .model-variants-table {
    flex: 1 1;
    flex-basis: 0;

    :global(.p-datatable-wrapper) {
      height: 100%;
    }
  }

  .model-variants-table-container,
  .base-model-table-container {
    .header {
      font-weight: bold;
      padding: 0.5rem;
      background-color: #dddddd;
      border: #e9e9e9 solid 1px;
    }
  }

  .all-models-table,
  .model-variants-table,
  .base-model-table {
    min-height: 100px;
  }

  :global(.p-paginator-element) {
    min-width: 2rem !important;
    height: 2rem !important;
  }

  :global(.p-paginator) {
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
}

.footer {
  position: relative;
}

.error-message {
  position: absolute;
  top: 25%;
  font-weight: 600;
}