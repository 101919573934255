.download-container {
  height: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

.drag-and-drop-container {
  font-size: 20px;
  color: #01518a;
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.download-icon-container {
  color: #01518a;
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.download-icon {
  height: 76px;
  width: 75px;
}

.download-title {
  font-size: 20px;
}

.vl {
  border-left: 1px solid #000;
  height: 100%;
}

.file-upload {
  border: 3px dashed #d7dbe4;
  height: 70%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.progress-bar {
  width: 90%;
}

.create-options-container {
  .options-container {
    .options {
      padding: 5rem 1rem 5rem 1rem;
      margin-left: 1rem;
    }
  }
}
