.dialog {
    .dialog-header__title {
        border-bottom: 2px solid #c5c3c3;
        padding-bottom: 2rem;
        margin-top: .5rem;
        margin-bottom: 0px;
        font-size: 1.25rem;
    }

    :global(.p-dialog-header-icon) {
        position: absolute;
        top: 1.75rem;
        right: 1.5rem;
    }

    .verbiage {
        margin-bottom: 1rem;
        line-height: 1.5rem;
    }

    .dialog-body {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .project-name {
            display: flex;
            flex-direction: column;
            gap: .3rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            &__label {
                font-weight: 600;
                font-size: 1rem;
                display: block;
                margin-bottom: .5rem;
            }

            &__input {
                width: 100%;

                &--error {
                    &:focus {
                        border-color: #e24c4c !important;
                        box-shadow: 0 0 0 0.1rem #e24c4c !important;
                    }
                }
            }
        }

        .flex-template__container {
            display: flex;
            align-items: flex-start;
            gap: .3rem;
            padding-right: 1.5rem;

            &-label {
                margin-top: .2rem;
            }
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: center;
        gap: .35rem;
        padding-top: 1.8rem;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        border-top: 2px solid #c5c3c3;

        button {
            height: 3.25rem;
            min-width: 9rem;
            font-weight: 600;
            display: flex;
            justify-content: center;
        }

        .btn-proceed {
            background-color: #00518A;
            border: none;

            &:disabled {
                background: #01518a;
                opacity: 0.5;
            }
        }

        .btn-cancel {
            background-color: #707070;
            border: none;

        }
    }
}