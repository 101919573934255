.container {
  .voltage-type-radio-buttons {
    display: flex;
    gap: 20px;
    margin-left: 6px;
    margin-bottom: 20px;
  }

  .dc-questions-container {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 35px;

    .sub-questions {
      padding-left: 35px;
    }
  }

  .battery-type-field {
    display: flex;
    align-items: flex-start;
    gap: 18px;
    margin-bottom: 16px;

    .battery-type-dropdown {
      min-width: 265px;

      :global(.p-dropdown-label) {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  .battery-type-other-container {
    max-width: 265px;
    flex-grow: 1;

    :global(.form-field) {
      margin-bottom: 0px !important;
    }
  }

  .battery-type-other {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 37px;
    min-height: 37px !important;
  }

  .operation-class-other-container {
    margin-top: 29px;

    :global(.form-field) {
      margin-bottom: 0px !important;
    }
  }

  .product-classification-container {
    & > h4 {
      font-size: 1rem;
    }

    .product-classification-guidelines {
      margin-top: 30px;

      & > p {
        line-height: 1.5rem;
      }
    }
  }

  .rating-container,
  .operation-class-container {
    display: flex;
    gap: 30px;

    & > div {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  :global(.p-multiselect-label) {
    & > *:not(:last-child)::after {
      content: ", ";
    }
  }
}

.selected-operation-class {
  display: none;
}
