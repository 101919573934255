.container {
  z-index: 9000;
  max-width: 430px;
  min-width: 400px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.3rem;
  padding: 10px 20px;
  max-height: 95vh;
  overflow-y: auto;

  .close-icon-container {
    text-align: right;
    margin-right: 15px;
    margin-bottom: 10px;

    .close-icon {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 6px;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 10px;

    .label {
      font-weight: bold;
      flex-basis: 45%;
    }

    .value {
      flex-basis: 55%;
    }

    .certificate-header {
      font-weight: bold;
    }
  }

  .message {
    font-weight: normal;
    color: black;
    margin-left: 5px;
    font-size: 15px;
  }

  .link {
    color: #01518a;
    cursor: pointer;
    text-decoration: underline;
  }
}

.warning-icon {
  color: #ffa701 !important;
  margin-right: 5px;
}

.info-icon {
  color: #0c687b !important;
  margin-right: 5px;
}

.model-info-popover {
  padding-right: 1.15rem;
}

:global(.popover-arrow) {
  left: 2px !important;
  width: 1rem;
  height: 1rem;
  margin: 0.3rem 0;
  border-top: unset !important;
  background-color: white;

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  &:before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.45);
  }

  &:after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }
}
