.overlay-close-button {
    :global(button.p-overlaypanel-close.p-link) {
        top: 1rem;
        right: 1rem;
        background-color: transparent;
        color: black;

        &:enabled:hover {
            background-color: transparent;
            color: black;
        }
    }
}

.btn-compare-delivery-path {
    padding: 0.5em 0.5em;
    margin-left: 1em;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.button {
    width: 130px !important;
    justify-content: center;
    margin-left: 10px;
}

.data-table-height {
    height: calc(36vh - 100px) !important;
}