.container {
  color: #1c8a86;
  font-weight: 700;
  gap: 0.25rem;
  margin-right: 0.25rem;
}

.container-child-task {
  color: #EB9A25;
  gap: 0.25rem;
  margin-right: 0.25rem;
}

.svg,
.children {
  vertical-align: middle;
}

.required-indicator {
  color: red;
  margin-left: 0.25rem;
}

.customer-indicator-container {
  display: flex;
  align-items: center;
  justify-content: end;
  .indicator-text {
    font-style: italic;
    align-self: center;
    font-size: 0.85rem;
  }
}
