.project-list-grid-container {
	margin-top: 1rem;
  
	.p-datatable-wrapper {
	  height: calc(100vh - 210px);
	}
  
	.text-overflow-ellipsis {
	  white-space: nowrap !important;
	  overflow: hidden !important;
	  text-overflow: ellipsis !important;
	  min-width: 90px !important;
	}
	
	thead > tr {
	  background-color: #e6e6e6 !important;
	}
  
	.p-reorderable-column:not(.p-frozen-column) {
	  z-index: 0 !important;
	}
  
	th:hover {
	  background-color: #cccccc !important;
	}
    
	.td-active {
	  color: #001524;
	}
  
	.td-expiring-soon {
	  color: #ffa700;
	}
  
	.td-under-revision {
	  color: #0a99ff;
	}
  
	.td-obsolete {
	  color: #e12d33;
	}
  
	th.p-resizable-column .p-column-header-content {
	  display: flex !important;
  
	  .p-column-title {
		width: calc(100% - 28px);
	  }
	}
  
	.td-withdrawn {
	  color: #999999;
	}
  
	.td-action-item {
	  cursor: pointer;
	}
  
	.p-reorderable-column:hover {
	  cursor: move;
	}
  
	.p-sortable-column-icon:hover {
	  cursor: pointer;
	}
	.p-button{
		padding:.1em;
	}
	.p-datatable .p-datatable-tbody > tr > td .p-row-toggler{
		height:1rem;
		width:1rem;
	}
	
  }
  .hideHeaderCheckbox {
	:global(.p-column-header-content){
		visibility: hidden;
	}
}