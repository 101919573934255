$border: 2px solid #b6b6b6;

.container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
}

.empty-message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .5;
    font-weight: 600;
}

.actions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 0rem 1.25rem;

    &>div {
        text-align: center;
    }

    button {
        all: unset;
        font-size: 1.05rem;
        font-weight: bold;
        background-color: #E6E6E6;
        padding: .6rem 1rem;
        width: 6rem;
        text-align: center;

        &:disabled {
            opacity: .5;
        }
    }
}

.search-input {
    width: 100%;
    border: $border;
}

.search-container {
    margin-bottom: 1rem;
}

.source-list-container {
    grid-area: 2 / 1 / 2 / 1;
}

.actions-container {
    grid-area: 2 / 2 / 2 / 2;
}

.selected-items-container {
    grid-area: 2 / 3 / 2 / 4;
}

.error-message-container {
    grid-area: 3 / 3 / 3 / 4;
    margin-top: .5rem;
    color: red;
    display: flex;
    width: 100%;
    gap: .5rem;

    .error-icon {
        margin-top: .25rem;
        margin-left: .25rem;
    }

    .error-message {
        font-weight: 500;
        font-style: italic;
        line-height: 1.5rem;
    }
}


.source-list-container,
.selected-items-container {
    border-radius: 5px;
    overflow-y: auto;
    border: $border;
    display: flex;
    flex-direction: column;
    height: 50vh;
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
}

.selected-item,
.source-list-item {
    all: unset;
    width: 100%;

    &.selected {
        background-color: #E6E6E6;
    }

    &>* {
        line-height: 1.5rem;
        padding: 1.25rem 1rem;
        cursor: pointer;

        &:hover,
        &.selected {
            background-color: #E6E6E6;
        }
    }
}