.badge-container {
  position: absolute;
}
.badge {
  cursor: default;
  font-size: 1rem !important;
  min-width: 2rem !important;
  height: 2rem !important;
  line-height: 2rem !important;

  font-weight: 400 !important;
  border-radius: 20px !important;
}
.ordercreator {
  background-color: #122c49 !important;
  color: #fff !important;
}
.flexhandler {
  background-color: #122c49 !important;
  color: #fff !important;
}
.gmawbhandler {
  background-color: #577e9e !important;
  color: #fff !important;
}
.milestonehandler {
  background-color: #e5ddca !important;
  color: #000000 !important;
}

.edit-collaborator-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  width: 100%;

  :global(.dropdown-panel) {
    min-width: 70%;
    max-width: 70%;
  }
}

.single-handler-reassignment-container {
  display: inline-flex;
  gap: 1rem;
  padding-left: 1rem;
  .confirm-button {
    color: green;
    cursor: pointer;
  }

  .confirm-button-padding{
    padding: 3px;
  }

  .action-buttons-disabled {
    cursor: not-allowed !important;
  }

  .cancel-button {
    color: red;
    cursor: pointer;
  }
  
  .cancel-button-padding{
    padding:3px;
  }

  svg {
    font-size: 20px !important;
  }
}

.badge-editable {
  cursor: pointer;
}

.tooltip {
  :first-child {
    border-right-color: white !important;
  }

  :last-child {
    background: white;
    color: #222222;
  }

  .tooltip-displayName {
    font-weight: bolder;
    padding-bottom: 0.5rem;
  }

  .tooltip-handlerLabel {
    font-style: italic;
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
}
