.error-message{
    text-align: left;
    color:red;
}

.error-message--hidden{
    visibility:hidden;
}

.error-text{
    margin-left:2px;
}