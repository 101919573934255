.tooltip {
    width: 30rem;

    :first-child {
        border-right-color: white !important;
    }

    :last-child {
        background: white;
        color: #222222;
    }
}

.tooltip-content {
    line-height: 1.5rem;
}