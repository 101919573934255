.create-options-container {
  height: 100%;
}

.options-container {
  height: 95%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.options {
  color: #01518a;
  width: 49%;
  border: solid #01518a;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.options:not(:first-child):hover {
  cursor: pointer;
  opacity: 1;
}

.import-icon {
  height: 76px;
  width: 75px;
}

.manual-icon {
  height: 76px;
  width: 85px;
}

.action-title {
  font-size: 24px;
}

.action-sub-title {
  font-size: 16px;
  color: #000000;
}
